import {handleActions} from 'redux-actions';

import apiActionTypes from './actions';
import Intervention from '../../utils/InterventionClass';
import {getInterventionZoneByInterventionId} from '../../utils/getInterventionZoneByInterventionId';
import {getInterventionZoneByExecutionId} from '../../utils/getInterventionZoneByExecutionId';
import {getInterventionIdByExecutionId} from '../../utils/getInterventionIdByExecutionId';

const initialState = {
  username: undefined,
  token: undefined,
  profile: undefined,
  manifest: undefined,
  loginError: undefined,
  communities: [],
  rolesList: [],
  interventionZones: [],
  interventionZoneDetail: undefined,
  downloadToken: undefined,
  annualPlans: [],
  commoners: [],
  business: [],
  businessWithLocation: undefined,
  maderaPlus_species: [],
  maderaPlus_district: undefined,
  accountEntries: [],
  batches: [],
  files: [],
  absorptionBaseLine: {},
  absorptionProject: {},
  apiError: undefined
};

//GENERAL---------------------------------------------------------------------------------------------------------------
const apiLoginSuccess = (state, {payload}) => ({
  ...initialState,
  username: payload.username,
  token: payload.token
});

const apiLoginUnauthorized = () => ({
  ...initialState,
  loginError: 'Usuario o contraseña no válidos'
});

const apiLoginError = (state, {payload}) => ({
  ...initialState,
  loginError: payload
});

const apiLogout = () => ({
  ...initialState
});

const apiProfileSuccess = (state, {payload}) => ({
  ...state,
  profile: payload
});

const apiProfileError = (state) => ({
  ...state,
  profile: undefined
});

const apiManifestSuccess = (state, {payload}) => ({
  ...state,
  manifest: {
    ...payload,
    filters: payload.filters.map(filter => ({ //TODO: quitar esto cuando la api ya devuelva los key bien matcheados
      ...filter,
      key: filter.key === 'priority' ? 'priorities' :
        filter.key === 'specie' ? 'species' :
          filter.key === 'categories' ? 'categories' : 'no_name'
    }))
  }
});

const apiManifestError = (state) => ({
  ...state,
  manifest: undefined
});

const apiCommunitiesSuccess = (state, {payload}) => ({
  ...state,
  communities: payload
});

const apiCommunitiesError = (state) => ({
  ...state,
  communities: []
});

const apiRolesListSuccess = (state, {payload}) => ({
  ...state,
  rolesList: payload
});

const apiRolesListError = (state) => ({
  ...state,
  rolesList: []
});

const apiInterventionZonesSuccess = (state, {payload}) => {
  const payloadFormated = payload.map(interventionZone => ({
    ...interventionZone,
    interventions: interventionZone.interventions.map(intervention => new Intervention(intervention))
  }));

  return {
    ...state,
    interventionZones: payloadFormated
  };
};

const apiResetInterventionZones = (state) => {
  return {
    ...state,
    interventionZones: []
  };
};

const apiGetCommunityFilesReducer = (state, {payload}) => ({
  ...state,
  files: payload
});

const apiResetCommunityFilesReducer = (state) => ({
  ...state,
  files: initialState.files
});

//INTERVENTIONS---------------------------------------------------------------------------------------------------------
const apiCreateIntervention = (state, {payload: {interventionZoneId, intervention: newIntervention}}) => {
  return {
    ...state,
    interventionZones: state.interventionZones.map(interventionZone =>
      interventionZone.id === interventionZoneId ?
        {
          ...interventionZone,
          interventions: [
            ...interventionZone.interventions,
            newIntervention
          ]
        } :
        interventionZone
    )
  };
};

const apiReplaceIntervention = (state, {payload: intervention}) => {
  const newIntervention = new Intervention(intervention);
  const selectedInterventionZone = getInterventionZoneByInterventionId(state.interventionZones, newIntervention.id);

  return {
    ...state,
    interventionZones: state.interventionZones.map(interventionZone =>
      interventionZone.id === selectedInterventionZone.id ?
        {
          ...interventionZone,
          interventions: interventionZone.interventions.map(intervention =>
            intervention.id === newIntervention.id ?
              newIntervention :
              intervention
          )
        } :
        interventionZone
    )
  };
};

const apiReplaceExecution = (state, {payload}) => {
  const execution = payload;
  const selectedInterventionZone = getInterventionZoneByExecutionId(state.interventionZones, execution.id);
  const selectedIntervention = getInterventionIdByExecutionId(state.interventionZones, execution.id);
  
  return {
    ...state,
    interventionZones: [
      ...state.interventionZones.filter(iz => iz.id !== selectedInterventionZone.id),
      {
        ...selectedInterventionZone,
        interventions: [
          ...selectedInterventionZone.interventions.filter(i => i.id !== selectedIntervention.id),
          {
            ...selectedIntervention,
            executions: [
              ...selectedIntervention.executions.filter(e => e.id !== execution.id),
              execution
            ]
          }
        ]
      }
    ]
  };
};

const apiDeleteInterventionSuccess = (state, {payload: {interventionZoneId, interventionId}}) => {
  const selectedInterventionZone = state.interventionZones.find(
    interventionZone => interventionZone.id === interventionZoneId
  );

  return {
    ...state,
    interventionZones: [
      ...state.interventionZones.filter(interventionZone => interventionZone.id !== interventionZoneId),
      {
        ...selectedInterventionZone,
        interventions: [
          ...selectedInterventionZone.interventions.filter(intervention => intervention.id !== interventionId)
        ]
      }
    ]
  };
};
const apiGetInterventionZoneDetailSuccess = (state, {payload}) => ({
  ...state,
  interventionZoneDetail: payload
});
const apiCleanInterventionZoneDetail = (state) => ({
  ...state,
  interventionZoneDetail: undefined
});

//EXECUTIONS------------------------------------------------------------------------------------------------------------
const apiDeleteExecutionSuccess = (state, {payload: {executionId, interventionZoneId, interventionId}}) => {
  const selectedInterventionZone = state.interventionZones.find(interventionZone => interventionZone.id === interventionZoneId);

  return {
    ...state,
    interventionZones: state.interventionZones.map(interventionZone =>
      interventionZone.id === selectedInterventionZone.id ?
        {
          ...interventionZone,
          interventions: interventionZone.interventions.map(intervention =>
            intervention.id === interventionId ?
              {
                ...intervention,
                executions: intervention.executions.filter(execution => execution.id !== executionId)
              } :
              intervention
          )
        } :
        interventionZone
    )
  };
};

//FILES-----------------------------------------------------------------------------------------------------------------
const apiUploadFileSuccess = (state, {payload: {interventionZoneId, interventionId, files}}) => {
  const selectedInterventionZone = state.interventionZones.find(interventionZone => interventionZone.id === interventionZoneId);
  const selectedIntervention = selectedInterventionZone.interventions.find(intervention => intervention.id === interventionId);
  return {
    ...state,
    interventionZones: [
      ...state.interventionZones.filter(interventionZone => interventionZone.id !== interventionZoneId),
      {
        ...selectedInterventionZone,
        interventions: [
          ...selectedInterventionZone.interventions.filter(intervention => intervention.id !== interventionId),
          {
            ...selectedIntervention,
            files: [
              ...files
            ]
          }
        ]
      }
    ]
  };
};
const apiDeleteFileSuccess = (state, {payload: {interventionZoneId, interventionId, fileId}}) => {
  const selectedInterventionZone = state.interventionZones.find(interventionZone => interventionZone.id === interventionZoneId);
  const selectedIntervention = selectedInterventionZone.interventions.find(intervention => intervention.id === interventionId);

  return {
    ...state,
    interventionZones: [
      ...state.interventionZones.filter(interventionZone => interventionZone.id !== interventionZoneId),
      {
        ...selectedInterventionZone,
        interventions: [
          ...selectedInterventionZone.interventions.filter(intervention => intervention.id !== interventionId),
          {
            ...selectedIntervention,
            files: [
              ...selectedIntervention.files.filter(file => file.id !== fileId)
            ]
          }
        ]
      }
    ]
  };
};
const apiUploadCommunityFileSuccess = (state, {payload: files}) => {
  return {
    ...state,
    files
  };
};
const apiDeleteCommunityFileSuccess = (state, {payload: fileId}) => {
  return {
    ...state,
    files: state.files.filter(file => file.id !== fileId)
  };
};

//ANNUALPLANS-----------------------------------------------------------------------------------------------------------
const apiAnnualPlansSuccess = (state, {payload}) => ({
  ...state,
  annualPlans: payload
});
const apiResetAnnualPlans = (state) => {
  return {
    ...state,
    annualPlans: []
  };
};
const apiCreateAnnualPlan = (state, {payload}) => {
  return {
    ...state,
    annualPlans: [
      ...state.annualPlans,
      payload
    ]
  };
};
const apiDeleteAnnualPlan = (state, {payload}) => {
  const noDeletedAnnualPlans = state.annualPlans.filter(plan => plan.id !== payload);
  return {
    ...state,
    annualPlans: [
      ...noDeletedAnnualPlans,
    ]
  };
};

const apiUpdateAnnualPlan = (state, {payload}) => {
  const noChangedAnnualPlans = state.annualPlans.filter(plan => plan.id !== payload.id);
  const interventionsInPlan = payload.interventions;
  const annualPlanToUpdate = {
    id: payload.id,
    year: payload.year
  };

  const updatedInterventionZones = state.interventionZones
    .map(iz => {
      const interv = iz.interventions
        .map(intervention => {
          if (intervention.planned_assigned.some(p => p.id === payload.id) && !interventionsInPlan.includes(intervention.id)) { //Delete plan
            return {
              ...intervention,
              planned_assigned: intervention.planned_assigned.filter(pa => pa.id !== payload.id)
            };
          }
          if (!intervention.planned_assigned.some(p => p.id === payload.id) && interventionsInPlan.includes(intervention.id)) { //Add plan
            return {
              ...intervention,
              planned_assigned: intervention.planned_assigned.concat(annualPlanToUpdate)
            };
          }
          return intervention;
        });
      return {
        ...iz,
        interventions: interv
      };
    }
    );

  return {
    ...state,
    interventionZones: updatedInterventionZones,
    annualPlans: [
      ...noChangedAnnualPlans,
      payload
    ]
  };
};

//COMMONERS-------------------------------------------------------------------------------------------------------------
const apiGetCommonersSuccess = (state, {payload}) => {
  return {
    ...state,
    commoners: payload
  };
};
const apiResetCommoners = (state) => {
  return {
    ...state,
    commoners: []
  };
};
const apiCreateCommonerSuccess = (state, {payload}) => {
  return {
    ...state,
    commoners: [
      ...state.commoners,
      payload
    ]
  };
};
const apiDeleteCommonerSuccess = (state, {payload}) => {
  const noDeletedCommoners = state.commoners.filter(commoner => commoner.id !== payload);
  return {
    ...state,
    commoners: [
      ...noDeletedCommoners
    ]
  };
};
const apiUpdateCommonerSuccess = (state, {payload}) => {
  const indexUpdated = state.commoners.findIndex(commoner => commoner.id === payload.id);
  const commoners = [...state.commoners];
  commoners[indexUpdated] = payload;
  return {
    ...state,
    commoners
  };
};
const apiInviteCommonerSuccess = (state, {payload}) => {
  const indexToInvite = state.commoners.findIndex(commoner => commoner.id === payload.id);
  const commonerToInvite = state.commoners.find(commoner => commoner.id === payload.id);
  const commoners = [...state.commoners];
  commoners[indexToInvite] = {
    ...commonerToInvite,
    invitations: [
      ...commonerToInvite.invitations,
      payload.response
    ]
  };
  return {
    ...state,
    commoners
  };
};
const apiActivateCommonerSuccess = (state, {payload}) => {
  const indexToActive = state.commoners.findIndex(commoner => commoner.id === payload.id);
  const commoners = [...state.commoners];
  commoners[indexToActive] = payload;
  return {
    ...state,
    commoners
  };
};
const apiDeactivateCommonerSuccess = (state, {payload}) => {
  const indexToDeactivate = state.commoners.findIndex(commoner => commoner.id === payload.id);
  const commoners = [...state.commoners];
  commoners[indexToDeactivate] = payload;
  return {
    ...state,
    commoners
  };
};

//BUSINESS--------------------------------------------------------------------------------------------------------------
const apiGetBusinessSuccess = (state, {payload}) => {
  return {
    ...state,
    business: payload
  };
};
const apiGetBusinessWithLocationSuccess = (state, {payload}) => {
  return {
    ...state,
    businessWithLocation: payload
  };
};

//MADERA PLUS-----------------------------------------------------------------------------------------------------------
const apiMaderaPlusGetSpeciesSuccess = (state, {payload}) => {
  return {
    ...state,
    maderaPlus_species: payload.especies
  };
};
const apiMaderaPlusGetDistrictSuccess = (state, {payload}) => {
  return {
    ...state,
    maderaPlus_district: Number(payload.distrito)
  };
};

//ACOUNTBOOK------------------------------------------------------------------------------------------------------------
const apiGetAccountEntriesSuccess = (state, {payload}) => {
  return {
    ...state,
    accountEntries: payload
  };
};
const apiResetAccountEntries = (state) => {
  return {
    ...state,
    accountEntries: []
  };
};
const apiCreateAccountEntry = (state, {payload}) => {
  return {
    ...state,
    accountEntries: [
      ...state.accountEntries,
      payload
    ]
  };
};
const apiDeleteAccountEntrySuccess = (state, {payload}) => {
  const noDeletedAccountEntries = state.accountEntries.filter(entry => entry.id !== payload);
  return {
    ...state,
    accountEntries: [
      ...noDeletedAccountEntries
    ]
  };
};
const apiUpdateAccountEntrySuccess = (state, {payload}) => {
  const noUpdatedAccountEntries = state.accountEntries.filter(entry => entry.id !== payload.id);
  return {
    ...state,
    accountEntries: [
      ...noUpdatedAccountEntries,
      payload
    ]
  };
};

//BATCHES---------------------------------------------------------------------------------------------------------------
const getCommunityBatches = (state, {payload}) => ({
  ...state,
  batches: payload
});

const resetBatches = (state) => ({
  ...state,
  batches: initialState.batches
});

const apiPostCreateBatchSuccess = (state, {payload}) => ({
  ...state,
  batches: [
    ...state.batches,
    {id: payload.id, name: payload.name}
  ]
});


const apiPostDeleteBatchSuccess = (state, {payload: batchId}) => {

  const updatedInterventionZones = state.interventionZones.map(iz => {
    const updatedInterventions = iz?.interventions && iz.interventions.map(i => {
      const updatedExecutions = i?.executions && i.executions.map(e => {
        if (e?.batch && e.batch.id === batchId) {
          return {
            ...e,
            batch: null
          };
        }
        return e;
      });
      return {
        ...i,
        executions: updatedExecutions
      };
    });
    return {
      ...iz,
      interventions: updatedInterventions
    };
  });

  return {
    ...state,
    interventionZones: updatedInterventionZones,
    batches: state.batches.filter(({id}) => id !== batchId)
  };

};

const apiPostUpdateBatchSuccess = (state, {payload}) => {

  const interventionZones = state.interventionZones
    .map(interventionZone => {
      const interventions = interventionZone.interventions
        .map(intervention => {
          return intervention.batch && intervention.batch.id === payload.id ?
            {
              ...intervention,
              batch: {
                id: payload.id,
                name: payload.name
              }
            }
            : intervention;
        });
      return {...interventionZone, interventions};
    });

  return {
    ...state,
    interventionZones,
    batches: state.batches.map(batch =>
      batch.id === payload.id ?
        {
          id: payload.id,
          name: payload.name
        }  :
        batch
    )
  };
};


//MULTIPLE--------------------------------------------------------------------------------------------------------------
const interventionsReducer = (state, {payload}) => {

  const interventionZonesIdsToUpdate = payload.map(intervention => intervention.intervention_zone);

  const finalInterventionZones = state.interventionZones
    .map(iz =>
      interventionZonesIdsToUpdate.includes(iz.id) ?
        {
          ...iz,
          interventions: iz.interventions.map(intervention =>
            payload.map(updatedIntervention => updatedIntervention.id).includes(intervention.id) ?
              new Intervention(payload.find(updatedIntervention => updatedIntervention.id === intervention.id)) :
              intervention
          ).concat(
            payload
              .filter(updatedIntervention => updatedIntervention.intervention_zone === iz.id)
              .filter(updatedIntervention => !iz.interventions.map(intervention => intervention.id).includes(updatedIntervention.id))
              .map(updatedIntervention => new Intervention(updatedIntervention))
          )
        } :
        iz
    );

  return {
    ...state,
    interventionZones: finalInterventionZones
  };
};

//BIOMASCO2-------------------------------------------------------------------------------------------------------------
const apiCalculateCO2Success = (state, {payload}) => {
  const payloadByType = payload.type === 'BASELINE' 
    ? {absorptionBaseLine: payload}
    : {absorptionProject: payload};
  
  return {
    ...state,
    ...payloadByType
  };
};

//ERROR
const apiError = (state, {payload}) => {
  return {
    ...state,
    apiError: payload
  };
};

const apiErrorClean = (state) => ({
  ...state,
  apiError: undefined
});

const apiReducer = handleActions({
  //GENERAL-------------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_LOGIN_SUCCESS]: apiLoginSuccess,
  [apiActionTypes.API_LOGIN_UNAUTHORIZED]: apiLoginUnauthorized,
  [apiActionTypes.API_LOGIN_ERROR]: apiLoginError,
  [apiActionTypes.API_LOGOUT]: apiLogout,

  [apiActionTypes.API_PROFILE_SUCCESS]: apiProfileSuccess,
  [apiActionTypes.API_PROFILE_ERROR]: apiProfileError,

  [apiActionTypes.API_MANIFEST_SUCCESS]: apiManifestSuccess,
  [apiActionTypes.API_MANIFEST_ERROR]: apiManifestError,

  [apiActionTypes.API_COMMUNITIES_SUCCESS]: apiCommunitiesSuccess,
  [apiActionTypes.API_COMMUNITIES_ERROR]: apiCommunitiesError,

  [apiActionTypes.API_ROLES_LIST_SUCCESS]: apiRolesListSuccess,
  [apiActionTypes.API_ROLES_LIST_ERROR]: apiRolesListError,

  [apiActionTypes.API_INTERVENTION_ZONES_SUCCESS]: apiInterventionZonesSuccess,
  [apiActionTypes.API_INTERVENTION_ZONES_ERROR]: apiResetInterventionZones,
  [apiActionTypes.API_RESET_INTERVENTION_ZONES]: apiResetInterventionZones,

  [apiActionTypes.API_GET_COMMUNITY_FILES_SUCCESS]: apiGetCommunityFilesReducer,
  [apiActionTypes.API_RESET_COMMUNITY_FILES]: apiResetCommunityFilesReducer,

  //INTERVENTIONS-------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_GET_INTERVENTION_SUCCESS]: apiReplaceIntervention,
  [apiActionTypes.API_POST_CREATE_INTERVENTION_SUCCESS]: apiCreateIntervention,
  [apiActionTypes.API_POST_CREATE_PLANTATION_SUCCESS]: apiCreateIntervention,
  [apiActionTypes.API_UPDATE_INTERVENTION_SUCCESS]: apiReplaceIntervention,
  [apiActionTypes.API_DELETE_INTERVENTION_SUCCESS]: apiDeleteInterventionSuccess,

  [apiActionTypes.API_GET_INTERVENTION_ZONE_DETAIL_SUCCESS]: apiGetInterventionZoneDetailSuccess,
  [apiActionTypes.API_CLEAN_INTERVENTION_ZONE_DETAIL]: apiCleanInterventionZoneDetail,

  [apiActionTypes.API_POST_NEVER_DO_SUCCESS]: apiReplaceIntervention,

  [apiActionTypes.API_GET_INTERVENTION_ERROR]: apiError,
  [apiActionTypes.API_POST_CREATE_INTERVENTION_ERROR]: apiError,
  [apiActionTypes.API_POST_CREATE_PLANTATION_ERROR]: apiError,
  [apiActionTypes.API_UPDATE_INTERVENTION_ERROR]: apiError,
  [apiActionTypes.API_DELETE_INTERVENTION_ERROR]: apiError,

  [apiActionTypes.API_GET_INTERVENTION_ZONE_DETAIL_ERROR]: apiError,

  [apiActionTypes.API_POST_NEVER_DO_ERROR]: apiError,

  //EXECUTIONS----------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_POST_CREATE_EXECUTION_SUCCESS]: apiReplaceIntervention,
  [apiActionTypes.API_UPDATE_EXECUTION_SUCCESS]: apiReplaceExecution,
  [apiActionTypes.API_DELETE_EXECUTION_SUCCESS]: apiDeleteExecutionSuccess,

  [apiActionTypes.API_POST_CREATE_EXECUTION_ERROR]: apiError,
  [apiActionTypes.API_UPDATE_EXECUTION_ERROR]: apiError,
  [apiActionTypes.API_DELETE_EXECUTION_ERROR]: apiError,

  //FILES---------------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_UPLOAD_FILE_SUCCESS]: apiUploadFileSuccess,
  [apiActionTypes.API_DELETE_FILE_SUCCESS]: apiDeleteFileSuccess,

  [apiActionTypes.API_UPLOAD_COMMUNITY_FILE_SUCCESS]: apiUploadCommunityFileSuccess,
  [apiActionTypes.API_DELETE_COMMUNITY_FILE_SUCCESS]: apiDeleteCommunityFileSuccess,

  [apiActionTypes.API_UPLOAD_FILE_ERROR]: apiError,
  [apiActionTypes.API_DELETE_FILE_ERROR]: apiError,

  [apiActionTypes.API_UPLOAD_COMMUNITY_FILE_ERROR]: apiError,
  [apiActionTypes.API_DELETE_COMMUNITY_FILE_ERROR]: apiError,

  //ANNUALPLANS---------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_GET_ANNUAL_PLANS_SUCCESS]: apiAnnualPlansSuccess,
  [apiActionTypes.API_GET_ANNUAL_PLANS_ERROR]: apiResetAnnualPlans,
  [apiActionTypes.API_RESET_ANNUAL_PLANS]: apiResetAnnualPlans,

  [apiActionTypes.API_CREATE_ANNUAL_PLAN_SUCCESS]: apiCreateAnnualPlan,
  [apiActionTypes.API_COMPLETE_ANNUAL_PLAN_SUCCESS]: apiUpdateAnnualPlan,
  [apiActionTypes.API_DELETE_ANNUAL_PLAN_SUCCESS]: apiDeleteAnnualPlan,
  [apiActionTypes.API_IN_PROCEDURE_ANNUAL_PLAN_SUCCESS]: apiUpdateAnnualPlan,

  [apiActionTypes.API_UPDATE_INTERVENTION_ANNUAL_PLAN_SUCCESS]: apiUpdateAnnualPlan,

  [apiActionTypes.API_GET_ANNUAL_PLANS_ERROR]: apiResetAnnualPlans,

  [apiActionTypes.API_CREATE_ANNUAL_PLAN_ERROR]: apiError,
  [apiActionTypes.API_COMPLETE_ANNUAL_PLAN_ERROR]: apiError,
  [apiActionTypes.API_DELETE_ANNUAL_PLAN_ERROR]: apiError,
  [apiActionTypes.API_IN_PROCEDURE_ANNUAL_PLAN_ERROR]: apiError,

  [apiActionTypes.API_UPDATE_INTERVENTION_ANNUAL_PLAN_ERROR]: apiError,


  //COMMONERS-----------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_GET_COMMONERS_SUCCESS]: apiGetCommonersSuccess,
  [apiActionTypes.API_RESET_COMMONERS]: apiResetCommoners,
  [apiActionTypes.API_CREATE_COMMONER_SUCCESS]: apiCreateCommonerSuccess,
  [apiActionTypes.API_DELETE_COMMONER_SUCCESS]: apiDeleteCommonerSuccess,
  [apiActionTypes.API_UPDATE_COMMONER_SUCCESS]: apiUpdateCommonerSuccess,
  [apiActionTypes.API_INVITE_COMMONER_SUCCESS]: apiInviteCommonerSuccess,
  [apiActionTypes.API_ACTIVATE_COMMONER_SUCCESS]: apiActivateCommonerSuccess,
  [apiActionTypes.API_DEACTIVATE_COMMONER_SUCCESS]: apiDeactivateCommonerSuccess,

  [apiActionTypes.API_GET_COMMONERS_ERROR]: apiError,
  [apiActionTypes.API_CREATE_COMMONER_ERROR]: apiError,
  [apiActionTypes.API_DELETE_COMMONER_ERROR]: apiError,
  [apiActionTypes.API_UPDATE_COMMONER_ERROR]: apiError,
  [apiActionTypes.API_INVITE_COMMONER_ERROR]: apiError,
  [apiActionTypes.API_ACTIVATE_COMMONER_ERROR]: apiError,
  [apiActionTypes.API_DEACTIVATE_COMMONER_ERROR]: apiError,

  //BUSINESS------------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_GET_BUSINESS_SUCCESS]: apiGetBusinessSuccess,
  [apiActionTypes.API_GET_BUSINESS_WITH_LOCATION_SUCCESS]: apiGetBusinessWithLocationSuccess,

  [apiActionTypes.API_GET_BUSINESS_ERROR]: apiError,
  [apiActionTypes.API_GET_BUSINESS_WITH_LOCATION_ERROR]: apiError,

  //MADERAPLUS----------------------------------------------------------------------------------------------------------
  [apiActionTypes.MADERA_PLUS_GET_SPECIES_SUCCESS]: apiMaderaPlusGetSpeciesSuccess,
  [apiActionTypes.MADERA_PLUS_GET_DISTRICT_SUCCESS]: apiMaderaPlusGetDistrictSuccess,

  [apiActionTypes.MADERA_PLUS_GET_SPECIES_ERROR]: apiError,
  [apiActionTypes.MADERA_PLUS_GET_DISTRICT_ERROR]: apiError,

  //ACCOUNT BOOK--------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_GET_ACCOUNT_ENTRIES_SUCCESS]: apiGetAccountEntriesSuccess,
  [apiActionTypes.API_RESET_ACCOUNT_ENTRIES]: apiResetAccountEntries,
  [apiActionTypes.API_CREATE_ACCOUNT_ENTRY_SUCCESS]: apiCreateAccountEntry,
  [apiActionTypes.API_DELETE_ACCOUNT_ENTRY_SUCCESS]: apiDeleteAccountEntrySuccess,
  [apiActionTypes.API_UPDATE_ACCOUNT_ENTRY_SUCCESS]: apiUpdateAccountEntrySuccess,

  [apiActionTypes.API_GET_ACCOUNT_ENTRIES_ERROR]: apiError,
  [apiActionTypes.API_CREATE_ACCOUNT_ENTRY_ERROR]: apiError,
  [apiActionTypes.API_DELETE_ACCOUNT_ENTRY_ERROR]: apiError,
  [apiActionTypes.API_UPDATE_ACCOUNT_ENTRY_ERROR]: apiError,

  //BATCHES-------------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_GET_BATCHES_SUCCESS]: getCommunityBatches,
  [apiActionTypes.API_RESET_BATCHES]: resetBatches,

  [apiActionTypes.API_POST_CREATE_BATCH_SUCCESS]: apiPostCreateBatchSuccess,
  [apiActionTypes.API_POST_DELETE_BATCH_SUCCESS]: apiPostDeleteBatchSuccess,
  [apiActionTypes.API_POST_UPDATE_BATCH_SUCCESS]: apiPostUpdateBatchSuccess,

  [apiActionTypes.API_GET_BATCHES_ERROR]: apiError,

  [apiActionTypes.API_POST_CREATE_BATCH_ERROR]: apiError,
  [apiActionTypes.API_POST_DELETE_BATCH_ERROR]: apiError,
  [apiActionTypes.API_POST_UPDATE_BATCH_ERROR]: apiError,

  //MULTIPLE------------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_POST_MULTIPLE_EXECUTION_SUCCESS]: interventionsReducer,
  [apiActionTypes.API_CREATE_MULTIPLE_INTERVENTIONS_SUCCESS]: interventionsReducer,

  [apiActionTypes.API_POST_MULTIPLE_EXECUTION_ERROR]: apiError,
  [apiActionTypes.API_CREATE_MULTIPLE_INTERVENTIONS_ERROR]: apiError,

  //BIOMASCO2-----------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_POST_CALCULATE_CO2_SUCCESS]: apiCalculateCO2Success,
  [apiActionTypes.API_POST_CALCULATE_CO2_ERROR]: apiError,
  
  //ERROR---------------------------------------------------------------------------------------------------------------
  [apiActionTypes.API_ERROR_CLEAN]: apiErrorClean,

}, initialState);

export default apiReducer;
