import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import DialogPanel from '../../../DialogPanel';
import EditableData from './EditableData';
import {inventory} from '../../../proptypes/inventory';
import {getCurrentYear} from '../../../../utils/getCurrentYear';

const InventoriesDialog = ({inventories, plantingYear, isInventoryDialogOpen, onDialogClose, onInventoryAdd, onPlantingYearAdd}) => {

  if(!isInventoryDialogOpen){
    return <></>;
  }

  const sortedInventories = inventories.length && inventories.sort((a, b) => a.year - b.year);

  const STEPS_IDS = {
    YEAR: 'year',
    PLANTING_YEAR: 'plantingYear',
    AGE: 'age',
    TREE_FEET: 'treeFeet',
    HEIGHT: 'height',
    BASOMETRIC_AREA: 'basometricArea'
  };

  const initialInventory = {
    year: getCurrentYear(),
    treeFeet: (sortedInventories && sortedInventories[sortedInventories.length-1][STEPS_IDS.TREE_FEET]) || 100,
    height: (sortedInventories && sortedInventories[sortedInventories.length-1][STEPS_IDS.HEIGHT]) || 100,
    basometricArea: (sortedInventories && sortedInventories[sortedInventories.length-1][STEPS_IDS.BASOMETRIC_AREA]) || 100,
  };

  const [activeStep, setActiveStep] = useState(0);
  const [newInventory, setNewInventory] = useState(initialInventory);
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleAddInventory = () => {
    onInventoryAdd(newInventory);
    onDialogClose();
    setActiveStep(0);
  };

  const handleCalculateAge = () => {
    const newYear = newInventory.year;
    handlePropertyChange({age: newYear - plantingYear});
    handleNext();
  };


  const handlePropertyChange = (property) => {
    setNewInventory({
      ...newInventory,
      ...property
    });
  };

  const minYear = (sortedInventories && sortedInventories[sortedInventories.length-1][STEPS_IDS.YEAR]);
  const maxYear = new Date().getFullYear();

  const steps = [
    {
      id: STEPS_IDS.PLANTING_YEAR,
      sublabel: 'Año de Plantación',
      sublabelWithSelectedOption: `Año de plantación: ${plantingYear}`,
      content: <Box my={1}>
        <EditableData
          onChange={(value) => onPlantingYearAdd(value)}
          value={plantingYear || getCurrentYear()}
          min={0}
        />
      </Box>
    },
    {
      id: STEPS_IDS.YEAR,
      sublabel: 'Año del Inventario',
      sublabelWithSelectedOption: `Año: ${newInventory.year}`,
      content: <Box my={1}>
        <EditableData
          onChange={(value) => handlePropertyChange({year: value})}
          value={newInventory.year}
          min={minYear}
          max={maxYear}
        />
        {minYear ? <Typography variant='body2' component='span' sx={{mt: 1, color: 'grey'}}>{`Min ${minYear} - `}</Typography> : <></>}
        <Typography variant='body2' component='span' sx={{mt: 1, color: 'grey'}}>{`Máx ${maxYear}`}</Typography>
      </Box>
    },
    {
      id: STEPS_IDS.TREE_FEET,
      sublabel: 'Nª de pies (N)',
      sublabelWithSelectedOption: `N: ${newInventory.treeFeet}`,
      content: <Box my={1}>
        <EditableData
          onChange={(value) => handlePropertyChange({treeFeet: value})}
          value={newInventory.treeFeet}
          min={0}
        />
      </Box>
    },
    {
      id: STEPS_IDS.HEIGHT,
      sublabel: 'Altura dominante (Ho)',
      sublabelWithSelectedOption: `Ho: ${newInventory.height}`,
      content: <Box my={1}>
        <EditableData
          onChange={(value) => handlePropertyChange({height: value})}
          value={newInventory.height}
          min={0}
        />
      </Box>
    },
    {
      id: STEPS_IDS.BASOMETRIC_AREA,
      sublabel: 'Área basimétrica (G)',
      sublabelWithSelectedOption: `G: ${newInventory.basometricArea}`,
      content: <Box my={1}>
        <EditableData
          onChange={(value) => handlePropertyChange({basometricArea: value})}
          value={newInventory.basometricArea}
          min={0}
        />
      </Box>
    }
  ];

  const calculatedSteps = useMemo(() => 
    inventories.length
      ? [...steps.filter(step => step.id !== STEPS_IDS.PLANTING_YEAR)]
      : [...steps], [steps]);

  return <DialogPanel
    isOpen={isInventoryDialogOpen}
    onClose={onDialogClose}
    label='AÑADIR INVENTARIO'
    fullWidth
  >
    <Stepper activeStep={activeStep} orientation="vertical" sx={{ml: 2, mb: 2}}>
      {
        calculatedSteps.map(({id, label, sublabel, sublabelWithSelectedOption, content}) =>
          <Step key={id}>
            <StepLabel>{activeStep + 1 > id ? sublabelWithSelectedOption : sublabel}</StepLabel>
            <StepContent>
              <Typography>{label}</Typography>
              {content}
              <Box mt={2}>
                <Button disabled={activeStep === 0} onClick={handleBack}>Atrás</Button>
                <Button variant='contained' color='secondary'
                  onClick={
                    inventories.length && activeStep === 0 ?
                      handleCalculateAge
                      : activeStep === calculatedSteps.length - 1
                        ? handleAddInventory
                        : handleNext
                  }
                >
                  {
                    activeStep === calculatedSteps.length - 1
                      ? 'GUARDAR'
                      : 'SIGUIENTE'
                  }
                </Button>
              </Box>
            </StepContent>
          </Step>
        )
      }
    </Stepper>
  </DialogPanel>;
};

InventoriesDialog.propTypes = {
  inventories: PropTypes.arrayOf(inventory),
  plantingYear: PropTypes.number,
  isInventoryDialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onInventoryAdd: PropTypes.func.isRequired,
  onPlantingYearAdd: PropTypes.func
};

export default InventoriesDialog;