import {CO2_INTERVENTION_TYPE, EXTRACTION_RATIO_OPTIONS} from '../src/config';

export const co2Interventions = [
  {
    id: 1,
    year: 2019,
    co2InterventionType: CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS,
    we_troncos: 120,
    extraction_tree_proportion: EXTRACTION_RATIO_OPTIONS[0].id,
    residue: 0.5
  },
  {
    id: 2,
    year: 2020,
    co2InterventionType: CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS,
    we_troncos: 100,
    extraction_tree_proportion: EXTRACTION_RATIO_OPTIONS[0].id,
    residue: 0.3
  }
];