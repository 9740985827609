import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogPanel from '../../../DialogPanel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import Typography from '@mui/material/Typography';

import SelectInput from '@geomatico/geocomponents/SelectInput';

import EditableData from './EditableData';

import {
  STEPS_IDS,
  BIOMAS_TYPE_LABELS,
  EXTRACTION_RATIO_OPTIONS,
  CO2_INTERVENTION_TYPE,
  CO2_INTERVENTION_TYPE_OPTIONS
} from '../../../../config';
import {getLabelById} from '../../../../utils/getLabelById';

const PerformancesDialog = ({performances, isPerformancesMenuOpen, mode, onDialogClose, onPerformanceChange}) => {

  if(!isPerformancesMenuOpen){
    return <></>;
  }

  const [activeStep, setActiveStep] = useState(0);
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleNext = () => setActiveStep(activeStep + 1);

  const FORMATTED_EXTRACTION_RATIO = EXTRACTION_RATIO_OPTIONS.map(e => ({
    id: e.id.toString(),
    label: e.label
  }));

  const hasPlanting = useMemo(() => CO2_INTERVENTION_TYPE.PLANTING in performances, [performances]);
  const hasFinalCutting = useMemo(() => CO2_INTERVENTION_TYPE.FINAL_CUTTING in performances, [performances]);
  const hasIntermediateCuttings = useMemo(() => CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS in performances, [performances]);

  const typeInterventionOptions = useMemo(() => {
    return hasPlanting && hasFinalCutting ?
      [{
        id: CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS,
        label: getLabelById(CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS, CO2_INTERVENTION_TYPE_OPTIONS)
      }] :
      hasPlanting ?
        [
          {
            id: CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS,
            label: getLabelById(CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS, CO2_INTERVENTION_TYPE_OPTIONS)
          },
          {
            id: CO2_INTERVENTION_TYPE.FINAL_CUTTING,
            label: getLabelById(CO2_INTERVENTION_TYPE.FINAL_CUTTING, CO2_INTERVENTION_TYPE_OPTIONS)
          }
        ] :
        hasFinalCutting ?
          [
            {
              id: CO2_INTERVENTION_TYPE.PLANTING,
              label: getLabelById(CO2_INTERVENTION_TYPE.PLANTING, CO2_INTERVENTION_TYPE_OPTIONS)
            },
            {
              id: CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS,
              label: getLabelById(CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS, CO2_INTERVENTION_TYPE_OPTIONS)
            }
          ] :
          [
            {
              id: CO2_INTERVENTION_TYPE.PLANTING,
              label: getLabelById(CO2_INTERVENTION_TYPE.PLANTING, CO2_INTERVENTION_TYPE_OPTIONS)
            },
            {
              id: CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS,
              label: getLabelById(CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS, CO2_INTERVENTION_TYPE_OPTIONS)
            },
            {
              id: CO2_INTERVENTION_TYPE.FINAL_CUTTING,
              label: getLabelById(CO2_INTERVENTION_TYPE.FINAL_CUTTING, CO2_INTERVENTION_TYPE_OPTIONS)
            }
          ];
  }, [hasPlanting, hasFinalCutting]
  );

  const initialPerformanceType = typeInterventionOptions[0].id;

  const [newPerformanceType, setNewPerformanceType] = useState(initialPerformanceType);

  const maxAge = useMemo(() => performances?.final_cutting ? performances?.final_cutting.age - 1 : undefined, [performances]);
  const minAge = useMemo(() => performances?.intermediate_cuttings?.length && newPerformanceType === CO2_INTERVENTION_TYPE.FINAL_CUTTING ?
    Math.max(...performances.intermediate_cuttings.map(i => i.age)) + 1 : 1, [performances, newPerformanceType]);

  const initialPerformance = useMemo(() => ({
    age: minAge,
    number_per_ha: 1,
    extraction_ratio: 0.75,
    extraction_tree_proportion: 0.75,
    residue: 0.50
  }), [performances, newPerformanceType]);

  const [newPerformance, setNewPerformance] = useState(initialPerformance);

  useEffect(() => {
    setNewPerformance(initialPerformance);
  }, [newPerformanceType]);

  useEffect(() => {
    activeStep === 0 && setNewPerformanceType(initialPerformanceType);
  }, [activeStep]);

  const handlePropertyChange = (property) => {
    setNewPerformance({
      ...newPerformance,
      ...property
    });
  };
  
  const filterPropertiesByType = (performance, type) => {
    
    return type === CO2_INTERVENTION_TYPE.PLANTING
      ? { number_per_ha: performance.number_per_ha }
      : type === CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS
        ? {
          age: performance.age,
          extraction_ratio: performance.extraction_ratio,
          extraction_tree_proportion: performance.extraction_tree_proportion,
          residue: performance.residue
        } 
        : { age: performance.age, residue: performance.residue };

  };
  
  const handlePerformancesChange = () => {
    const type = newPerformanceType;
    onPerformanceChange(
      type !== CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS // si no es corta intermedia
        ? {
          ...performances,
          [type]: filterPropertiesByType(newPerformance, newPerformanceType)
        }
        : hasIntermediateCuttings ?
          { // corta intermedia y ya hay otras
            ...performances,
            [CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS]: [
              ...performances.intermediate_cuttings,
              filterPropertiesByType(newPerformance, newPerformanceType)
            ]
          }
          : { // primera corta intermedia
            ...performances,
            [CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS]: [filterPropertiesByType(newPerformance, newPerformanceType)]
          }
    );
    onDialogClose();
    setActiveStep(0);
    setNewPerformance(initialPerformance);
  };

  const allSteps = {
    [STEPS_IDS.CO2_INTERVENTION_TYPE]: {
      sublabel: 'Elige una actuación',
      sublabelWithSelectedOption: `Tipo: ${getLabelById(newPerformanceType, CO2_INTERVENTION_TYPE_OPTIONS)}`,
      content: <Box my={1}>
        <SelectInput
          options={typeInterventionOptions}
          onOptionChange={setNewPerformanceType}
          selectedOptionId={newPerformanceType}
          menuSx={{zIndex: 3000}}
        />
        {
          typeInterventionOptions.length === 1 &&
          <Typography variant='body2' sx={{mt: 1, color: 'grey'}}>Sólo se pueden realizar más que Cortas intermedias</Typography>
        }
      </Box>
    },
    [STEPS_IDS.AGE]: {
      sublabel: 'Edad a la que se realiza la actuación (t)',
      sublabelWithSelectedOption: `Edad (t): ${newPerformance.age || initialPerformance.age}`,
      content: <Box my={1}> 
        <EditableData 
          onChange={(value) => handlePropertyChange({age: value})}
          value={newPerformance.age}
          min={minAge}
          max={maxAge}
        />
        <Typography variant='body2' component='span' sx={{mt: 1, color: 'grey'}}>{`Min ${minAge} `}</Typography>
        {maxAge && <Typography variant='body2' component='span' sx={{mt: 1, color: 'grey'}}>{`- Máx ${maxAge}`}</Typography> }
      </Box>
    },
    [STEPS_IDS.NUMBER_PER_HA]: {
      sublabel: 'Número de árboles por hectárea (N)',
      sublabelWithSelectedOption: `Número (N): ${newPerformance.number_per_ha || initialPerformance.number_per_ha}`,
      content: <EditableData
        onChange={(value) => handlePropertyChange({number_per_ha: value})}
        value={newPerformance.number_per_ha}
        min={1}
      />
    },
    [STEPS_IDS.EXTRACTION_RATIO]: {
      sublabel: 'Relación de extracción de la clara (R)',
      sublabelWithSelectedOption: `Extracción de la clara (R): ${newPerformance.extraction_ratio?.label || initialPerformance.extraction_ratio?.label}`,
      content: <SelectInput options={FORMATTED_EXTRACTION_RATIO} selectedOptionId={newPerformance.extraction_ratio?.toString() || EXTRACTION_RATIO_OPTIONS[0].id.toString()}
        onOptionChange={(value) => handlePropertyChange({extraction_ratio: Number(value)})} menuSx={{zIndex: 3000}}/>
    },
    [STEPS_IDS.EXTRACTION_TREE_PROPORTION]: {
      sublabel: 'Proporción de árboles a extraer (i)',
      sublabelWithSelectedOption: `Extracción (i): ${newPerformance.extraction_tree_proportion || initialPerformance.extraction_tree_proportion}`,
      content: <EditableData
        onChange={(value) => handlePropertyChange({extraction_tree_proportion: value})}
        value={newPerformance.extraction_tree_proportion}
        step={0.05}
        min={0.05}
        max={0.95}
      />
    },
    [STEPS_IDS.RESIDUE]: {
      sublabel: 'Restos del suelo (rs)',
      sublabelWithSelectedOption: `Restos (rs): ${newPerformance.residue || initialPerformance.residue}`,
      content: <EditableData
        onChange={(value) => handlePropertyChange({residue: value})}
        value={newPerformance.residue}
        step={0.05}
        min={0.05}
        max={0.95}
      />
    }
  };

  const calculatedSteps = useMemo(() => {
    switch (newPerformanceType) {

    case CO2_INTERVENTION_TYPE.PLANTING:
      return [
        allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
        allSteps[STEPS_IDS.NUMBER_PER_HA]
      ];

    case CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS:
      return [
        allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
        allSteps[STEPS_IDS.AGE],
        allSteps[STEPS_IDS.EXTRACTION_RATIO],
        allSteps[STEPS_IDS.EXTRACTION_TREE_PROPORTION],
        allSteps[STEPS_IDS.RESIDUE],
      ];

    case CO2_INTERVENTION_TYPE.FINAL_CUTTING:
      return [
        allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
        allSteps[STEPS_IDS.AGE],
        allSteps[STEPS_IDS.RESIDUE],
      ];

    }

  }, [newPerformance, newPerformanceType]);


  return <DialogPanel
    isOpen={isPerformancesMenuOpen}
    onClose={onDialogClose}
    label={`${BIOMAS_TYPE_LABELS[mode]}: AÑADIR ACTUACIÓN`}
    fullWidth
  >
    <Stepper activeStep={activeStep} orientation="vertical" sx={{ml: 2, mb: 2}}>
      {
        calculatedSteps.map(({label, sublabel, sublabelWithSelectedOption, content}, index) =>
          <Step key={index}>
            <StepLabel>{activeStep + 1 > index ? sublabelWithSelectedOption : sublabel}</StepLabel>
            <StepContent>
              <Typography>{label}</Typography>
              {content}
              <Box mt={2}>
                <Button disabled={activeStep === 0} onClick={handleBack}>Atrás</Button>
                <Button variant='contained' color='secondary'
                  onClick={
                    activeStep === calculatedSteps.length - 1
                      ? handlePerformancesChange
                      : handleNext
                  }
                >
                  {
                    activeStep === calculatedSteps.length - 1
                      ? 'GUARDAR'
                      : 'SIGUIENTE'
                  }
                </Button>
              </Box>
            </StepContent>
          </Step>
        )
      }
    </Stepper>
  </DialogPanel>;
};

PerformancesDialog.propTypes = {
  isPerformancesMenuOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['BASELINE', 'PROJECT']).isRequired,
  performances: PropTypes.object.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onPerformanceChange: PropTypes.func.isRequired
};

export default PerformancesDialog;