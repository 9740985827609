import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ReadOnlyData from './ReadOnlyData';
import {MACHINING_DIFFICULTY} from '../../../../config';

const MonitoringMassDescription = ({id_rodal, area, species_label, centroid, wildfire_risk, machining_difficulty_id}) => {

  return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'space-around', mt: 1}}>
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
      <ReadOnlyData label='ID Rodal' value={id_rodal}/>
      <ReadOnlyData label='Especie' value={species_label.toUpperCase()}/>
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 12}}>
      <ReadOnlyData label='Superficie' value={area}/>
      <ReadOnlyData label='Coordenadas del centroide (x, y)' value={`${centroid[0]}, ${centroid[1]}`}/>
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', ml: 12}}>
      <ReadOnlyData label='Zona riesgo incendio' value={wildfire_risk}/>
      <ReadOnlyData label='Dificultad mecanizado' value={MACHINING_DIFFICULTY.filter(difficulty => difficulty.id === machining_difficulty_id)[0].label}/>
    </Box>
  </Box>;
};

MonitoringMassDescription.propTypes = {
  id_rodal: PropTypes.number.isRequired,
  area: PropTypes.number.isRequired,
  species_label: PropTypes.string.isRequired,
  centroid: PropTypes.arrayOf(PropTypes.number).isRequired,
  wildfire_risk: PropTypes.string.isRequired,
  machining_difficulty_id: PropTypes.string.isRequired,
};

export default MonitoringMassDescription;