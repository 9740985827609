import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import {useMediaQuery} from '@mui/material';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

//MUI-ICONS
import BorderAllIcon from '@mui/icons-material/BorderAll';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DownloadIcon from '@mui/icons-material/Download';
import FactoryIcon from '@mui/icons-material/Factory';
import FilterListIcon from '@mui/icons-material/FilterList';
import FolderIcon from '@mui/icons-material/Folder';
import GroupsIcon from '@mui/icons-material/Groups';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LogoutIcon from '@mui/icons-material/Logout';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MapIcon from '@mui/icons-material/Map';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

//GEOCOMPONENTS
import Logo_geomatico from '../../img/Logo_geomatico.png';
import Logo_Bikenta_blanco from '../../img/Logo_Bikenta_blanco.png';
import Logo_Bikenta_offline from '../../img/Logo_Bikenta_offline.png';
import MiniSidePanel from '@geomatico/geocomponents/MiniSidePanel';
import ResponsiveHeader from '@geomatico/geocomponents/ResponsiveHeader';
import SearchBox from '@geomatico/geocomponents/SearchBox';
import SidePanel from '@geomatico/geocomponents/SidePanel';

//BIKENTA
import AnnualPlanSidePanel from '../AnnualPlan/AnnualPlanSidePanel';
import BatchList from '../Batch/BatchList';
import BottomMenu from './BottomMenu';
import Carousel from '../Carousel';
import CommunityDocumentationMenu from './CommunityDocumentationMenu';
import InterventionFilterSidePanel from '../Filters/InterventionFilterSidePanel';
import InterventionZoneMenu from '../InterventionZones/InterventionZoneMenu/InterventionZoneMenu';
import MaderaPluspProspectionPointsCalculation from '../InterventionZones/MaderaPlus/MaderaPluspProspectionPointsCalculation';
import MaderaPlusForm from '../InterventionZones/MaderaPlus/MaderaPlusForm';
import MultipleExecutionMenu from '../MultiselectionMenu/MultipleExecutionMenu';
import MultiSelectFilter from '../MultiSelectFilter';
import TopControls from './TopControls';
import InterventionZoneBottomSheet from '../CommunityMap/InterventionZoneBottomSheet';

//UTILS
import IcBatch from '../icons/IcBatch';
import {annualPlan} from '../proptypes/annualPlan';
import {annualPlanSummary} from '../proptypes/annualPlanSummary';
import {categoryFilters} from '../proptypes/categoryFilters';
import {selectedCommunity} from '../proptypes/selectedCommunity';
import {setActiveFilters} from '../../services/localStorage';
import {intervention} from '../proptypes/intervention';
import {
  LEFT_DRAWER_WIDTH,
  FILTERS_ORDER,
  MINI_SIDE_PANEL_WIDTH,
  ACTIONS_ID,
  RIGHT_DRAWER_WIDTH, ROLES_LABEL, VIEW_PERMISSIONS, BIOMASS_CO2_FORM_PLANNING, BIOMASS_CO2_FORM_MONITORING
} from '../../config';

import {prospectingPoints} from '../proptypes/prospectingPoints';
import {roles} from '../proptypes/roles';
import {commoners} from '../proptypes/commoners';
import {fileDetail} from '../proptypes/fileDetail';
import {hasViewPermissions} from '../../modules/useCases';

//STYLES
const responsiveHeaderStyle = {
  '&.MuiAppBar-root': {
    zIndex: 1500,
  },
  '& .ResponsiveHeader-toolbar': {
    backgroundColor: 'secondary.main',
  },
  '& .ResponsiveHeader-logo': {
    width: 170,
    pt: 0
  },
  '& .ResponsiveHeader-title': {
    mt: 2.6
  },
};
const filterIconStyle = {
  margin: '8px 4px 0 8px',
  color: 'white',
  cursor: 'pointer',
};
const sidePanelLeftSx = {
  '& .MuiDrawer-paper': {
    left: MINI_SIDE_PANEL_WIDTH
  }
};
const miniSidePanelStyle = {
  '& .MuiPaper-root': {
    zIndex: 1000,
  },
  '& .MiniSidePanel-itemSelected': {
    '&:hover': {
      bgcolor: 'primary.main'
    },
  },
};

const Layout = ({
  annualPlans,
  annualPlanSummary,
  batches,
  biomassForm,
  multiSelectModeInterventionZoneIds,
  categoryFilters,
  commoners,
  commonInterventions,
  communityFiles,
  fileDetail,
  filters,
  interventions,
  interventionZone,
  isCommunitySelected,
  isInterventionZoneSelected,
  isMaderaPlusMode,
  isMaderaPlusFormOpen,
  isOffline,
  maderaPlusSpecies,
  maderaPlusDistrict,
  multiselect,
  notifications,
  placeholder,
  prospectingPoints,
  role,
  rolesList,
  searchedText,
  selectedActionId,
  selectedAnnualPlan,
  selectedCommunity,
  selectedFilters,
  title,
  toggleMapDashboard,
  onAddButtonClick,
  onAdvanceSearchClick,
  onAnnualPlanClick,
  onAnnualPlanComplete,
  onAnnualPlanCreate,
  onAnnualPlanDelete,
  onAnnualPlanDownload,
  onAnnualPlanInProcedure,
  onAnnualPlanSave,
  onBatchExit,
  onBatchMode,
  onBatchModeMenuClose,
  onBiomasCO2Open,
  onCommunityFileDelete,
  onCommunityFileDownload,
  onCommunityFileUpload,
  onContactRSC,
  onExportFilterResult,
  onCreateBatch,
  onDeleteBatch,
  onEditBatch,
  onFileDetailOpen,
  onGoBack,
  onInterventionAdd,
  onInterventionSelect,
  onLogout,
  onMaderaPlusEnd,
  onMaderaPlusFormOpen,
  onMultiselectActivation,
  onMultiselectDelete,
  onMultiselectEnd,
  onMultiselectExit,
  onMultiselectAll,
  onMultipleExecutionUpdate,
  onNavigationMenuClicked,
  onNeverDo,
  onSearchClick,
  onActionChange,
  onSelectedFilter,
  onStartIconClick,
  onToggleMaderaPlusMode,
  onToggleMapDashboard,
  onDeleteProspectingPoint,
  onViewMapParam,
  onUpdateIntervention,
  readUserNotification
}) => {
  const [isCommunityDocumentationDialogOpen, setCommunityDocumentationDialogOpen] = useState(false);
  const [isAnnualPlanDialogOpen, setAnnualPlanDialogOpen] = useState(false);
  const [isBatchMenuOpen, setBatchMenuOpen] = useState(false);

  const widescreen = theme => useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});

  //MADERAPLUS
  const maderaPlusAvailableSpecies = interventionZone?.species.filter(specie => maderaPlusSpecies.includes(specie.id));
  const initialCalculationSpecie = maderaPlusAvailableSpecies?.length !== 0 ? maderaPlusAvailableSpecies?.[0] : undefined;

  const [calculationSpecie, setCalculationSpecie] = useState(initialCalculationSpecie);

  const handleCalculateWithoutParamsEnd = () => {
    onMaderaPlusEnd();
    onToggleMaderaPlusMode();
  };
  const handleCalculateWithoutParams = (specieId) => {
    setCalculationSpecie(specieId);
    onToggleMaderaPlusMode();
  };

  const handleSelectedFilter = (key, ids) => {
    const existingCategoriesFiltered = [...selectedFilters.map(filter => filter.key)];
    const newCategoriesWithFiltersApplied = ids.length ?
      [...new Set([...existingCategoriesFiltered, key])] :
      existingCategoriesFiltered.filter(category => category !== key);
    const newFilters = newCategoriesWithFiltersApplied.map(category => (
      {
        key: category,
        values: category === key ? [...ids] : selectedFilters.find(filter => filter.key === category).values
      }
    ));
    onSelectedFilter(newFilters);
    setActiveFilters(newFilters);
  };

  const filtersByCategory = (category) => {
    if (selectedFilters.find(selectedFilter => selectedFilter.key === category)) {
      return selectedFilters.find(selectedFilter => selectedFilter.key === category).values;
    } else {
      return [];
    }
  };
  const sortedFiltersByDictionary = FILTERS_ORDER
    .map(sortElement => filters.find(filter => filter.key === sortElement))
    //If FILTERS_ORDER.length > filters.length, don't show undefined
    .filter(filter => filter !== undefined);

  //If filters.length > FILTERS_ORDER.length, show all filters
  const sortedFilters = Array.from(new Set([...sortedFiltersByDictionary, ...filters]));//Si añaden un filtro, aparecerá pero no en orden alfabético

  const mainFilters = sortedFilters
    .map(
      filter => <Box key={filter.key} sx={{display: 'inline-block', mb: 1.5}}>
        <MultiSelectFilter
          title={filter.label}
          onSelectionChange={(ids) => handleSelectedFilter(filter.key, ids)}
          selectedIds={filtersByCategory(filter.key)}
          values={filter.values}
        />
      </Box>
    );

  const interventionFilters = categoryFilters
    .filter(filter => filter.values.length)
    .sort((a, b) => a.key > b.key ? 1 : -1)
    .map(filter => <Box key={filter.key} sx={{display: 'inline-block', mb: 1.5}}>
      <MultiSelectFilter
        title={filter.label}
        onSelectionChange={(ids) => handleSelectedFilter('categories', ids)}
        selectedIds={filtersByCategory('categories')}
        values={filter.values}
      />
    </Box>
    );

  const allFilters = mainFilters.concat(interventionFilters);

  const handleSelectIntervention = (interventionId) => onInterventionSelect && onInterventionSelect(interventionId);

  const handleCommunityDocumentationDialogClose = () => {
    setCommunityDocumentationDialogOpen(false);
    onActionChange(ACTIONS_ID.MAP);
  };
  const handleAnnualPlanDialogClose = () => {
    setAnnualPlanDialogOpen(false);
    onActionChange(ACTIONS_ID.MAP);
  };

  const handleBatchModeDialogClose = () => {
    onBatchModeMenuClose();
  };

  const batchContent = <BatchList
    isBatchMenuOpen={isBatchMenuOpen}
    batches={batches}
    interventions={interventions}
    isOffline={isOffline}
    onBatchExit={onBatchExit}
    onBatchMode={onBatchMode}
    onBatchModeMenuClose={handleBatchModeDialogClose}
    onDeleteBatch={onDeleteBatch}
    onEditBatch={onEditBatch}
    onCreateBatch={onCreateBatch}
  />;

  const multiselectionContent = <MultipleExecutionMenu
    batches={batches}
    categoryFilters={categoryFilters}
    multiSelectModeInterventionZoneIds={multiSelectModeInterventionZoneIds}
    commonInterventions={commonInterventions}
    isOffline={isOffline}
    interventions={interventions}
    multiselect={multiselect}
    role={role}
    onActionChange={onActionChange}
    onMultipleExecutionUpdate={onMultipleExecutionUpdate}
    onMultiselectActivation={onMultiselectActivation}
    onMultiselectDelete={onMultiselectDelete}
    onMultiselectEnd={onMultiselectEnd}
    onMultiselectExit={onMultiselectExit}
    onMultiselectAll={onMultiselectAll}
    onNeverDo={onNeverDo}
    onAddButtonClick={onAddButtonClick}
    onUpdateIntervention={onUpdateIntervention}
  />;

  const CommunityDocumentation = <CommunityDocumentationMenu
    commoners={commoners}
    fileDetail={fileDetail}
    files={communityFiles}
    isOffline={isOffline}
    isCommunityDocumentationDialogOpen={isCommunityDocumentationDialogOpen}
    notifications={notifications}
    role={role}
    rolesList={rolesList}
    onDeleteFile={onCommunityFileDelete}
    onFileDetailOpen={onFileDetailOpen}
    onDownloadFile={onCommunityFileDownload}
    onUploadFile={onCommunityFileUpload}
    onCommunityDocumentationDialogClose={handleCommunityDocumentationDialogClose}
    readUserNotification={readUserNotification}
  />;
  
  const annualPlanContent = <AnnualPlanSidePanel
    annualPlans={annualPlans}
    annualPlanSummary={annualPlanSummary}
    interventions={interventions}
    isOffline={isOffline}
    isAnnualPlanDialogOpen={isAnnualPlanDialogOpen}
    selectedAnnualPlan={selectedAnnualPlan}
    role={role}
    onAnnualPlanCreate={onAnnualPlanCreate}
    onAnnualPlanClick={onAnnualPlanClick}
    onAnnualPlanComplete={onAnnualPlanComplete}
    onAnnualPlanDelete={onAnnualPlanDelete}
    onAnnualPlanDownload={onAnnualPlanDownload}
    onAnnualPlanInProcedure={onAnnualPlanInProcedure}
    onAnnualPlanSave={onAnnualPlanSave}
    onAnnualPlanDialogClose={handleAnnualPlanDialogClose}
  />;

  //MINISIDEPANEL && BOTTOMMENU
  const ACTIONS = useMemo(() =>
    [
      {
        id: ACTIONS_ID.ANNUAL_PLAN,
        visible: true,
        label: 'P.Anual',
        content: <ContentPasteIcon id="miniside-panel-annual-plan-button"/>,
        disabled: !isCommunitySelected || annualPlans.length === 0 || !!biomassForm,
        action: () => {
          onActionChange(ACTIONS_ID.ANNUAL_PLAN);
          setAnnualPlanDialogOpen(true);
          setBatchMenuOpen(false);
        }
      },
      {
        id: ACTIONS_ID.FILTERS,
        visible: true,
        label: 'Filtros',
        content: <FilterListIcon/>,
        disabled: isInterventionZoneSelected || !!biomassForm,
        action: () => onAdvanceSearchClick()
      },
      {
        id: ACTIONS_ID.INTERVENTION_ZONE_DETAIL,
        visible: true,
        label: 'Z.Intervención',
        content: <BorderAllIcon/>,
        disabled: !isInterventionZoneSelected,
        action: () => {}
      },
      {
        id: ACTIONS_ID.COMMUNITY_DOCUMENTATION,
        visible: true,
        label: 'Documentos',
        content: <Badge color="error" variant='dot' invisible={!selectedCommunity  || !notifications.length}>
          <FolderIcon/>
        </Badge>,
        disabled: !isCommunitySelected,
        action: () => {
          onActionChange(ACTIONS_ID.COMMUNITY_DOCUMENTATION);
          setCommunityDocumentationDialogOpen(true);
        }
      },
      {
        id: toggleMapDashboard === ACTIONS_ID.MAP ? ACTIONS_ID.DASHBOARD : ACTIONS_ID.MAP,
        visible: true,
        label: toggleMapDashboard === ACTIONS_ID.MAP ? 'Dashboard' : 'Map',
        content: toggleMapDashboard === ACTIONS_ID.MAP ? <QueryStatsIcon/> : <MapIcon/>,
        disabled: !isCommunitySelected || !!biomassForm,
        action: toggleMapDashboard === ACTIONS_ID.MAP ?
          () => onToggleMapDashboard(ACTIONS_ID.DASHBOARD) :
          () => onToggleMapDashboard(ACTIONS_ID.MAP)
      },
      {
        id: ACTIONS_ID.COMMONERS,
        visible: hasViewPermissions(VIEW_PERMISSIONS.COMMONER_LIST, role),
        label: 'Listado de Comuneros',
        content: <GroupsIcon/>,
        disabled: !isCommunitySelected || !!biomassForm,
        action: () => onActionChange(ACTIONS_ID.COMMONERS)
      },
      {
        id: ACTIONS_ID.BUSINESS,
        visible: true,
        label: 'Listado de Empresas',
        content: <FactoryIcon/>,
        disabled:  !!biomassForm,
        action: () => onActionChange(ACTIONS_ID.BUSINESS)
      },
      {
        id: ACTIONS_ID.ACCOUNT_BOOK,
        visible: hasViewPermissions(VIEW_PERMISSIONS.BOOK_ACCOUNT, role),
        label: 'Libro de cuentas',
        content: <RequestQuoteIcon id='miniside-panel-account-book-button'/>,
        disabled: !isCommunitySelected || !!biomassForm,
        action: () => onActionChange(ACTIONS_ID.ACCOUNT_BOOK)
      },
      {
        id: ACTIONS_ID.MULTISELECTION,
        visible: role !== ROLES_LABEL.COMMONER,
        label: 'Multiselección',
        content: <LibraryAddCheckIcon/>,
        disabled: !isCommunitySelected || isOffline || !!biomassForm,
        action: () => onActionChange(ACTIONS_ID.MULTISELECTION)
      },
      {
        id: ACTIONS_ID.BATCH,
        visible: hasViewPermissions(VIEW_PERMISSIONS.BATCH_LIST, role),
        label: 'Gestión de lotes',
        content: <IcBatch id='miniside-panel-batch-button'/>,
        disabled: !isCommunitySelected || isOffline || !!biomassForm,
        action: () => {
          onActionChange(ACTIONS_ID.BATCH);
          setBatchMenuOpen(true);
        }
      },
    ], [annualPlans, isInterventionZoneSelected, isCommunitySelected, toggleMapDashboard, role, notifications, biomassForm]);

  const VISIBLE_ACTIONS = ACTIONS.filter(action => action.visible);  

  const BOTTOM_MENU_ACTIONS = VISIBLE_ACTIONS.filter(
    action => action.id !== ACTIONS_ID.INTERVENTION_ZONE_DETAIL && action.id !== ACTIONS_ID.MULTISELECTION);

  const handleActionClick = (actionId) => {
    if ([ACTIONS_ID.MAP, ACTIONS_ID.DASHBOARD].includes(actionId)){
      onToggleMapDashboard(actionId);
    } else if (actionId === ACTIONS_ID.COMMUNITY_DOCUMENTATION){
      onActionChange(actionId);
    } /*else if (actionId === ACTIONS_ID.MULTISELECTION) {
      
    }*/ else {
      onActionChange(actionId);
    }
  };
  
  const sidePanels = [
    {
      id: ACTIONS_ID.ANNUAL_PLAN,
      content: annualPlanContent
    },
    {
      id: ACTIONS_ID.FILTERS,
      content: <InterventionFilterSidePanel
        interventionFilters={interventionFilters}
        isCommunitySelected={isCommunitySelected}
        mainFilters={mainFilters}
        onExportFilterResult={onExportFilterResult}
      />
    },
    {
      id: ACTIONS_ID.INTERVENTION_ZONE_DETAIL,
      content: interventionZone && <InterventionZoneMenu
        age={interventionZone.age}
        area={interventionZone.area ? parseFloat(interventionZone.area.toFixed(2)) : 0}
        ua={interventionZone.ua}
        uo={interventionZone.uo}
        elastic_module={(interventionZone && !!interventionZone.length && interventionZone.elastic_module) ? interventionZone.elastic_module : []}
        id={interventionZone.id}
        interventions={interventionZone.interventions}
        isOffline={isOffline}
        isMaderaPlusMode={isMaderaPlusMode}
        maderaPlusAvailableSpecies={maderaPlusAvailableSpecies}
        cuartel={interventionZone.cuartel}
        refcat={interventionZone.refcat}
        role={role}
        selectedAnnualPlan={selectedAnnualPlan}
        stratum={interventionZone.stratum}
        species={interventionZone.species.length !== 0 ? interventionZone.species : []}
        specie={calculationSpecie}
        onBiomasCO2Open={onBiomasCO2Open}
        onContactRSC={onContactRSC}
        onExportClick={() => {}}
        onMaderaPlusFormOpen={onMaderaPlusFormOpen}
        onNotificationClick={() => {}}
        onShareClick={() => {}}
        onToggleMaderaPlusMode={onToggleMaderaPlusMode}
        onInterventionAdd={onInterventionAdd}
        onInterventionSelect={(interventionId) => handleSelectIntervention(interventionId)}
      />
    },
    {
      id: ACTIONS_ID.COMMONERS,
      content: undefined
    },
    {
      id: ACTIONS_ID.COMMUNITY_DOCUMENTATION,
      content: CommunityDocumentation
    },
    {
      id: ACTIONS_ID.ACCOUNT_BOOK,
      content: undefined
    },
    {
      id: ACTIONS_ID.MULTISELECTION,
      content: multiselectionContent
    },
    {
      id: ACTIONS_ID.BATCH,
      content: batchContent
    }
  ];

  const leftSidePanelContent = useMemo(() => {
    return widescreen && sidePanels.find(sidePanel => sidePanel.id === selectedActionId)?.content;
  }, [
    annualPlans,
    annualPlanSummary,
    isCommunitySelected,
    mainFilters,
    interventionFilters,
    selectedActionId,
    selectedAnnualPlan,
    selectedCommunity,
    interventionZone,
    role
  ]);

  //SEARCHTEXT
  const [searchText, setSearchText] = useState(searchedText);
  const handleSearchClick = () => {
    if (!isCommunitySelected) onSearchClick(searchText);
  };
  const handleOnTextChange = (ev) => {
    if (!isCommunitySelected) setSearchText(ev);
  };

  const MaderaPlusFormDialog = () => <MaderaPlusForm
    maderaPlusAvailableSpecies={maderaPlusAvailableSpecies}
    onClose={onMaderaPlusFormOpen}
    onCalculateWithParams={() => {}}
    onCalculateWithoutParams={handleCalculateWithoutParams}
  />;

  return <>
    <Hidden lgUp>{/*MOBILE*/}
      <TopControls id='top-controls'
        searchedText={searchedText}
        placeholder={placeholder}
        selectedFilters={selectedFilters}
        filters={filters}
        categoryFilters={categoryFilters}
        isCommunitySelected={isCommunitySelected}
        isInterventionZoneSelected={isInterventionZoneSelected}
        onSelectedFilter={onSelectedFilter}
        onNavigationMenuClicked={onNavigationMenuClicked}
        onAdvanceSearchClick={onAdvanceSearchClick}
        onGoBack={onGoBack}
        onLogout={onLogout}
        onSearchClick={onSearchClick}
      >
        {CommunityDocumentation}
        {annualPlanContent}
        {batchContent}
        <Box sx={{display: 'flex', my: '10px', overflow: 'auto'}}>
          <DownloadIcon sx={filterIconStyle} onClick={onExportFilterResult}/>
          <Carousel onActiveIndexChange={() => {}}>
            {allFilters}
          </Carousel>
        </Box>
      </TopControls>
      <BottomMenu
        actions={BOTTOM_MENU_ACTIONS}
        onActionClick={(id) => ACTIONS.find(action => action.id === id).action}
      />
      {interventionZone && <InterventionZoneBottomSheet
        elastic_module={(interventionZone && !!interventionZone.length && interventionZone.elastic_module) ? interventionZone.elastic_module : []}
        isOffline={isOffline}
        isMaderaPlusMode={isMaderaPlusMode}
        maderaPlusAvailableSpecies={maderaPlusAvailableSpecies}
        prospectingPoints={prospectingPoints}
        role={role}
        selectedInterventionZone={interventionZone}
        specie={calculationSpecie}
        onMaderaPlusEnd={handleCalculateWithoutParamsEnd}
        onMaderaPlusFormOpen={onMaderaPlusFormOpen}
        onInterventionAdd={onInterventionAdd}
        onInterventionSelect={handleSelectIntervention}
      />}
      { isMaderaPlusFormOpen && MaderaPlusFormDialog() }
    </Hidden>
    <Hidden lgDown>{/*DESKTOP*/}
      <ResponsiveHeader
        logo={<img src={isOffline ? Logo_Bikenta_offline : Logo_Bikenta_blanco} alt="Logo Bikenta" width={150}/>}
        title={title}
        onStartIconClick={onStartIconClick}
        sx={responsiveHeaderStyle}
      >
        <SearchBox
          text={searchText}
          dense
          placeholder={placeholder || ''}
          AdvanceSearchIcon={KeyboardBackspaceIcon}
          onSearchClick={handleSearchClick}
          onTextChange={handleOnTextChange}
          sx={{
            '&.SearchBox-root': {
              width: 300,
            },
          }}
        />
        {
          isCommunitySelected ?
            (isInterventionZoneSelected ?
              <KeyboardBackspaceIcon sx={{ml: 2}} onClick={() => onGoBack()}/>
              : <>
                <FilterListIcon sx={{ml: 2}} onClick={onAdvanceSearchClick}/>
                <KeyboardBackspaceIcon sx={{ml: 2}} onClick={() => onGoBack()}/>
              </>)
            : <FilterListIcon sx={{ml: 2}} onClick={onAdvanceSearchClick}/>
        }
        {
          <Tooltip title="Logout">
            <LogoutIcon sx={{ml: 1, cursor:'pointer'}} onClick={onLogout}/>
          </Tooltip>
        }
      </ResponsiveHeader>
      <MiniSidePanel
        dense
        actions={VISIBLE_ACTIONS}
        selectedActionId={selectedActionId}
        onActionClick={handleActionClick}
        sx={miniSidePanelStyle}
      />
      <SidePanel
        drawerWidth={LEFT_DRAWER_WIDTH}
        anchor="left"
        isOpen={true}
        widescreen={true}
        onClose={() => {}}
        sx={sidePanelLeftSx}
      >
        {leftSidePanelContent}
        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexGrow: 2, minHeight: 25}}>
          <Link href="https://geomatico.es" target="_blank" sx={{display: 'flex', alignItems: 'flex-end'}}>
            <img src={Logo_geomatico} width={80} alt="Logo geomatico"/>
          </Link>
        </Box>
      </SidePanel>
      { isMaderaPlusFormOpen && MaderaPlusFormDialog() }
      {
        isMaderaPlusMode && <SidePanel
          drawerWidth={RIGHT_DRAWER_WIDTH}
          anchor="right"
          isOpen={isMaderaPlusMode}
          widescreen={true}
          onClose={() => {
          }}
        >
          <MaderaPluspProspectionPointsCalculation
            onMaderaPlusEnd={handleCalculateWithoutParamsEnd}
            specie={calculationSpecie}
            district={maderaPlusDistrict}
            prospectingPoints={prospectingPoints}
            onDeletePoint={onDeleteProspectingPoint}
            onViewMapParam={onViewMapParam}
          />
        </SidePanel>
      }
    </Hidden>
  </>;

};

Layout.propTypes = {
  annualPlans: PropTypes.arrayOf(annualPlan),
  annualPlanSummary: annualPlanSummary,
  batches: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, name: PropTypes.string })),
  biomassForm: PropTypes.oneOf([BIOMASS_CO2_FORM_PLANNING, BIOMASS_CO2_FORM_MONITORING]),
  multiSelectModeInterventionZoneIds: PropTypes.arrayOf(PropTypes.number),
  calculationSpecieId: PropTypes.number,
  categoryFilters: categoryFilters,
  commonInterventions: PropTypes.arrayOf(PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  })),
  commoners: commoners,
  communityFiles: PropTypes.array,
  fileDetail: fileDetail,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
      )
    })
  ).isRequired,
  interventions: PropTypes.arrayOf(intervention),
  interventionZone: PropTypes.object,
  isCommunitySelected: PropTypes.bool.isRequired,
  isInterventionZoneSelected: TopControls.propTypes.isInterventionZoneSelected,
  isMaderaPlusMode: PropTypes.bool.isRequired,
  isMaderaPlusFormOpen: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  maderaPlusSpecies: PropTypes.array,
  maderaPlusDistrict: PropTypes.number,
  multiselect: PropTypes.bool,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  ),
  placeholder: TopControls.propTypes.placeholder,
  prospectingPoints: prospectingPoints,
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  rolesList: PropTypes.arrayOf(roles),
  searchedText: TopControls.propTypes.searchedText,
  selectedActionId: PropTypes.string,
  selectedAnnualPlan: annualPlan,
  selectedCommunity: selectedCommunity,
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.number,
          PropTypes.string
        ]))
    })
  ),
  title: PropTypes.string.isRequired,
  toggleMapDashboard: PropTypes.oneOf([ACTIONS_ID.MAP, ACTIONS_ID.DASHBOARD]),
  onAddButtonClick: PropTypes.func,
  onAnnualPlanClick: PropTypes.func,
  onAnnualPlanComplete: PropTypes.func,
  onAnnualPlanCreate: PropTypes.func,
  onAnnualPlanDelete: PropTypes.func,
  onAnnualPlanDownload: PropTypes.func,
  onAnnualPlanInProcedure: PropTypes.func,
  onAnnualPlanSave: PropTypes.func,
  onAdvanceSearchClick: PropTypes.func,
  onBatchExit: PropTypes.func,
  onBatchMode: PropTypes.func,
  onBatchModeMenuClose: PropTypes.func,
  onBiomasCO2Open: PropTypes.func,
  onCommonersListClick: PropTypes.func,
  onCommunityFileDelete: PropTypes.func,
  onCommunityFileDownload: PropTypes.func,
  onCommunityFileUpload: PropTypes.func,
  onContactRSC: PropTypes.func,
  onExportFilterResult: PropTypes.func,
  onCreateBatch: PropTypes.func,
  onDeleteBatch: PropTypes.func,
  onEditBatch: PropTypes.func,
  onFileDetailOpen: PropTypes.func,
  onGoBack: PropTypes.func,
  onInterventionAdd: PropTypes.func,
  onInterventionSelect: PropTypes.func,
  onLogout: PropTypes.func,
  onMaderaPlusEnd: PropTypes.func,
  onMaderaPlusFormOpen: PropTypes.func,
  onMultiselectActivation: PropTypes.func,
  onMultiselectDelete: PropTypes.func,
  onMultiselectEnd: PropTypes.func,
  onMultiselectExit: PropTypes.func,
  onMultiselectAll: PropTypes.func,
  onMultipleExecutionUpdate: PropTypes.func,
  onNavigationMenuClicked: PropTypes.func,
  onNeverDo: PropTypes.func,
  onSearchClick: PropTypes.func,
  onActionChange: PropTypes.func,
  onSelectedFilter: PropTypes.func,
  onStartIconClick: PropTypes.func,
  onToggleMaderaPlusMode: PropTypes.func,
  onToggleMapDashboard: PropTypes.func,
  onDeleteProspectingPoint: PropTypes.func,
  onViewMapParam: PropTypes.func,
  onUpdateIntervention: PropTypes.func,
  readUserNotification: PropTypes.func
};

export default Layout;