import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//BIKENTA
import DialogPanel from '../../DialogPanel';
import EditInterventionGeometryMenu from '../EditGeometryMenu/EditInterventionGeometryMenu';
import InputNumber from './InputNumber';
import InputSelect from './InputSelect';
import InputSpecies from './InputSpecies';
import ToogleScopeIntervention from './ToogleScopeIntervention';

//UTILS
import {basemap} from '../../proptypes/basemap';
import {intervention} from '../../proptypes/intervention';
import {interventionType} from '../../proptypes/interventionType';
import {viewport} from '../../proptypes/viewport';
import {PRIORITIES} from '../../../config';
import {getCurrentYear} from '../../../utils/getCurrentYear';
import {specie} from '../../proptypes/specie';

const IS_PARTIAL = 'partial';

const AddInterventionMenu = ({
  species,
  isAddInterventionMenuOpen,
  isMultipleAddition,
  isEditing,
  categories,
  interventionTypes,
  selectedIntervention,
  onSave,
  onCancel,
  viewport,
  basemap,
  onInputInterventionZoneGeometryStarts,
  onInputInterventionZoneGeometryEnds,
  interventionZoneDetail,
  interventionZone
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isEditingGeometry, setIsEditingGeometry] = useState(false);

  //To display the selected labels for each InputSelect
  const getCategoryGroupLabel = (keyGroup) => categories
    .find(category => category.key === keyGroup).label.es;

  const getCategoryLabel = (keyGroup, keyCategory) => categories
    .find(category => category.key === keyGroup).values
    .find(value => value.key === keyCategory).label.es;

  const getInterventionLabel = (key) => interventionTypes.find(intervention => intervention.id === key)?.label;

  //To display the options for each group
  const categoryGroups = categories
    .map(categoryGroup => {
      return {
        key: categoryGroup.key,
        label: categoryGroup.label.es
      };
    })
    .sort((a, b) => a.label > b.label ? 1 : -1);//TODO Ordenar para que 'Outros' esté al final

  const getCategoriesForGroup = (keyGroup) => keyGroup ? categories
    .find(element => element.key === keyGroup).values
    .map(category => {
      return {
        key: category.key,
        label: category.label.es
      };
    })
    .sort((a, b) => a.label > b.label ? 1 : -1)
    : [];

  const getInterventionTypesForCategory = (keyCategory) => interventionTypes
    .filter(element => element.categories.includes(keyCategory))
    .map(intervention => {
      return {
        key: intervention.id,
        label: intervention.label
      };
    })
    .sort((a, b) => a.label > b.label ? 1 : -1);

  const defaultCategoryGroup = categoryGroups[0].key;
  const defaultCategory = getCategoriesForGroup(defaultCategoryGroup)[0].key;
  const defaultIntervention = getInterventionTypesForCategory(defaultCategory)[0].key;

  const selectedInterventionCategory = selectedIntervention?.categories[0];
  const selectedInterventionIntervention = selectedIntervention?.type.id;
  const selectedInterventionCategoryGroup = selectedInterventionCategory ?
    categories
      .find(group => group.values
        .find(category => category.key === selectedInterventionCategory)).key :
    undefined;

  const maxYear = getCurrentYear() + 30;
  const minYear = getCurrentYear() - 30;
  const initialIntervention = {
    geometry: 'total',
    planned_start_year: selectedIntervention?.planned_start_year || getCurrentYear(),
    planned_end_year: selectedIntervention?.planned_end_year || getCurrentYear(),
    planned_invest: selectedIntervention?.planned_invest || 0,
    planned_income: selectedIntervention?.planned_income || 0,
    done: selectedIntervention?.done || false,
    type: selectedInterventionIntervention || defaultIntervention,
    categoryGroup:  selectedInterventionCategoryGroup || defaultCategoryGroup,
    category: selectedInterventionCategory || defaultCategory,
    is_annual_planned: selectedIntervention?.is_annual_planned || false,
    priority: selectedIntervention?.priority,
  };
  const [newIntervention, setNewIntervention] = useState(initialIntervention);
  const [selectedSpecieId, setSelectedSpecieId] = useState(undefined);

  const PLANTING_CATEGORY = 11;

  const handleCreateOrUpdateIntervention = (newIntervention, specieId) => {
    selectedIntervention?.id ?
      onSave({...newIntervention, id: selectedIntervention.id}, specieId) :
      onSave(newIntervention, specieId);

    setNewIntervention(initialIntervention);
    setActiveStep(0);
  };

  const handleCancel = () => {
    onCancel();
    setNewIntervention(initialIntervention);
    setActiveStep(0);
  };

  const handleBack = () => setActiveStep(activeStep - 1);
  const handleNext = () => setActiveStep(activeStep + 1);

  const handleCategoryGroup = value => {
    const defaultCategory = getCategoriesForGroup(parseInt(value))[0].key;
    setNewIntervention({
      ...newIntervention,
      categoryGroup: parseInt(value),
      category: defaultCategory,
      type: getInterventionTypesForCategory(parseInt(defaultCategory))[0].key
    });
  };
  const handleCategory = value => {
    setNewIntervention({
      ...newIntervention,
      category: parseInt(value),
      type: getInterventionTypesForCategory(parseInt(value))[0].key
    });
  };

  const handleStartGeometryInput = value => {
    if (value === IS_PARTIAL) {
      onInputInterventionZoneGeometryStarts(interventionZone.id);
      setIsEditingGeometry(value === IS_PARTIAL);
    }
  };

  const handleProperty = (property) => {
    setNewIntervention({
      ...newIntervention,
      ...property
    });
  };

  const handleGeometryCancel = () => {
    setIsEditingGeometry(false);
    onInputInterventionZoneGeometryEnds();
  };

  const handleGeometryAccept = feature => {
    setNewIntervention({
      ...newIntervention,
      geom: feature.geometry,
      geometry: IS_PARTIAL
    });
    setIsEditingGeometry(false);
    onInputInterventionZoneGeometryEnds();
  };
  
  const priorities = PRIORITIES.map(priority => ({
    key: priority.id,
    label: priority.label
  }));

  const allSteps = [
    {
      id: 1,
      label: 'Año de inicio y finalización de la intervención:',
      sublabel: 'Fechas',
      sublabelWithSelectedOption: `Inicio ${newIntervention.planned_start_year} - Fin ${newIntervention.planned_end_year}`,
      content: <Box display="flex" flexDirection="row" alignItems="center">
        <InputNumber
          id="add-intervention-menu-inicio"
          label="Inicio"
          max={newIntervention.planned_end_year}
          min={minYear}
          value={newIntervention.planned_start_year}
          onChange={(value) => handleProperty({planned_start_year: value})}
        />
        <InputNumber
          id="add-intervention-menu-fin"
          label="Fin"
          max={maxYear}
          min={newIntervention.planned_start_year}
          value={newIntervention.planned_end_year}
          onChange={(value) => handleProperty({planned_end_year: value})}
        />
      </Box>
    },
    {
      id: 2,
      sublabel: 'Elige una opción',
      sublabelWithSelectedOption: getCategoryGroupLabel(newIntervention.categoryGroup),
      content: <InputSelect
        label="Grupo de categorías"
        value={newIntervention.categoryGroup}
        options={categoryGroups}
        onChange={handleCategoryGroup}
      />
    },
    {
      id: 3,
      sublabel: 'Elige una opción',
      sublabelWithSelectedOption: getCategoryLabel(newIntervention.categoryGroup, newIntervention.category),
      content: <InputSelect
        label="Categorías"
        value={newIntervention.category}
        options={getCategoriesForGroup(newIntervention.categoryGroup)}
        onChange={handleCategory}
      />
    },
    {
      id: 4,
      sublabel: 'Elige una opción',
      sublabelWithSelectedOption: getInterventionLabel(newIntervention.type),
      content: <InputSelect
        label="Tipo de intervención"
        value={Number(newIntervention.type)}
        options={getInterventionTypesForCategory(newIntervention.category)}
        onChange={(value) => handleProperty({type: value})}
      />
    },
    {
      id: 5,
      sublabel: 'Inversión',
      sublabelWithSelectedOption: `Inversión: ${newIntervention.planned_invest} €`,
      content: <TextField
        label='Inversión'
        type='number'
        InputLabelProps={{shrink: true}}
        variant='outlined'
        value={newIntervention.planned_invest}
        onChange={(e) => handleProperty({planned_invest: e.target.value})}
      />
    },
    {
      id: 6,
      sublabel: 'Beneficio',
      sublabelWithSelectedOption: `Beneficio: ${newIntervention.planned_income} €`,
      content: <TextField
        label='Beneficio'
        type='number'
        InputLabelProps={{shrink: true}}
        variant='outlined'
        value={newIntervention.planned_income}
        onChange={(e) => handleProperty({planned_income: e.target.value})}
      />
    },
    {
      id: 7,
      sublabel: 'Prioridad',
      sublabelWithSelectedOption: newIntervention.priority,
      content: <InputSelect
        label="Prioridad"
        value={newIntervention.priority}
        options={priorities}
        onChange={(value) => handleProperty({priority: Number(value)})}
      />
    },
    {
      id: 8,
      sublabel: 'Especie a plantar',
      sublabelWithSelectedOption: 'QUERCUS',
      content: <Box display="flex" flexDirection="row" alignItems="center">
        <InputSpecies species={species} onSpecieChange={(specie) => setSelectedSpecieId(specie.key)}/>
      </Box>
    },
    {
      id: 9,
      sublabel: 'Área de la intervención',
      sublabelWithSelectedOption: `${newIntervention.geometry ? 'Intervención sobre todo el rodal' : 'Intervención sobre una parte del rodal'}`,
      content: <Box display="flex" flexDirection="row" alignItems="center">
        <ToogleScopeIntervention onScopeChange={handleStartGeometryInput}/>
      </Box>
    }
  ];

  const multiselectionIds = [1, 2, 3, 4];
  const multiselectionPlantingIds = [1, 2, 3, 4, 8];
  const editingIds = [1, 2, 3, 4, 5, 6, 7];
  const addingIds = [1, 2, 3, 4, 9];
  const editingAndPlantingIds = [1, 2, 3, 4, 5, 6, 7, 8];
  const addingAndPlantingIds = [1, 2, 3, 4, 8, 9];

  const getSteps = (stepMode) => stepMode.flatMap(id => allSteps.filter(step => step.id === id));

  const calculatedSteps = useMemo(() => {
    const IS_PLANTING_CATEGORY = newIntervention.category === PLANTING_CATEGORY;
    return isMultipleAddition ?
      IS_PLANTING_CATEGORY ? getSteps(multiselectionPlantingIds) : getSteps(multiselectionIds) :
      isEditing ?
        IS_PLANTING_CATEGORY ? getSteps(editingAndPlantingIds) : getSteps(editingIds)
        : IS_PLANTING_CATEGORY ? getSteps(addingAndPlantingIds) : getSteps(addingIds);
  }, [newIntervention]);

  return (isEditingGeometry && interventionZoneDetail ?
    <EditInterventionGeometryMenu
      initialViewport={viewport}
      basemap={basemap}
      baseGeometry={interventionZoneDetail.geom}
      onAcceptFeature={handleGeometryAccept}
      onCancel={handleGeometryCancel}
    />
    :
    <DialogPanel
      isOpen={isAddInterventionMenuOpen}
      onClose={handleCancel}
      label={isEditing ? 'EDITAR INTERVENCIÓN' : 'AÑADIR INTERVENCIÓN'}
      fullWidth
    >
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ml: 2, mb: 2}}>
        {
          calculatedSteps.map(({label, sublabel, sublabelWithSelectedOption, content, id}) => (
            <Step key={id}>
              <StepLabel>{activeStep + 1 > id ? sublabelWithSelectedOption : sublabel}</StepLabel>
              <StepContent>
                <Typography>{label}</Typography>
                {content}
                <Box mt={1}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>Atrás</Button>
                  <Button id="add-intervention-menu-siguiente-anadir"
                    variant='contained' color='secondary'
                    onClick={
                      activeStep === calculatedSteps.length - 1 ?
                        newIntervention.category === PLANTING_CATEGORY ?
                          () => handleCreateOrUpdateIntervention(newIntervention, selectedSpecieId) :
                          () => handleCreateOrUpdateIntervention(newIntervention) :
                        handleNext
                    }
                  >
                    {
                      activeStep === calculatedSteps.length - 1 ?
                        isEditing? 
                          'GUARDAR' : 
                          'AÑADIR' : 
                        'SIGUIENTE'
                    }
                  </Button>
                </Box>
              </StepContent>
            </Step>
          ))
        }
      </Stepper>
    </DialogPanel>
  );
};
AddInterventionMenu.propTypes = {
  isAddInterventionMenuOpen: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isMultipleAddition: PropTypes.bool.isRequired,
  categories: PropTypes.array.isRequired,
  interventionTypes: PropTypes.arrayOf(interventionType),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedIntervention: intervention,
  species: PropTypes.arrayOf(specie),
  viewport: viewport,
  basemap: basemap,
  onInputInterventionZoneGeometryStarts: PropTypes.func,
  onInputInterventionZoneGeometryEnds: PropTypes.func,
  interventionZoneDetail: PropTypes.object,
  interventionZone: PropTypes.object
};

export default AddInterventionMenu;