import React from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {BIOMASS_CO2_FORM_MONITORING, BIOMASS_CO2_FORM_PLANNING} from '../../../../config';


//BIKENTA

//UTILS

//STYLES
const containerSx = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  justifyContent: 'space-between'
};



const BiomasCO2Menu = ({ isOffline, hasPlanning=false, onBiomasCO2Open}) => {
  return <Box sx={containerSx}>
    <Button variant='contained' color='secondary' sx={{mt: 2, width: '250px'}} 
      disabled={isOffline} 
      onClick={() => onBiomasCO2Open(BIOMASS_CO2_FORM_PLANNING)}
    >
      PLANIFICACIÓN
    </Button>
    <Button variant={hasPlanning? 'contained' : 'outlined'} color='secondary' sx={{mt: 2, width: '250px'}} 
      disabled={!hasPlanning || isOffline}
      onClick={() => onBiomasCO2Open(BIOMASS_CO2_FORM_MONITORING)}
    >
      SEGUIMIENTO
    </Button>
  </Box>;
};

export default BiomasCO2Menu;

BiomasCO2Menu.propTypes = {
  isOffline: PropTypes.bool,
  hasPlanning: PropTypes.bool,
  onBiomasCO2Open: PropTypes.func.isRequired
};