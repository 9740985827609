import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import grey from '@mui/material/colors/grey';

import {
  COMMERCIAL_PURPOSE_OPTIONS,
  EXTRACTION_RATIO_OPTIONS,
  CO2_INTERVENTION_TYPE,
  STEPS_IDS,
  FELLING_PROCESSING_TYPE,
  CO2_INTERVENTION_TYPE_OPTIONS,
  FELLING_PROCESSING_OPTIONS
} from '../../../../config';

import EditableData from './EditableData';
import DialogPanel from '../../../DialogPanel';

import {getLabelById} from '../../../../utils/getLabelById';

import SelectInput from '@geomatico/geocomponents/SelectInput';
import {getCurrentYear} from '../../../../utils/getCurrentYear';
import {co2_intervention} from '../../../proptypes/co2_intervention';

const Co2_InterventionDialog = ({allCo2Interventions, isCo2InterventionDialogOpen, onDialogClose, onDialogOpen, onCo2InterventionAdd}) => {

  if(!isCo2InterventionDialogOpen){
    return <></>;
  }
  
  const hasFinalCutting = allCo2Interventions
    .some(intervention => intervention.co2InterventionType === CO2_INTERVENTION_TYPE.FINAL_CUTTING);
  
  const lastFinalCuttingYear = useMemo(() => hasFinalCutting
    ? allCo2Interventions
      .sort((a, b) => b.year - a.year)
      .find(intervention => intervention.co2InterventionType === CO2_INTERVENTION_TYPE.FINAL_CUTTING).year
    : 9999, [allCo2Interventions]);

  const initialCo2Intervention = {
    year: new Date().getFullYear(),
    treeFeet: 1,
    extraction_ratio: 0.75,
    extraction_tree_proportion: 0.75,
    residue: 0.50,
    we_troncos: 0,
    commercialPurpose: COMMERCIAL_PURPOSE_OPTIONS[0].id,
    felling: FELLING_PROCESSING_TYPE.MECHANIZED,
    processing: FELLING_PROCESSING_TYPE.MECHANIZED,
  };

  const typeCo2InterventionsPlantingOption = useMemo(() => {
    return [
      {
        id: CO2_INTERVENTION_TYPE.PLANTING,
        label: getLabelById(CO2_INTERVENTION_TYPE.PLANTING, CO2_INTERVENTION_TYPE_OPTIONS)
      }
    ];
  },[]);

  const typeCo2InterventionsRegenerationOption = useMemo(() => {
    return [
      {
        id: CO2_INTERVENTION_TYPE.NATURAL_REGENERATION,
        label: getLabelById(CO2_INTERVENTION_TYPE.NATURAL_REGENERATION, CO2_INTERVENTION_TYPE_OPTIONS)
      }
    ];
  },[]);

  const typeCo2InterventionsCuttingOptions = useMemo(() => {
    return [
      {
        id: CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS,
        label: getLabelById(CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS, CO2_INTERVENTION_TYPE_OPTIONS)
      },
      {
        id: CO2_INTERVENTION_TYPE.FINAL_CUTTING,
        label: getLabelById(CO2_INTERVENTION_TYPE.FINAL_CUTTING, CO2_INTERVENTION_TYPE_OPTIONS)
      }
    ];
  },[]);

  const [activeStep, setActiveStep] = useState(0);
  const [newCo2Intervention, setNewCo2Intervention] = useState(initialCo2Intervention);
  const [isLastInterventionFinalCuttingType, setIsLastInterventionFinalCuttingType] = useState(false);

  useEffect(() => {
    activeStep === 0 && setNewCo2InterventionType(initialCo2InterventionType);
  }, [activeStep]);

  //DEFINE LA OPCIÓN SELECCIONADA POR DEFECTO EN EL SELECT DEL TIPO DE ACTUACIÓN
  const initialCo2InterventionType = useMemo(() =>
    isLastInterventionFinalCuttingType
      ? [...typeCo2InterventionsPlantingOption, ...typeCo2InterventionsRegenerationOption][0].id
      : allCo2Interventions.length === 0
        ?  [...typeCo2InterventionsPlantingOption, ...typeCo2InterventionsCuttingOptions][0].id
        : typeCo2InterventionsCuttingOptions[0].id,
  [isLastInterventionFinalCuttingType, allCo2Interventions]);
  
  //DEFINE LAS OPCIONES DEL SELECT DEL TIPO DE ACTUACIÓN
  const interventionTypeOptions = useMemo(() => 
    isLastInterventionFinalCuttingType 
      ? [...typeCo2InterventionsPlantingOption, ...typeCo2InterventionsRegenerationOption]
      : allCo2Interventions.length === 0
        ? [...typeCo2InterventionsPlantingOption, ...typeCo2InterventionsCuttingOptions]
        : typeCo2InterventionsCuttingOptions,
  [isLastInterventionFinalCuttingType, allCo2Interventions]
  );

  const [newCo2InterventionType, setNewCo2InterventionType] = useState(initialCo2InterventionType);
  
  const FORMATTED_EXTRACTION_RATIO = EXTRACTION_RATIO_OPTIONS.map(e => ({
    id: e.id.toString(),
    label: e.label
  }));

  const handleBack = () => setActiveStep(activeStep - 1);
  const handleNext = () => setActiveStep(activeStep + 1);

  const handleCloseDialog = () => {
    onDialogClose();
    setActiveStep(0);
    setNewCo2Intervention(initialCo2Intervention);
  };

  const handleAddPlanting = () => {
    setActiveStep(0);
    setNewCo2Intervention(initialCo2Intervention);
  };
  
  const handleAddCo2Intervention = () => {

    switch (newCo2InterventionType) {

    case CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS:
      onCo2InterventionAdd({
        co2InterventionType: newCo2InterventionType,
        year: newCo2Intervention.year,
        residue: newCo2Intervention.residue,
        extraction_tree_proportion: newCo2Intervention.extraction_tree_proportion,
        we_troncos: newCo2Intervention.we_troncos,
        commercialPurpose: newCo2Intervention.commercialPurpose,
        felling: newCo2Intervention.felling,
        processing: newCo2Intervention.processing
      });
      handleCloseDialog();
      break;

    case CO2_INTERVENTION_TYPE.FINAL_CUTTING:
      onCo2InterventionAdd({
        co2InterventionType: newCo2InterventionType,
        year: newCo2Intervention.year,
        residue: newCo2Intervention.residue,
        we_troncos: newCo2Intervention.we_troncos,
        commercialPurpose: newCo2Intervention.commercialPurpose,
        felling: newCo2Intervention.felling,
        processing: newCo2Intervention.processing
      });
      handleAddPlanting();
      setIsLastInterventionFinalCuttingType(true);
      break;

    case CO2_INTERVENTION_TYPE.PLANTING:
      if(isLastInterventionFinalCuttingType) {
        onCo2InterventionAdd({
          co2InterventionType: newCo2InterventionType,
          treeFeet: newCo2Intervention.treeFeet,
          year: lastFinalCuttingYear,
        });
        setNewCo2InterventionType(CO2_INTERVENTION_TYPE.PLANTING);
      } else {
        onCo2InterventionAdd({
          co2InterventionType: newCo2InterventionType,
          treeFeet: newCo2Intervention.treeFeet,
          year: newCo2Intervention.year,
        });
      }
      handleCloseDialog();
      setIsLastInterventionFinalCuttingType(false);
      break;
      
    case CO2_INTERVENTION_TYPE.NATURAL_REGENERATION:
      onCo2InterventionAdd({
        co2InterventionType: newCo2InterventionType,
        treeFeet: newCo2Intervention.treeFeet,
        year: lastFinalCuttingYear,
      });
      setNewCo2InterventionType(CO2_INTERVENTION_TYPE.NATURAL_REGENERATION);
      handleCloseDialog();
      setIsLastInterventionFinalCuttingType(false);
      break;

    default:
      console.warn('Tipo de intervención no reconocido:', newCo2InterventionType);
        
      if (newCo2InterventionType === CO2_INTERVENTION_TYPE.FINAL_CUTTING) {
        onDialogOpen();
      }
    }
  };

  const handlePropertyChange = (property) => setNewCo2Intervention({...newCo2Intervention, ...property});

  const allSteps = {
    [STEPS_IDS.CO2_INTERVENTION_TYPE]: {
      sublabel: 'Actuación',
      sublabelWithSelectedOption: `Actuación: ${getLabelById(newCo2InterventionType, CO2_INTERVENTION_TYPE_OPTIONS)}`,
      content: <Box my={1}>
        <SelectInput
          options={interventionTypeOptions}
          onOptionChange={setNewCo2InterventionType}
          selectedOptionId={newCo2InterventionType}
          menuSx={{zIndex: 3000}}
        />
      </Box>
    },
    [STEPS_IDS.YEAR]: {
      sublabel: 'Año de la actuación',
      sublabelWithSelectedOption: `Año: ${newCo2Intervention.year}`,
      content: <Box my={1}>
        <EditableData
          onChange={(value) => handlePropertyChange({year: value})}
          value={newCo2Intervention.year}
          max={getCurrentYear()}
        />
        <Typography variant='body2' component='span' sx={{mt: 1, color: grey[700]}}>{`Máx ${getCurrentYear()}`}</Typography>
      </Box>
    },
    [STEPS_IDS.NUMBER_PER_HA]: {
      sublabel: 'Número de árboles por hectárea (N)',
      sublabelWithSelectedOption: `Número (N): ${newCo2Intervention.treeFeet || initialCo2Intervention.treeFeet}`,
      content: <Box>
        {
          newCo2InterventionType === CO2_INTERVENTION_TYPE.NATURAL_REGENERATION &&
          <Typography sx={{mb: 1, color: grey[700], fontStyle: 'italic'}}>Indica el Nº de pies que se dejarán cuando se realice el clareo.</Typography>
        }
        <EditableData
          onChange={(value) => handlePropertyChange({treeFeet: value})}
          value={newCo2Intervention.treeFeet}
          min={1}
        />
      </Box>
      
    },
    [STEPS_IDS.EXTRACTION_RATIO]: {
      sublabel: 'Relación de extracción de la clara (R)',
      sublabelWithSelectedOption: `Extracción (R): ${getLabelById(newCo2Intervention.extraction_ratio, EXTRACTION_RATIO_OPTIONS)}`,
      content: <SelectInput
        options={FORMATTED_EXTRACTION_RATIO}
        selectedOptionId={newCo2Intervention.extraction_ratio?.toString() || EXTRACTION_RATIO_OPTIONS[0].id.toString()}
        onOptionChange={(value) => handlePropertyChange({extraction_ratio: Number(value)})}
        menuSx={{zIndex: 3000}}
      />
    },
    [STEPS_IDS.EXTRACTION_TREE_PROPORTION]: {
      sublabel: 'Proporción de árboles a extraer (i)',
      sublabelWithSelectedOption: `Proporción (i): ${getLabelById(newCo2Intervention.extraction_ratio, EXTRACTION_RATIO_OPTIONS)}`,
      content: <EditableData
        onChange={(value) => handlePropertyChange({extraction_tree_proportion: Number(value)})}
        value={newCo2Intervention.extraction_tree_proportion}
        step={0.05}
        min={0.05}
        max={0.95}
      />
    },
    [STEPS_IDS.RESIDUE]: {
      sublabel: 'Restos del suelo (rs)',
      sublabelWithSelectedOption: `Restos (rs): ${newCo2Intervention.residue || initialCo2Intervention.residue}`,
      content: <EditableData
        onChange={(value) => handlePropertyChange({residue: value})}
        value={newCo2Intervention.residue}
        step={0.05}
        min={0.05}
        max={0.95}
      />
    },
    [STEPS_IDS.WE_TRONCOS]: {
      sublabel: 'Toneladas de madera extraída',
      sublabelWithSelectedOption: `Toneladas: ${newCo2Intervention.we_troncos === 0 ? 'POR DEFINIR' : newCo2Intervention.we_troncos}`,
      content: <Box my={1}>
        {
          newCo2Intervention.felling === FELLING_PROCESSING_TYPE.MECHANIZED &&
          <Typography sx={{mb: 1, color: grey[700], fontStyle: 'italic'}}>Introduce el valor de la madera extraída tras pesar los camiones, si aún no conoces el valor podrás introducirlo más adelante, pero será necesario de cara al cálculo de emisiones.</Typography>
        }
        <EditableData
          onChange={(value) => handlePropertyChange({we_troncos: value})}
          value={newCo2Intervention.we_troncos}
        />
      </Box>
    },
    [STEPS_IDS.FELLING_PROCESSING]: {
      sublabel: 'Apeo y procesado',
      sublabelWithSelectedOption: `APEO: ${getLabelById(newCo2Intervention.felling, FELLING_PROCESSING_OPTIONS)} - PROCESADO: ${getLabelById(newCo2Intervention.processing, FELLING_PROCESSING_OPTIONS)} `,
      content: <Box display='flex' flexDirection='column'>
        <Box my={1} sx={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <RadioGroup value={newCo2Intervention.felling} onChange={(e) => handlePropertyChange({felling: e.target.value})}>
            <FormLabel>Apeo</FormLabel>
            <FormControlLabel value={FELLING_PROCESSING_TYPE.MECHANIZED} control={<Radio/>}
              label={getLabelById(FELLING_PROCESSING_TYPE.MECHANIZED, FELLING_PROCESSING_OPTIONS)}/>
            <FormControlLabel value={FELLING_PROCESSING_TYPE.MANUAL} control={<Radio/>}
              label={getLabelById(FELLING_PROCESSING_TYPE.MANUAL, FELLING_PROCESSING_OPTIONS)}/>
          </RadioGroup>
          <RadioGroup
            value={newCo2Intervention.felling === FELLING_PROCESSING_TYPE.MECHANIZED ? FELLING_PROCESSING_TYPE.MECHANIZED : newCo2Intervention.processing}
            onChange={(e) => handlePropertyChange({processing: e.target.value})}
          >
            <FormLabel>Procesado</FormLabel>
            <FormControlLabel value={FELLING_PROCESSING_TYPE.MECHANIZED} control={<Radio/>}
              label={getLabelById(FELLING_PROCESSING_TYPE.MECHANIZED, FELLING_PROCESSING_OPTIONS)}/>
            <FormControlLabel value={FELLING_PROCESSING_TYPE.MANUAL} control={<Radio disabled={newCo2Intervention.felling === FELLING_PROCESSING_TYPE.MECHANIZED}/>}
              label={getLabelById(FELLING_PROCESSING_TYPE.MANUAL, FELLING_PROCESSING_OPTIONS)}/>
          </RadioGroup>
        </Box>
        <Typography sx={{color: grey[700], fontStyle: 'italic'}}>Si el Apeo es mecanizado el Procesado debe ser mecanizado también.</Typography>
      </Box>
    },
    [STEPS_IDS.COMMERCIAL_PURPOSE]: {
      sublabel: 'Producto objetivo',
      sublabelWithSelectedOption: `Producto: ${newCo2Intervention.commercialPurpose}`,
      content: <Box my={1}>
        <SelectInput
          options={COMMERCIAL_PURPOSE_OPTIONS}
          onOptionChange={(value) => handlePropertyChange({commercialPurpose: value})}
          selectedOptionId={newCo2Intervention.commercialPurpose}
          menuSx={{zIndex: 3000}}
        />
      </Box>
    }
  };

  //DEFINE LAS PREGUNTAS EN FUNCIÓN DEL TIPO DE INTERVENCIÓN
  const calculatedSteps = useMemo(() => {
    switch (newCo2InterventionType) {
    
    case CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS:
      return [
        allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
        allSteps[STEPS_IDS.YEAR],
        allSteps[STEPS_IDS.EXTRACTION_RATIO],
        allSteps[STEPS_IDS.EXTRACTION_TREE_PROPORTION],
        allSteps[STEPS_IDS.RESIDUE],
        allSteps[STEPS_IDS.WE_TRONCOS],
        allSteps[STEPS_IDS.COMMERCIAL_PURPOSE],
      ];

    case CO2_INTERVENTION_TYPE.FINAL_CUTTING:
      return [
        allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
        allSteps[STEPS_IDS.YEAR],
        allSteps[STEPS_IDS.RESIDUE],
        allSteps[STEPS_IDS.FELLING_PROCESSING],
        allSteps[STEPS_IDS.WE_TRONCOS], 
        allSteps[STEPS_IDS.COMMERCIAL_PURPOSE],
      ];
      
    case CO2_INTERVENTION_TYPE.PLANTING:
      return isLastInterventionFinalCuttingType 
        ? [
          allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
          allSteps[STEPS_IDS.NUMBER_PER_HA],
        ]
        : [
          allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
          allSteps[STEPS_IDS.YEAR],
          allSteps[STEPS_IDS.NUMBER_PER_HA],
        ];

    case CO2_INTERVENTION_TYPE.NATURAL_REGENERATION:
      return isLastInterventionFinalCuttingType 
        ? [
          allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
          allSteps[STEPS_IDS.NUMBER_PER_HA],
        ]
        : [
          allSteps[STEPS_IDS.CO2_INTERVENTION_TYPE],
          allSteps[STEPS_IDS.YEAR],
          allSteps[STEPS_IDS.NUMBER_PER_HA],
        ];
    
    }
    
  }, [newCo2Intervention, newCo2InterventionType]);

  return <DialogPanel
    isOpen={isCo2InterventionDialogOpen}
    onClose={onDialogClose}
    label='AÑADIR ACTUACIÓN'
    fullWidth
  >
    <Stepper activeStep={activeStep} orientation="vertical" sx={{ml: 2, mb: 2}}>
      {
        calculatedSteps.map(({sublabel, sublabelWithSelectedOption, content}, index) =>
          <Step key={index}>
            <StepLabel>{activeStep > index ? sublabelWithSelectedOption : sublabel}</StepLabel>
            <StepContent>
              {content}
              <Box mt={2}>
                <Button disabled={activeStep === 0} onClick={handleBack}>Atrás</Button>
                <Button variant='contained' color='secondary'
                  onClick={
                    activeStep === calculatedSteps.length - 1
                      ? handleAddCo2Intervention
                      : handleNext
                  }
                >
                  {
                    activeStep === calculatedSteps.length - 1
                      ? 'GUARDAR'
                      : 'SIGUIENTE'
                  }
                </Button>
              </Box>
            </StepContent>
          </Step>
        )
      }
    </Stepper>
    {
      newCo2InterventionType === CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS && <Alert severity="info" sx={{mx: 1, mb: 2}}>
        <Typography sx={{fontStyle: 'italic'}}>
        El apeo y el procesado se consideran mecanizados de cara al cálculo de emisiones.
        </Typography>
      </Alert>
    }
    {
      (newCo2InterventionType === CO2_INTERVENTION_TYPE.PLANTING || newCo2InterventionType === CO2_INTERVENTION_TYPE.NATURAL_REGENERATION) &&
      <Alert severity="info" sx={{mx: 1, mb: 2}}>
        <Typography sx={{fontStyle: 'italic'}}>
        Esta actuación incluye las emisiones de la trituración de restos, del desbroce previo y la preparación del terreneo en el cálculo del consumo del CO<sub>2</sub>
        </Typography>
      </Alert>
    }
  </DialogPanel>;
};

Co2_InterventionDialog.propTypes = {
  allCo2Interventions: PropTypes.arrayOf(co2_intervention),
  isCo2InterventionDialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onDialogOpen: PropTypes.func.isRequired,
  onCo2InterventionAdd: PropTypes.func.isRequired
};

export default Co2_InterventionDialog;