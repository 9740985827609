import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {GeolocateControl} from 'react-map-gl';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//GEOCOMPONENTS
import Map from '@geomatico/geocomponents/Map';
import Dashboard from '../Dashboard/Dashboard';
import LazyLoading from '../LazyLoading';
import CommonersListView from '../../views/CommonersListView';

//BIKENTA
import AccountBookView from '../../views/AccountBookView';
import AddInterventionMenu from '../InterventionZones/AddInterventionMenu/AddInterventionMenu';
import BusinessCard from '../Cards/BusinessCard';
import BusinessListView from '../../views/BusinessListView';
import CommunitiesGroup from './CommunitiesGroup';
import CommunitiesPois from './CommunitiesPois';
import InterventionMenu from '../InterventionZones/InterventionMenu/InterventionMenu';
import InterventionZoneGroup from './InterventionZoneGroup';
import Layout from '../Layout/Layout';
import Legend from './Legend';
import Mask from '../Mask';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import PlanningBiomassForm from '../InterventionZones/InterventionZoneMenu/BiomasCO2/PlanningBiomassForm';
import MonitoringBiomassForm from '../InterventionZones/InterventionZoneMenu/BiomasCO2/MonitoringBiomassForm';

//UTILS
import {annualPlan} from '../proptypes/annualPlan';
import {annualPlanSummary} from '../proptypes/annualPlanSummary';
import {categoryFilters} from '../proptypes/categoryFilters';
import {viewport} from '../proptypes/viewport';
import {intervention} from '../proptypes/intervention';
import layerConstructor from '../layers';
import {PNOA_HISTORICO_BASEMAP} from '../../utils/pnoa';
import {
  MAP_OFFSET,
  ACTIONS_ID,
  PNOA_YEARS,
  ROLES_LABEL,
  MAP_DETAILS,
  RIGHT_DRAWER_WIDTH_INT,
  MAP_OFFSET_WITH_RIGHT_PANEL,
  ELASTIC_MODULE_PARAMS, BIOMASS_CO2_FORM_PLANNING
} from '../../config';
import { Popup } from 'react-map-gl';

//STYLES
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import {roles} from '../proptypes/roles';
import {commoners} from '../proptypes/commoners';
import {fileDetail} from '../proptypes/fileDetail';
import {specie} from '../proptypes/specie';
import AlertDialog from '../AlertDeleteDialog';



const CommunityMap = ({
  absorptionBaseLine,
  absorptionProject,
  activedFilters,
  activeFilters,
  annualPlanInterventionZoneIdsByCategoryGroup,
  annualPlans,
  annualPlansAssignedInSelectedIntervention,
  annualPlansAssignedInSelectedInterventionZones,
  annualPlanSummary,
  apiError,
  basemap,
  batches,
  businessWithLocation,
  carouselCommunityId,
  carouselInterventionZoneId,
  categories,
  categoryFilters,
  commoners,
  communities,
  communityFiles,
  fileDetail,
  filters,
  maderaPlusSpecies,
  maderaPlusDistrict,
  notifications,
  interventions,
  interventionTypes,
  interventionZoneDetail,
  interventionZones,
  interventionZoneIdsFromAnnualPlan,
  interventionZonesForDashboard,
  isLoading,
  isOffline,
  openAdvanceFilters,
  profile,
  role,
  rolesList,
  searchedText,
  selectCommunityOnCarousel,
  selectedAnnualPlan,
  selectedCommunity,
  selectedIntervention,
  selectedInterventionZone,
  selectedinterventionZones,
  selectInterventionZoneOnCarousel,
  species,
  viewport,
  onAnnualPlanDownload,
  onAnnualPlanClick,
  onAnnualPlanComplete,
  onAnnualPlanCreate,
  onAnnualPlanDelete,
  onAnnualPlanInProcedure,
  onAnnualPlanSave,
  onAnnualPlanUpdateIntervention,
  onApiErrorClean,
  onBaseMapChange,
  onCalculateCO2,
  onCommunityFileDelete,
  onCommunityFileDownload,
  onCommunityFileUpload,
  onContactRSC,
  onCreateBatch,
  onCreateExecution,
  onCreateIntervention,
  onCreatePlantation,
  onCreateMultipleInterventions,
  onCreateMultiplePlantations,
  onDeleteBatch,
  onEditBatch,
  onDeleteFile,
  onDeleteIntervention,
  onDownloadFile,
  onExportFilterResult,
  onDeleteExecution,
  onFileDetailOpen,
  onInputInterventionZoneGeometryEnds,
  onInputInterventionZoneGeometryStarts,
  onLogout,
  onMaderaPlusGetDistrict,
  //onMaderaPlusGetPropspectingPoints,
  onMaderaPlusSidePanelOpen,
  onMultipleExecutionUpdate,
  onNeverDo,
  onSearchClick,
  onUploadFile,
  onUpdateExecution,
  onUpdateIntervention,
  onUpdatePlantation,
  onViewportChange,
  readUserNotification,
  accountBookViewProps,
  businessListViewProps,
  commonersListViewProps
}) => {

  // eslint-disable-next-line no-unused-vars
  const [pnoaYear, setPnoaYear] = useState(PNOA_YEARS[PNOA_YEARS.length - 1].value);
  const history = useHistory();
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('lg'), {noSsr: true});
  const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(false);
  const [isAddInterventionMenuOpen, setIsAddInterventionMenuOpen] = useState(false);
  const [isEditPlannedInterventionMenuOpen, setIsEditPlannedInterventionMenuOpen] = useState(false);
  const [interventionZonesToAddIntervention, setInterventionZonesToAddIntervention] = useState(undefined);
  const [isMultipleAddition, setMultipleAddition] = useState(false);
  const [isInterventionMenuOpen, setIsInterventionMenuOpen] = useState(true);
  const [communityPreSelect, setCommunityPreSelect] = useState(undefined);
  const [selectedActionId, setSelectedActionId] = useState({prev: undefined, act: ACTIONS_ID.FILTERS});
  const [toggleMapDashboard, setToggleMapDashboard] = useState(ACTIONS_ID.MAP);
  const [selectedDetailsMaps, setSelectedDetailsMaps] = useState([]);//['anualPlan','especies']
  const [businessFeatureClicked, setBusinessFeatureClicked] = useState(undefined);
  const [multiselectMode, setMultiselectMode] = useState({activated: false, interventionZoneIds: []});
  const [batchMode, setBatchMode] = useState({activated: false, batchId: undefined, interventionZoneIds: []});
  const [biomassForm, setBiomasForm] = useState();

  const mapRef = useRef();
  const match = useRouteMatch();

  //MADERAPLUS MODE
  const [isMaderaPlusMode, onToggleMaderaPlusMode] = useState(false);
  const [prospectingPoints, setProspectingPoints] = useState([]);
  const [paramIdMap, setParamIdMap] = useState(undefined); //Param from Madera Plus
  //const [specieId, setSpecieId] = useState(null);

  const mapWrapperStyle = useMemo (() => {
    return {
      position: 'absolute',
      width: {
        xs: '100%',
        lg: isMaderaPlusMode ? `calc(100% - ${MAP_OFFSET_WITH_RIGHT_PANEL})`: `calc(100% - ${MAP_OFFSET})`
      },
      height: {
        xs: '100%',
        lg: 'calc(100% - 64px)',
      },
      right: {
        xs: 0,
        lg: isMaderaPlusMode ? RIGHT_DRAWER_WIDTH_INT : 0
      },
      bottom: 0
    };
  }, [isMaderaPlusMode]);

  const handleToggleMaderaPlusMode = () => onToggleMaderaPlusMode(!isMaderaPlusMode);
  const [pointId, setPointId]= useState(1);
  const handleMaderaPlusAddPointSample = ({lng, lat}) => {
    const newPoint = {
      type: 'Feature',
      id: pointId,
      geometry: {
        type: 'Point',
        coordinates: [lng, lat]
      },
      properties:{}
    };
    const allPoints = prospectingPoints.concat(newPoint);
    setProspectingPoints(allPoints);
    setPointId(pointId+1);
  };

  const handleDeleteProspectingPoint = useCallback((id) => {
    const allPoints = prospectingPoints.filter(point => point.id !== id);
    setProspectingPoints(allPoints);
  }, [prospectingPoints]);

  const handleMaderaPlusEnd = useCallback(() => {
    setProspectingPoints([]);
    setPointId(1);
    setParamIdMap(undefined);
  }, []);

  const disableMaderaPlusMode = () => {
    onToggleMaderaPlusMode(false);
    setPointId(1);
    setProspectingPoints([]);
  };

  const handlePopupClose = () => setBusinessFeatureClicked(false);

  const {communityId} = match.params;

  const zoomOutCommunity = () => {
    const selectedBbox = selectedCommunity.bbox;

    if (selectedBbox.xmin && selectedBbox.xmax && selectedBbox.ymin && selectedBbox.ymax) {
      mapRef.current?.fitBounds([[selectedBbox.xmin, selectedBbox.ymin], [selectedBbox.xmax, selectedBbox.ymax]],
        {
          duration: 2000,
          padding: widescreen ? {
            left: 50,
            right: 50,
            top: 50,
            bottom: 250
          } : 50
        }
      );
    }
  };

  useEffect(() => {
    if (communities && communities.length) {
      const {xmin, ymin, xmax, ymax} = selectedCommunity ?
        selectedCommunity !== communityId ?
          selectedCommunity.bbox :
          undefined :
        communities.reduce((acc, {bbox}) => {
          // generamos un bbox a partir de los bbox de todas las comunidades
          ['xmin', 'ymin', 'xmax', 'ymax'].map(prop => {
            // eslint-disable-next-line no-prototype-builtins
            if (acc.hasOwnProperty(prop)) {
              if (prop.includes('min')) {
                if (bbox[prop] < acc[prop]) {
                  acc[prop] = bbox[prop];
                }
              } else {
                if (bbox[prop] > acc[prop]) {
                  acc[prop] = bbox[prop];
                }
              }
            } else {
              acc[prop] = bbox[prop];
            }
          });
          return acc;
        }, {});

      if (xmin && xmax && ymin && ymax) {
        mapRef.current?.fitBounds([[xmin, ymin], [xmax, ymax]],
          {
            duration: 2000,
            padding: widescreen ? {
              left: 50,
              right: 50,
              top: 50,
              bottom: 250
            } : 50
          }
        );
      }
    }
  }, [communities, selectedCommunity]);

  useEffect(() => {
    if (carouselCommunityId) {
      const {centroid} = communities.find(community => community.id === carouselCommunityId);
      mapRef.current?.flyTo({
        center: [centroid.lat, centroid.lon],
        zoom: 9
      }
      );
    }
  }, [carouselCommunityId]);

  useEffect(() => {
    if (carouselInterventionZoneId) {
      const interventionZone = interventionZones.find(interventionZone => interventionZone.id === carouselInterventionZoneId);

      // esto está así porque en el estado transitivo de una comunidad a otra, llega el nuevo carouselId, pero las interventionZones
      // de la comunidad anterior, y el find da undefined, cuando llegan las actualizadas vuelve a funcionar.
      if(interventionZone) {
        const {
          xmin,
          ymin,
          xmax,
          ymax
        } = interventionZone.bbox;
        mapRef.current?.fitBounds([[xmin, ymin], [xmax, ymax]],
          {
            duration: 1000,
            padding: widescreen ? 150 : {
              left: 50,
              right: 50,
              top: selectedInterventionZone ? 50 : 200,
              bottom: selectedInterventionZone ? window.innerHeight / 2 + 50 : 300
            }
          });
      }

    }
  }, [carouselInterventionZoneId, selectedInterventionZone, interventionZones, isMaderaPlusMode]);

  const communitiesPois = useMemo(() => {
    return communities?.length ?
      communities.map(({id, centroid}) => ({id, ...centroid})) :
      [];
  }, [communities]);

  const sources = useMemo(() => ({
    xorfor: {
      type: 'vector',
      tiles: [
        `${process.env.TEGOLA_URL}xorfor/{z}/{x}/{y}.pbf`
      ],
      minzoom: 7,
      maxzoom: 16
    },
    maderaPlusParams: {
      type: 'raster',
      tiles: [
        `https://visor.maderaplus.es/geoserver/Galicia/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Galicia:${(paramIdMap !== '31' && paramIdMap !== '32') ? paramIdMap+'_26' : paramIdMap}`
        //`https://visor.maderaplus.es/geoserver/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Galicia:${(paramIdMap !== '31' && paramIdMap !== '32') ? paramIdMap+'_'+specieId : paramIdMap}`
      ],
      bounds: selectedCommunity ?
        [selectedCommunity.bbox.xmin, selectedCommunity.bbox.ymin, selectedCommunity.bbox.xmax, selectedCommunity.bbox.ymax] :
        [-180,-85.051129,180,85.051129],
      minzoom: 14,
      maxzoom: 16
    },
    resfor: {
      type: 'geojson',
      data: businessWithLocation
    },
    maderaPlus: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: prospectingPoints
      }
    },
    afecciones: {
      type: 'vector',
      tiles: [
        'https://tileserver.geomatico.es/data/bikenta-afecciones/{z}/{x}/{y}.pbf'
      ],
      minzoom: 7,
      maxzoom: 14
    },
  }), [businessWithLocation, prospectingPoints, paramIdMap, selectedCommunity]);

  const handleDetailMapChange = (newSelectedDetailsMaps) => {
    newSelectedDetailsMaps !== selectedDetailsMaps &&
      setSelectedDetailsMaps(newSelectedDetailsMaps);
  };

  const layers = useMemo(() => {
    const interventionZonesIds = interventionZones.map(({id}) => id);
    const interventionZoneId = selectedInterventionZone && selectedInterventionZone.id;
    let layers = [];

    if (selectedAnnualPlan) {
      if (selectedDetailsMaps.includes(MAP_DETAILS.especies)) {
        layers = layerConstructor.species(selectedCommunity.id, interventionZonesIds, interventionZoneId, carouselInterventionZoneId, interventionZoneIdsFromAnnualPlan);
      } else {
        layers = layerConstructor.annualPlan(selectedCommunity.id, interventionZonesIds, interventionZoneId, annualPlanInterventionZoneIdsByCategoryGroup);
      }
    } else if (selectedCommunity) {
      if (selectedDetailsMaps.includes(MAP_DETAILS.especies)) {
        layers = layerConstructor.species(selectedCommunity.id, interventionZonesIds, interventionZoneId, carouselInterventionZoneId);
      } else if (multiselectMode.activated) {
        layers = layerConstructor.multiselect(
          selectedCommunity.id,
          interventionZonesIds,
          multiselectMode.interventionZoneIds,
          interventionZoneId,
          carouselInterventionZoneId);
      }  else if (batchMode.activated) {
        layers = layerConstructor.batch(
          selectedCommunity.id,
          interventionZonesIds,
          batchMode.interventionZoneIds,
          interventionZoneId,
          carouselInterventionZoneId);
      } else {
        layers = layerConstructor.normal(selectedCommunity.id, interventionZonesIds, interventionZoneId, carouselInterventionZoneId);
        if(!!interventionZoneId && isMaderaPlusMode) {
          const maderaPlusProspectingPoints = layerConstructor.maderaPlusProspectingPoints();
          layers.push(...maderaPlusProspectingPoints);
          if(paramIdMap){
            const maderaPlusParamsMap = layerConstructor.maderaPlusParamsMap;
            layers.push(...maderaPlusParamsMap);
          }
        }
      }
    }
    if(selectedDetailsMaps.includes(MAP_DETAILS.business)) {
      const business = layerConstructor.business();
      layers.push(business);
    }
    if(selectedDetailsMaps.includes(MAP_DETAILS.forestal)) {
      const morelayers = layerConstructor.forestal();
      layers.push(...morelayers);
    }
    if(selectedDetailsMaps.includes(MAP_DETAILS.patrimonio)) {
      const morelayers = layerConstructor.patrimonio();
      layers.push(...morelayers);
    }
    return layers;
    
  }, [selectedCommunity, interventionZones, selectedInterventionZone, carouselInterventionZoneId, selectedDetailsMaps, annualPlanInterventionZoneIdsByCategoryGroup, selectedAnnualPlan, isMaderaPlusMode, multiselectMode, paramIdMap, batchMode]);

  const handleMapClick = ({point, lngLat}) => {
    const getFeatureId = (feature) => feature.properties.id;
    if (selectedCommunity && selectedDetailsMaps.includes(MAP_DETAILS.business)) {
      const clickedFeature = mapRef.current?.queryRenderedFeatures(point, {layers: ['interventionZone', 'resfor']})[0];
      if (clickedFeature) {
        if(clickedFeature.layer.id === 'interventionZone') {
          if(!multiselectMode.activated) {
            handleInterventionZoneSelect(getFeatureId(clickedFeature));
          }
        }
        if (clickedFeature.layer.id === 'resfor') {
          setBusinessFeatureClicked(clickedFeature);
        }
      } else {
        if(!multiselectMode.activated) {
          handleInterventionZoneSelect();
        }
      }
    } else if (selectedCommunity) {
      const clickedFeature = mapRef.current?.queryRenderedFeatures(point, {layers: ['interventionZone']})[0];
      if (clickedFeature) {
        if (multiselectMode.activated) {
          handleInterventionZoneMultiselect(getFeatureId(clickedFeature));
        } else {
          handleInterventionZoneSelect(getFeatureId(clickedFeature));
          setSelectedActionId({prev: ACTIONS_ID.BATCH, act: ACTIONS_ID.INTERVENTION_ZONE_DETAIL});
          setBatchMode({activated: false, batchId: undefined, interventionZoneIds: []});
        }
      } else {
        multiselectMode.activated || batchMode.activated ? undefined : handleInterventionZoneSelect();
      }
      if(selectedInterventionZone && isMaderaPlusMode) {
        handleMaderaPlusAddPointSample(lngLat);
      }
    } else if (selectedDetailsMaps.includes(MAP_DETAILS.business)) {
      const clickedFeature = mapRef.current?.queryRenderedFeatures(point, {layers: ['resfor']})[0];
      if (clickedFeature) {
        setBusinessFeatureClicked(clickedFeature);
      } else {
        return undefined;
      }
    }
  };

  const handleSelectCommunity = (communityId) => {
    if (communityId) {
      history.push(`/community/${communityId}`);
    } else {
      setToggleMapDashboard(ACTIONS_ID.MAP);
      history.push('/');
    }
  };

  const handleNavigationMenuClose = () => setIsNavigationMenuOpen(!isNavigationMenuOpen);
  const handleStyleMapChange = (basemap) =>
    onBaseMapChange(basemap === 'pnoa-historico' ? PNOA_HISTORICO_BASEMAP(pnoaYear) : basemap);

  useEffect(() => {
    if (basemap?.name === 'PNOA') {
      onBaseMapChange(PNOA_HISTORICO_BASEMAP(pnoaYear));
    }
  }, [pnoaYear]);

  const handleInterventionZoneSelect = (interventionZoneId) => {
    if (interventionZoneId) {
      history.push(`/community/${selectedCommunity.id}/interventionZone/${interventionZoneId}`);
    } else {
      history.push(`/community/${selectedCommunity.id}`);
      disableMaderaPlusMode();
    }
  };
  const handleCreateIntervention = () => setIsAddInterventionMenuOpen(true);

  const handleAddButtonClick = (interventionZoneIds) => {
    setMultipleAddition(true);
    setIsAddInterventionMenuOpen(!isAddInterventionMenuOpen);
    setInterventionZonesToAddIntervention(interventionZoneIds);
  };

  const handleAddOrUpdateIntervention = (newIntervention, specieId) => {
    if (newIntervention.id) { //EDITAR
      specieId ?
        onUpdatePlantation(newIntervention.id, newIntervention, specieId) : //Editar Plantación
        onUpdateIntervention(newIntervention.id, newIntervention); //Editar cualquier Intervención
      setIsEditPlannedInterventionMenuOpen(false);
    } else {//CREAR
      if(isMultipleAddition) {//MULTIPLE
        specieId ?
          onCreateMultiplePlantations(interventionZonesToAddIntervention, newIntervention, specieId) : //Crear Múltiple Plantación
          onCreateMultipleInterventions(interventionZonesToAddIntervention, newIntervention); //Crear Múltiple cualquier Intervención
      } else {//NO MULTIPLE
        specieId ?
          onCreatePlantation(newIntervention, specieId) : //Crear Plantación
          onCreateIntervention(newIntervention); //Crear cualquier Intervención
      }
    }
    setIsAddInterventionMenuOpen(false);
    setMultipleAddition(false);
    setInterventionZonesToAddIntervention(undefined);
  };

  const handleInterventionMenuEditEnd = () => {
    setIsInterventionMenuOpen(false);
    history.push(`/community/${selectedCommunity.id}/interventionZone/${selectedInterventionZone.id}`);
  };

  const handleCloseInterventionMenu = () => history.push(`/community/${selectedCommunity.id}/interventionZone/${selectedInterventionZone.id}`);

  const handleSelectIntervention = (interventionId) => {
    if (interventionId) {
      history.push(`/community/${selectedCommunity.id}/interventionZone/${selectedInterventionZone.id}/intervention/${interventionId}`);
      setIsInterventionMenuOpen(true);
    } else {
      history.push(`/community/${selectedCommunity.id}/interventionZone/${selectedInterventionZone.id}`);
      setIsInterventionMenuOpen(false);
    }
  };
  const handleEditPlannedIntervention = () => setIsEditPlannedInterventionMenuOpen(true);

  const handleDeleteIntervention = (interventionId) => {
    onDeleteIntervention(selectedInterventionZone.id, interventionId);
    setIsInterventionMenuOpen(false);
    history.push(`/community/${selectedCommunity.id}/interventionZone/${selectedInterventionZone.id}`);
  };
  const handleExportFilterResult = () => {
    const selectedCommunityId = selectedCommunity?.id;
    onExportFilterResult(selectedCommunityId, interventionZones.map(interventionZone => interventionZone.id));
  };

  const handleBatchExit = () => {
    history.push(`/community/${selectedCommunity.id}`);
    setSelectedActionId({prev: ACTIONS_ID.BATCH, act: ACTIONS_ID.FILTERS});
    setBatchMode({activated: false, batchId: undefined, interventionZoneIds: []});
  };

  const handleMultiselectExit = () => {
    history.push(`/community/${selectedCommunity.id}`);
    setSelectedActionId({prev: ACTIONS_ID.BATCH, act: ACTIONS_ID.FILTERS});
    setMultiselectMode({activated: false, interventionZoneIds: []});
  };

  const handleSelectedActionChange = (actionId) => {
    setSelectedActionId({prev: selectedActionId.act, act: actionId});
    if (actionId === ACTIONS_ID.ANNUAL_PLAN) {
      setMultiselectMode({activated: false, interventionZoneIds: []});
      setBatchMode({activated: false, batchId: undefined, interventionZoneIds: []});
      zoomOutCommunity();
    }
    if (actionId === ACTIONS_ID.INTERVENTION_ZONE_DETAIL) {
      setMultiselectMode({activated: false, interventionZoneIds: []});
      setBatchMode({activated: false, batchId: undefined, interventionZoneIds: []});
    }
    if (actionId === ACTIONS_ID.MULTISELECTION) {
      setMultiselectMode({activated: true, interventionZoneIds: []});
      if(selectedInterventionZone) {
        history.push(`/community/${selectedCommunity.id}`);
      }
      setBatchMode({activated: false, batchId: undefined, interventionZoneIds: []});
      zoomOutCommunity();
    }
    if (actionId === ACTIONS_ID.BATCH) {
      zoomOutCommunity();
      setBatchMode({activated: true, batchId: undefined, interventionZoneIds: []});
      setMultiselectMode({activated: false, interventionZoneIds: []});
    }
  };
  const handleToggleMapDashboard = (option) => setToggleMapDashboard(option);

  //DEFINE THE ACTION WHEN STARTING
  useEffect(() => {
    if (selectedInterventionZone) {
      handleSelectedActionChange(widescreen ? ACTIONS_ID.INTERVENTION_ZONE_DETAIL : ACTIONS_ID.MAP);
    } else if (selectedCommunity) {
      handleSelectedActionChange(widescreen ? multiselectMode.activated ? ACTIONS_ID.MULTISELECTION : ACTIONS_ID.ANNUAL_PLAN : ACTIONS_ID.MAP);
    } else {
      handleSelectedActionChange(widescreen ? ACTIONS_ID.FILTERS : ACTIONS_ID.MAP);
    }
  }, [selectedInterventionZone, selectedCommunity?.id, widescreen]);

  //ANNUAL PLANS
  const handleAnnualPlanClick = (plan) => onAnnualPlanClick(plan);
  const handleAnnualPlanComplete = (plan) => onAnnualPlanComplete(plan);
  const handleAnnualPlanCreate = (year) => onAnnualPlanCreate(year);
  const handleAnnualPlanDelete = (plan) => onAnnualPlanDelete(plan);
  const handleAnnualPlanInProcedure = (plan) => onAnnualPlanInProcedure(plan);
  const handleAnnualPlanSave = (plan) => onAnnualPlanSave(plan);

  //COMMUNITY FILES
  const handleCommunityFileDelete = (file) => onCommunityFileDelete(file);
  const handleCommunityFileDownload = (file) => onCommunityFileDownload(file);
  const handleCommunityFileUpload = (uploadFormData) => onCommunityFileUpload(selectedCommunity.id, uploadFormData);

  const [isGeolocatingUser, setIsGeolocatingUser] = useState(false);

  useEffect(() => {
    if (isGeolocatingUser) {
      setTimeout(() => document.querySelector('.mapboxgl-ctrl-geolocate').click(), 1);
    }
  }, [isGeolocatingUser]);

  //MADERA PLUS
  const [isMaderaPlusFormOpen, setMaderaPlusFormOpen] = useState(false);
  const handleMaderaPlusFormOpen = () => {
    onMaderaPlusGetDistrict();
    /*onMaderaPlusGetPropspectingPoints();*/
    setMaderaPlusFormOpen(!isMaderaPlusFormOpen);
  };

  const maderaPlusMapTitle = {
    color: 'secondary.main',
    bgcolor: 'common.white',
    borderRadius: 2,
    fontSize: 16,
    position: 'absolute',
    right: 0,
    m: 1,
    p: 1,
    fontWeight: 'bold'
  };

  const imgLegendSx = {
    position: 'absolute',
    right: 0,
    bottom: 0,
    m: 1,
    p: 1
  };

  //MULTISELECTION
  const allTypes = interventions
    .filter(intervention => multiselectMode.interventionZoneIds?.includes(intervention.intervention_zone))
    .map(intervention => (
      {
        type: intervention.type,
        id: intervention.id,
        categories: intervention.categories,
        done: intervention.done,
        never_do: intervention.never_do
      }
    ))
    .filter((obj, index, self) =>
      index === self.findIndex(el => el.id === obj.id && el.name === obj.name));

  
  const typesInCommon = allTypes.filter(typo =>
    multiselectMode.interventionZoneIds.every(interventionZone =>
      interventions.some(intervention =>
        intervention.intervention_zone === interventionZone &&
        intervention.type.id === typo.type.id &&
        intervention.type.name === typo.type.name
      )
    )
  );
  

  const commonInterventions = typesInCommon.reduce((acc, obj) => {
    const typo = obj.type;
    const category = obj.categories;
    if (!acc[typo.id]) {
      acc[typo.id] = {
        ids: [],
        type: typo,
        categories: category,
        done: [],
        never_do: []
      };
    }
    acc[typo.id].ids.push(obj.id);
    acc[typo.id].done.push(obj.done);
    acc[typo.id].never_do.push(obj.never_do);
    return acc;
  }, {});

  const formattedCommonInterventions = Object.values(commonInterventions).map(obj => {
    const simplifyDoneAtribute = obj.done.every(value => value === true);
    const simplifyNeverDoAtribute = obj.never_do.every(value => value === true);
    return {
      ...obj,
      done: simplifyDoneAtribute,
      never_do: simplifyNeverDoAtribute
    };
  });


  const handleInterventionZoneMultiselect = (id) => {
    if(!multiselectMode.interventionZoneIds.includes(id)){
      setMultiselectMode({
        activated: true,
        interventionZoneIds: [
          ...multiselectMode.interventionZoneIds,
          id
        ]
      });
    } else {
      const noDeletedIds = multiselectMode.interventionZoneIds.filter(item => item !== id);
      setMultiselectMode({activated: true, interventionZoneIds: noDeletedIds});
    }
  };
  
  const handleMultiselectAll = () => {
    setMultiselectMode({
      activated: true,
      interventionZoneIds: interventionZones.map(({id}) => id)
    });
  };

  const handleMultiselectDelete = () => {
    setMultiselectMode({
      activated: true,
      interventionZoneIds: []
    });
  };

  const handleViewMapParam = (paramId, /*specieId*/) => {
    paramId === paramIdMap ? setParamIdMap(undefined): setParamIdMap(paramId);
    //specieId && setSpecieId(specieId);
  };

  const[imgLegend, setImgLegend] = useState();

  useEffect(() => {
    if (paramIdMap) {
      fetch(`https://visor.maderaplus.es/geoserver/Galicia/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=Galicia:${paramIdMap}_26`, {
        method: 'GET',
        headers: {'Authorization': 'Basic ' + btoa(`${process.env.MADERA_PLUS_VISOR_USER}:${process.env.MADERA_PLUS_VISOR_PASSWORD}`)}})
        .then(response => response.blob().then(blob => setImgLegend(URL.createObjectURL(blob))));
    }
  }, [paramIdMap]);

  //BATCH-MODE
  const handleBatchMode = (batchId, interventionZoneIds) => {
    batchId === batchMode?.batchId ?
      setBatchMode({activated: true, batchId: undefined, interventionZoneIds: []})
      : setBatchMode({activated: true, batchId, interventionZoneIds});
  };

  const handleBatchModeDialogClose = () => {
    setBatchMode({activated: true, batchId: undefined, interventionZoneIds: []});
  };

  const handleMultipleExecutionUpdate = (mulitipleExecution) => {
    setMultiselectMode({activated: false, interventionZoneIds: []});
    onMultipleExecutionUpdate(mulitipleExecution);
    handleSelectedActionChange(ACTIONS_ID.FILTERS);
  };

  //BIOMAS CO2
  const handleBiomasCO2Open = (form) => setBiomasForm(form);
  const handleBiomasCO2Close = () => setBiomasForm(undefined);

  return <div style={{width: '100%', height: '100%'}}>
    <LazyLoading isOpen={isLoading}/>
    {apiError && <AlertDialog isOpen={true} title='ERROR' description={apiError.message} onAccept={onApiErrorClean}/>}
    <Layout
      annualPlans={annualPlans}
      annualPlanSummary={annualPlanSummary}
      batches={batches}
      biomassForm={biomassForm}
      categoryFilters={categoryFilters}
      multiSelectModeInterventionZoneIds={multiselectMode.interventionZoneIds}
      commoners={commoners}
      commonInterventions={formattedCommonInterventions}
      communityFiles={communityFiles}
      elastic_module={(selectedInterventionZone && !!selectedInterventionZone.length && selectedInterventionZone.elastic_module) ? selectedInterventionZone.elastic_module : []}
      fileDetail={fileDetail}
      filters={filters}
      interventions={interventions}
      interventionZone={selectedInterventionZone}
      isCommunitySelected={selectedCommunity !== undefined}
      isInterventionZoneSelected={!!selectedInterventionZone}
      isOffline={isOffline}
      isMaderaPlusMode={isMaderaPlusMode}
      isMaderaPlusFormOpen={isMaderaPlusFormOpen}
      maderaPlusSpecies={maderaPlusSpecies}
      maderaPlusDistrict={maderaPlusDistrict}
      multiselect={multiselectMode.activated}
      notifications={notifications}
      placeholder={selectedCommunity && selectedCommunity.owner || undefined}
      prospectingPoints={prospectingPoints}
      role={role}
      rolesList={rolesList}
      searchedText={searchedText}
      selectedActionId={selectedActionId.act}
      selectedAnnualPlan={selectedAnnualPlan}
      selectedCommunity={selectedCommunity}
      selectedFilters={activedFilters}
      title={`BIKENTA M⁺ ${selectedCommunity && '    '+ selectedCommunity.owner || ''}${selectedAnnualPlan ? '    PLAN ANUAL ' + selectedAnnualPlan.year : ''}`}
      toggleMapDashboard={toggleMapDashboard}
      onAddButtonClick={handleAddButtonClick}
      onAdvanceSearchClick={openAdvanceFilters}
      onAnnualPlanClick={handleAnnualPlanClick}
      onAnnualPlanComplete={handleAnnualPlanComplete}
      onAnnualPlanCreate={handleAnnualPlanCreate}
      onAnnualPlanDelete={handleAnnualPlanDelete}
      onAnnualPlanDownload={onAnnualPlanDownload}
      onAnnualPlanInProcedure={handleAnnualPlanInProcedure}
      onAnnualPlanSave={handleAnnualPlanSave}
      onBatchMode={handleBatchMode}
      onBatchExit={handleBatchExit}
      onBatchModeMenuClose={handleBatchModeDialogClose}
      onBiomasCO2Open={handleBiomasCO2Open}
      onCommunityFileDelete={handleCommunityFileDelete}
      onCommunityFileDownload={handleCommunityFileDownload}
      onCommunityFileUpload={handleCommunityFileUpload}
      onContactRSC={onContactRSC}
      onCreateBatch={onCreateBatch}
      onDeleteBatch={onDeleteBatch}
      onEditBatch={onEditBatch}
      onExportFilterResult={handleExportFilterResult}
      onFileDetailOpen={onFileDetailOpen}
      onGoBack={selectedInterventionZone ? handleInterventionZoneSelect : selectedCommunity ? handleSelectCommunity : undefined}
      onInterventionAdd={handleCreateIntervention}
      onInterventionSelect={handleSelectIntervention}
      onLogout={onLogout}
      onMaderaPlusFormOpen={handleMaderaPlusFormOpen}
      onMaderaPlusSidePanelOpen={onMaderaPlusSidePanelOpen}
      onMaderaPlusEnd={handleMaderaPlusEnd}
      onMultiselectAll={handleMultiselectAll}
      onMultiselectDelete={handleMultiselectDelete}
      onMultiselectExit={handleMultiselectExit}
      onMultipleExecutionUpdate={handleMultipleExecutionUpdate}
      onNavigationMenuClicked={handleNavigationMenuClose}
      onNeverDo={onNeverDo}
      onSearchClick={onSearchClick}
      onActionChange={handleSelectedActionChange}
      onSelectedFilter={activeFilters}
      onStartIconClick={handleNavigationMenuClose}
      onToggleMapDashboard={handleToggleMapDashboard}
      onToggleMaderaPlusMode={handleToggleMaderaPlusMode}
      onDeleteProspectingPoint={handleDeleteProspectingPoint}
      onViewMapParam={handleViewMapParam}
      onUpdateIntervention={onUpdateIntervention}
      readUserNotification={readUserNotification}
    />
    <NavigationMenu
      isCommunitySelected={!!selectedCommunity}
      isNavigationMenuOpen={isNavigationMenuOpen}
      pnoaYear={pnoaYear}
      profile={profile}
      selectedStyleId={basemap}
      selectedDetailMap={selectedDetailsMaps}
      onClose={handleNavigationMenuClose}
      onDetailMapChange={handleDetailMapChange}
      onStyleChange={handleStyleMapChange}
      onValueChange={setPnoaYear}
      handleGeolocateUser={() => setIsGeolocatingUser(!isGeolocatingUser)}
      isGeolocatingUser={isGeolocatingUser}
    />
    {
      toggleMapDashboard === 'map' ?
        <Box sx={mapWrapperStyle}>
          {isOffline && <Mask width={300} height={300} opacity={0.30}/>}
          {
            !biomassForm ?
              <Map
                hash={false}
                layers={layers}
                mapboxAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
                mapStyle={basemap}
                ref={mapRef}
                sources={sources}
                viewport={viewport}
                onClick={handleMapClick}
                onViewportChange={onViewportChange}
                auth={[
                  {
                    urlMatch: process.env.MADERA_PLUS_URL,
                    user: process.env.MADERA_PLUS_VISOR_USER,
                    password: process.env.MADERA_PLUS_VISOR_PASSWORD,
                  }
                ]}
              >
                {
                  isGeolocatingUser &&
              <GeolocateControl
                positionOptions={{enableHighAccuracy: true}}
                style={{visibility: 'hidden'}}
                trackUserLocation={isGeolocatingUser}
              />
                }
                {
                  !selectedCommunity && <CommunitiesPois
                    communitiesPoisData={communitiesPois}
                    selectedCommunityId={communityPreSelect}
                    onClick={handleSelectCommunity}
                  />
                }
                {
                  !!businessFeatureClicked && <Popup
                    latitude={businessFeatureClicked.geometry.coordinates[1]}
                    longitude={businessFeatureClicked.geometry.coordinates[0]}
                    maxWidth='500px'
                    closeOnMove={true}
                    closeButton={false}
                    closeOnClick={true}
                    onClose={handlePopupClose}
                  >
                    <BusinessCard feature={businessFeatureClicked.properties}/>
                  </Popup>
                }
                {
                  paramIdMap && <>
                    <Typography sx={maderaPlusMapTitle}>
                      {Object.values(ELASTIC_MODULE_PARAMS).filter(value => value.id === (paramIdMap + ''))[0].label}
                    </Typography>
                    <Box component='img' src={imgLegend} sx={imgLegendSx}/>
                  </>
                }
              </Map>
              : biomassForm === BIOMASS_CO2_FORM_PLANNING ? 
                <PlanningBiomassForm
                  selectedInterventionZone={selectedInterventionZone}
                  absorptionBaseLine={absorptionBaseLine}
                  absorptionProject={absorptionProject}
                  isOffline={isOffline}
                  onBiomasCO2Close={handleBiomasCO2Open}
                  onCalculateCO2={onCalculateCO2}
                />
                : <MonitoringBiomassForm
                  selectedInterventionZone={selectedInterventionZone}
                  onBiomasCO2Close={handleBiomasCO2Close}
                />
          }
          {
            selectedDetailsMaps.length !== 0 && <Legend
              isInterventionZoneSelected={!!selectedInterventionZone}
              legendTarget={selectedDetailsMaps}
            />
          }
          {!selectedCommunity &&
        <CommunitiesGroup
          communities={communities}
          communityId={carouselCommunityId}
          onCommunityCardHover={setCommunityPreSelect}
          onCommunityPreselect={selectCommunityOnCarousel}
          onCommunitySelect={handleSelectCommunity}
        />}
          {selectedCommunity &&
        <div style={{visibility: selectedInterventionZone ? 'hidden' : 'visible'}}>
          <InterventionZoneGroup
            annualPlansAssignedInSelectedInterventionZones={annualPlansAssignedInSelectedInterventionZones}
            interventionZoneId={carouselInterventionZoneId}
            interventionZones={interventionZones}
            onInterventionZonePreselect={selectInterventionZoneOnCarousel}
            onInterventionZoneSelect={handleInterventionZoneSelect}
          />
        </div>
          }
          {(isAddInterventionMenuOpen || isEditPlannedInterventionMenuOpen) &&
        <AddInterventionMenu
          isMultipleAddition={isMultipleAddition}
          isEditing={isEditPlannedInterventionMenuOpen}
          basemap={basemap}
          categories={categories}
          interventionTypes={interventionTypes}
          interventionZone={selectedInterventionZone}
          interventionZoneDetail={interventionZoneDetail}
          isAddInterventionMenuOpen={true}
          selectedIntervention={selectedIntervention || undefined}
          species={species}
          viewport={viewport}
          onCancel={() => {
            setIsAddInterventionMenuOpen(false);
            setIsEditPlannedInterventionMenuOpen(false);
          }}
          onInputInterventionZoneGeometryEnds={onInputInterventionZoneGeometryEnds}
          onInputInterventionZoneGeometryStarts={onInputInterventionZoneGeometryStarts}
          onSave={handleAddOrUpdateIntervention}
        />
          }
          {selectedIntervention &&
        <InterventionMenu
          annualPlansAssignedInSelectedIntervention={annualPlansAssignedInSelectedIntervention}
          basemap={basemap}
          batches={batches}
          categoryFilters={categoryFilters}
          commoners={commoners}
          fileDetail={fileDetail}
          intervention={selectedIntervention}
          interventionZone={selectedInterventionZone}
          interventionZoneDetail={interventionZoneDetail}
          isInterventionMenuOpen={isInterventionMenuOpen}
          isOffline={isOffline}
          multiselect={multiselectMode.activated}
          role={role}
          rolesList={rolesList}
          selectedAnnualPlan={selectedAnnualPlan}
          viewport={viewport}
          onAnnualPlanUpdateIntervention={onAnnualPlanUpdateIntervention}
          onCreateExecution={onCreateExecution}
          onEditEnd={handleInterventionMenuEditEnd}
          onDelete={handleDeleteIntervention}
          onDeleteFile={onDeleteFile}
          onDownloadFile={onDownloadFile}
          onCloseInterventionMenu={handleCloseInterventionMenu}
          onEditPlannedIntervention={(interventionId) => handleEditPlannedIntervention(interventionId)}
          onDeleteExecution={onDeleteExecution}
          onUpdateIntervention={onUpdateIntervention}
          onUpdateExecution={onUpdateExecution}
          onUploadFile={onUploadFile}
          onFileDetailOpen={onFileDetailOpen}
          onInputInterventionZoneGeometryEnds={onInputInterventionZoneGeometryEnds}
          onInputInterventionZoneGeometryStarts={onInputInterventionZoneGeometryStarts}
          onNeverDo={onNeverDo}
        />
          }
        </Box>
        : <Dashboard
          categories={categories}
          filteredInterventionZones={interventionZonesForDashboard}
          interventionZones={selectedinterventionZones}
          selectedCommunity={selectedCommunity}
        />
    }
    {
      selectedActionId.act === ACTIONS_ID.COMMONERS && <CommonersListView
        {...commonersListViewProps}
        onCommonersListClose={() => handleSelectedActionChange(selectedActionId.prev)}
      />
    }
    {
      selectedActionId.act === ACTIONS_ID.BUSINESS && <BusinessListView
        {...businessListViewProps}
        onBusinessListClose={() => handleSelectedActionChange(selectedActionId.prev)}
      />
    }
    {
      selectedActionId.act === ACTIONS_ID.ACCOUNT_BOOK && <AccountBookView
        {...accountBookViewProps}
        onAccountBookClose={() => handleSelectedActionChange(selectedActionId.prev)}
      />
    }
  </div>;
};

CommunityMap.propTypes = {
  absorptionBaseLine: PropTypes.object,
  absorptionProject: PropTypes.object,
  activedFilters: PropTypes.array,
  activeFilters: PropTypes.func.isRequired,
  annualPlanInterventionZoneIdsByCategoryGroup: PropTypes.shape({
    1: PropTypes.arrayOf(PropTypes.number),
    2: PropTypes.arrayOf(PropTypes.number),
    3: PropTypes.arrayOf(PropTypes.number),
    4: PropTypes.arrayOf(PropTypes.number),
    5: PropTypes.arrayOf(PropTypes.number),
    6: PropTypes.arrayOf(PropTypes.number),
  }),
  annualPlans: PropTypes.arrayOf(annualPlan),
  annualPlansAssignedInSelectedIntervention: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    year: PropTypes.number
  })),
  annualPlansAssignedInSelectedInterventionZones: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    annualPlans: PropTypes.arrayOf(PropTypes.number)
  })),
  annualPlanSummary: annualPlanSummary,
  apiError: PropTypes.object,
  basemap: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  batches: PropTypes.arrayOf(PropTypes.shape({id: PropTypes.number, name: PropTypes.string })),
  businessWithLocation: PropTypes.object,
  carouselCommunityId: PropTypes.number,
  carouselInterventionZoneId: PropTypes.number,
  categories: PropTypes.array,
  categoryFilters: categoryFilters,
  commoners: commoners,
  communities: PropTypes.arrayOf(
    PropTypes.object
  ),
  communityFiles: PropTypes.array,
  fileDetail: fileDetail,
  filters: PropTypes.array,
  maderaPlusSpecies: PropTypes.array,
  maderaPlusDistrict: PropTypes.number,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string
    })
  ),
  interventions: PropTypes.arrayOf(intervention),
  interventionTypes: PropTypes.arrayOf(intervention),
  interventionZoneDetail: PropTypes.object,
  interventionZones: PropTypes.arrayOf(
    PropTypes.object
  ),
  interventionZoneIdsFromAnnualPlan: PropTypes.arrayOf(
    PropTypes.number
  ),
  interventionZonesForDashboard: PropTypes.arrayOf(
    PropTypes.object
  ),
  isBiomasCO2FormVisible: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isOffline: PropTypes.bool.isRequired,
  openAdvanceFilters: PropTypes.func,
  profile: PropTypes.shape({
    id: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    company: PropTypes.string,
    groups: PropTypes.array,
    roles: PropTypes.array
  }),
  role: PropTypes.oneOf(Object.values(ROLES_LABEL)),
  rolesList: PropTypes.arrayOf(roles),
  searchedText: PropTypes.string,
  selectCommunityOnCarousel: PropTypes.func,
  selectedAnnualPlan: annualPlan,
  selectedCommunity: PropTypes.object,
  selectedIntervention: PropTypes.object,
  selectedInterventionZone: PropTypes.object,
  selectedinterventionZones: PropTypes.array,
  selectInterventionZoneOnCarousel: PropTypes.func,
  species: PropTypes.arrayOf(specie),
  viewport: viewport,
  onAnnualPlanDownload: PropTypes.func,
  onAnnualPlanClick: PropTypes.func,
  onAnnualPlanComplete: PropTypes.func,
  onAnnualPlanCreate: PropTypes.func,
  onAnnualPlanDelete: PropTypes.func,
  onAnnualPlanInProcedure: PropTypes.func,
  onAnnualPlanSave: PropTypes.func,
  onAnnualPlanUpdateIntervention: PropTypes.func,
  onApiErrorClean: PropTypes.func,
  onBaseMapChange: PropTypes.func,
  onCommunityFileDelete: PropTypes.func,
  onCommunityFileDownload: PropTypes.func,
  onCommunityFileUpload: PropTypes.func,
  onCreateBatch: PropTypes.func,
  onCreateExecution: PropTypes.func,
  onCreateIntervention: PropTypes.func,
  onCreatePlantation: PropTypes.func,
  onCreateMultipleInterventions: PropTypes.func,
  onCreateMultiplePlantations: PropTypes.func,
  onDeleteBatch: PropTypes.func,
  onEditBatch: PropTypes.func,
  onEditPlannedIntervention: PropTypes.func,
  onFileDetailOpen: PropTypes.func,
  onCalculateCO2: PropTypes.func,
  onCommunitySelect: PropTypes.func,
  onContactRSC: PropTypes.func,
  onDeleteFile: PropTypes.func,
  onDeleteIntervention: PropTypes.func,
  onDownloadFile: PropTypes.func,
  onUpdateIntervention: PropTypes.func,
  onUpdatePlantation: PropTypes.func,
  onExportFilterResult: PropTypes.func,
  onDeleteExecution: PropTypes.func,
  onInputInterventionZoneGeometryEnds: PropTypes.func,
  onInputInterventionZoneGeometryStarts: PropTypes.func,
  onLogout: PropTypes.func,
  onMaderaPlusGetDistrict: PropTypes.func,
  onMaderaPlusGetPropspectingPoints: PropTypes.func,
  onMaderaPlusSidePanelOpen: PropTypes.func,
  onMultipleExecutionUpdate: PropTypes.func,
  onSearchClick: PropTypes.func,
  onNeverDo: PropTypes.func.isRequired,
  onUpdateExecution: PropTypes.func,
  onUploadFile: PropTypes.func,
  onViewportChange: PropTypes.func,
  readUserNotification: PropTypes.func,
  accountBookViewProps: PropTypes.object,
  businessListViewProps: PropTypes.object,
  commonersListViewProps: PropTypes.object,
};

export default CommunityMap;
