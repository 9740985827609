import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import AlertDeleteDialog from '../../../AlertDeleteDialog';
import Co2_InterventionTable from './Co2_InterventionTable';
import Co2_InterventionDialog from './Co2_InterventionDialog';

import {co2_intervention} from '../../../proptypes/co2_intervention';

const Co2_InterventionMenu = ({co2Interventions, newCo2Interventions, plantingYear, onCo2InterventionAdd, onCo2NewInterventionsChange, onCo2InterventionUpdate, onCalculateCO2}) => {

  const [openCo2InterventionDialog, setOpenCo2InterventionDialog] = useState(false);
  const [openCalculationAlert, setOpenCalculationAlert] = useState(false);

  const sx = {
    fab: {
      '&.MuiButtonBase-root': {
        borderRadius: '100%',
        backgroundColor: 'secondary.main',
        mr: 1,
        '&:hover': {
          backgroundColor: 'primary.main',
        }
      },
      '&.Mui-disabled': {
        backgroundColor: '#d7d7d7'
      }
    },
    addIcon: {
      color: 'white'
    },
    button: {
      display: 'flex',
      height: '40px',
      flexDirection: 'row',
      justifyContent: co2Interventions.length ? 'space-between' : 'flex-start'
    }
  };

  const hasCuttingWithoutWeTroncos = useMemo(() => [...co2Interventions, ...newCo2Interventions]
    .some(co2Intervention => co2Intervention.we_troncos === 0),
  [co2Interventions, newCo2Interventions]);

  return <Box display='flex' flexDirection='column'>
    <Co2_InterventionTable
      co2Interventions={co2Interventions}
      newCo2Interventions={newCo2Interventions}
      onCo2NewInterventionsChange={onCo2NewInterventionsChange}
      onCo2InterventionUpdate={onCo2InterventionUpdate}
      plantingYear={plantingYear}
    />
    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' mt={hasCuttingWithoutWeTroncos ? 1 : 2}>
      <Box display='flex' flexDirection='row' sx={{gap: 2, alignItems: 'center'}}>
        <Box sx={sx.button}>
          <Button variant={hasCuttingWithoutWeTroncos ? 'outlined' : 'contained'}
            color='secondary'
            disabled={hasCuttingWithoutWeTroncos}
            onClick={() => setOpenCalculationAlert(!openCalculationAlert)}>CALCULAR CO2</Button>
        </Box>
        {
          hasCuttingWithoutWeTroncos && <Alert severity="error" sx={{my: 2}}>
            <Typography sx={{fontStyle: 'italic'}}>
        Será necesario incluir las toneladas marcadas en rojo antes de realizar el cálculo de CO<sub>2</sub>
            </Typography>
          </Alert>
        }
      </Box>
      <Fab sx={sx.fab} size='small' onClick={() => setOpenCo2InterventionDialog(!openCo2InterventionDialog)}>
        <AddIcon sx={sx.addIcon}/>
      </Fab>
    </Box>
    <Co2_InterventionDialog
      isCo2InterventionDialogOpen={openCo2InterventionDialog}
      allCo2Interventions={[...co2Interventions, ...newCo2Interventions]}
      onDialogClose={() => setOpenCo2InterventionDialog(!openCo2InterventionDialog)}
      onDialogOpen={() => setOpenCo2InterventionDialog(!openCo2InterventionDialog)}
      onCo2InterventionAdd={onCo2InterventionAdd}
    />
    {
      openCalculationAlert && <AlertDeleteDialog
        title={'Calcular CO2'}
        description={'Una vez efectuado el cálculo, no se podrán modificar las actuaciones. ¿Estás seguro?'}
        onCancel={() => setOpenCalculationAlert(!openCalculationAlert)}
        onAccept={(co2Interventions) => onCalculateCO2(co2Interventions)}/>
    }
  </Box>;
};

Co2_InterventionMenu.propTypes = {
  co2Interventions: PropTypes.arrayOf(co2_intervention),
  newCo2Interventions: PropTypes.arrayOf(co2_intervention),
  plantingYear: PropTypes.number.isRequired,
  onCo2InterventionAdd: PropTypes.func.isRequired,
  onCo2InterventionUpdate: PropTypes.func.isRequired,
  onCo2NewInterventionsChange: PropTypes.func.isRequired,
  onCalculateCO2: PropTypes.func.isRequired
};

export default Co2_InterventionMenu;