import React, {useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

//BIKENTA
import BiomasSection from './BiomasSection';

//UTILS
import {MACHINING_DIFFICULTY} from '../../../../config';

import MonitoringMassDescription from './MonitoringMassDescription';
import InventoriesMenu from './InventoriesMenu';
import Co2_InterventionMenu from './Co2_InterventionMenu';

import {inventories} from '../../../../../fixture/inventories';
import {co2Interventions} from '../../../../../fixture/co2Interventions';

//STYLES
const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  margin: '10px',
  width: '98%',
  pt: 1.25
};

const MonitoringBiomassForm = ({
  selectedInterventionZone,
  onBiomasCO2Close
}) => {

  const {id, area, centroid, species} = selectedInterventionZone;

  const WILDFIRE_RISK = 'Bajo'; //TODO ABM-756 Guardar datos de dificultad de mecanizado y riesgo de incendio
  const MACHINING_DIFFICULTY_ID = MACHINING_DIFFICULTY[0].id; //TODO ABM-756 Guardar datos de dificultad de mecanizado y riesgo de incendio
  const PLANTING_YEAR = 1990; //TODO
  const CO2_INTERVENTIONS = co2Interventions; //TODO

  const [newCo2Interventions, setNewCo2Interventions] = useState([]);
  const [newInventories, setNewInventories] = useState([]);

  const handleBiomasCo2Close = () => onBiomasCO2Close();

  const handleInterventionUpdate = (interventions) => {
    setNewCo2Interventions(interventions);
  };

  return <Box sx={containerSx}>
    {/*DESCRIPCIÓN DE LA MASA----------------------------------------------------------------------------------------*/}
    <BiomasSection number={1} label='Descripción de la masa'>
      <>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 4}}>
          <MonitoringMassDescription
            id_rodal={id}
            area={area}
            centroid={[centroid.lat, centroid.lon]}//TODO ABM-731 Incorporar las coordenadas del centroide del rodal
            species_label={species[0].label}
            wildfire_risk={WILDFIRE_RISK}
            machining_difficulty_id={MACHINING_DIFFICULTY_ID}/>
        </Box>
      </>
    </BiomasSection>

    {/*INVENTARIOS---------------------------------------------------------------------------------------------------*/}
    <BiomasSection number={2} label='Inventarios' sx={{display: 'flex', flexDirection: 'column', mb: 0}}>
      <InventoriesMenu
        inventories={inventories}
        newInventories={newInventories}
        plantingYear={PLANTING_YEAR}
        onInventoryAdd={(inventory) => setNewInventories([inventory])}
        onInventoryUpdate={() => {}}
        onInventoriesChange={(inventories) => setNewInventories(inventories)}
        onPlantingYearAdd={() => {}}//TODO
      />
    </BiomasSection>

    {/*ACTUACIONES---------------------------------------------------------------------------------------------------*/}
    <BiomasSection number={3} label='Actuaciones' sx={{display: 'flex', flexDirection: 'column'}}>
      <Co2_InterventionMenu
        co2Interventions={CO2_INTERVENTIONS}
        newCo2Interventions={newCo2Interventions}
        inventories={inventories}
        plantingYear={PLANTING_YEAR}
        onCo2InterventionAdd={(value) => setNewCo2Interventions([...newCo2Interventions, value])}
        onCo2InterventionUpdate={handleInterventionUpdate}
        onCo2NewInterventionsChange={(interventions) => setNewCo2Interventions(interventions)}
        onCalculateCO2={() => {}}//TODO
      />
    </BiomasSection>

    {/*BALANCE DE CO2------------------------------------------------------------------------------------------------*/}
    <BiomasSection number={4} label='Balance de CO2' sx={{display: 'flex', flexDirection: 'column', mt: 2}}>
      <></>
    </BiomasSection>
    <Button variant='contained' color='secondary' sx={{ml: 4, my: 2, width: '250px'}} onClick={handleBiomasCo2Close}>SALIR</Button>
  </Box>;
};

export default MonitoringBiomassForm;

MonitoringBiomassForm.propTypes = {
  isOffline: PropTypes.bool,
  selectedInterventionZone: PropTypes.object.isRequired,
  absorptionBaseLine: PropTypes.object,
  absorptionProject: PropTypes.object,
  onBiomasCO2Close: PropTypes.func.isRequired
};