import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

//MUI
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

//BIKENTA
import DialogPanel from '../DialogPanel';
import InputNumber from '../InterventionZones/AddInterventionMenu/InputNumber';

//UTILS
import {annualPlan} from '../proptypes/annualPlan';
import {getCurrentYear} from '../../utils/getCurrentYear';

const min = getCurrentYear() - 10;
const max = getCurrentYear() + 10;

const SelectAnnualPlanYearMenu = ({annualPlans, isAnnualPlanYearMenuOpen, onClose, onAnnualPlanCreate}) => {
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const handleYearChange = (value) => setSelectedYear(value);
  const handleCreateAnnualPlan = () => onClose && onAnnualPlanCreate(selectedYear);
  const handleClose = () => onClose && onClose();

  const isValidYear = useMemo(() => {
    const invalidYears = Array.from(annualPlans, plan => plan.year);
    return !invalidYears.includes(selectedYear);
  }, [annualPlans, selectedYear]);

  return <DialogPanel
    isOpen={isAnnualPlanYearMenuOpen}
    label="NUEVO PLAN ANUAL"
    onClose={onClose}
    id='modal-new-annual-plan-title'
  >
    <Typography gutterBottom>Selecciona un año que no tenga todavía un Plan Anual creado.</Typography>
    <InputNumber
      label="Año del Plan Anual"
      max={max}
      min={min}
      value={selectedYear}
      onChange={handleYearChange}
      sx={{alignItems: 'center', my: 1}}
    />
    <Typography variant="caption" color="error.main" m={2}>{isValidYear ? undefined : 'Ya existe un plan anual de ese año.'}</Typography>
    <Stack direction='row' justifyContent='flex-end' alignItems='center'>
      <Button color='secondary' onClick={handleClose}>CANCELAR</Button>
      <Button
        variant='contained'
        color='secondary'
        onClick={handleCreateAnnualPlan}
        disabled={!isValidYear}
      >CREAR
      </Button>
    </Stack>
  </DialogPanel>;
};

SelectAnnualPlanYearMenu.propTypes = {
  annualPlans: PropTypes.arrayOf(annualPlan),
  isAnnualPlanYearMenuOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onAnnualPlanCreate: PropTypes.func
};

SelectAnnualPlanYearMenu.defaultProps = {
  isAnnualPlanYearMenuOpen: false
};

export default SelectAnnualPlanYearMenu;