import PropTypes from 'prop-types';
import {
  COMMERCIAL_PURPOSE_OPTIONS,
  CO2_INTERVENTION_TYPE,
  EXTRACTION_RATIO_OPTIONS, FELLING_PROCESSING_TYPE
} from '../../config';

export const co2_intervention = PropTypes.shape({
  id: PropTypes.number,
  year: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(CO2_INTERVENTION_TYPE)),
  co2InterventionType: PropTypes.oneOf(Object.values(CO2_INTERVENTION_TYPE)),
  treeFeet: PropTypes.number,
  residue: PropTypes.number,
  extraction_tree_proportion: PropTypes.oneOf(EXTRACTION_RATIO_OPTIONS.map(e => e.id)),
  we_troncos: PropTypes.number,
  commercialPurpose: PropTypes.oneOf(COMMERCIAL_PURPOSE_OPTIONS.map(o => o.id)),
  felling: PropTypes.oneOf(Object.values(FELLING_PROCESSING_TYPE)),
  processing: PropTypes.oneOf(Object.values(FELLING_PROCESSING_TYPE))
});
