export const inventories = [
  {
    id: 1,
    year: 2000,
    treeFeet: 1100,
    height: 24,
    basometricArea: 40,
    site_index: 0.8
  },
  {
    id: 2,
    year: 2011,
    treeFeet: 1010,
    height: 28,
    basometricArea: 42,
    site_index: 0.8
  },
  {
    id: 3,
    year: 2012,
    treeFeet: 1010,
    height: 28,
    basometricArea: 42,
    site_index: 0.8
  },
  {
    id: 4,
    year: 2015,
    treeFeet: 1010,
    height: 28,
    basometricArea: 40,
    site_index: 0.8
  },
  {
    id: 5,
    year: 2016,
    treeFeet: 1010,
    height: 28,
    basometricArea: 42,
    site_index: 0.8
  },
  {
    id: 6,
    year: 2017,
    treeFeet: 1050,
    height: 29,
    basometricArea: 52,
    site_index: 0.8
  },
  {
    id: 7,
    year: 2018,
    treeFeet: 1010,
    height: 32,
    basometricArea: 40,
    site_index: 0.65
  }
];