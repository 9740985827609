import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import SelectInput from '@geomatico/geocomponents/SelectInput';
import Typography from '@mui/material/Typography';
import {COMMERCIAL_PURPOSE_OPTIONS} from '../../../../config';

const TargetProduct = ({selectedProductId, onProductChange}) => {
  return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1}}>
    <Typography variant='body2'>PRODUCTO OBJETIVO</Typography>
    <SelectInput options={COMMERCIAL_PURPOSE_OPTIONS} selectedOptionId={selectedProductId || 'PAPER'} onOptionChange={onProductChange}/>
  </Box>;
};

TargetProduct.propTypes = {
  selectedProductId: PropTypes.string,
  onProductChange: PropTypes.func.isRequired,
};

export default TargetProduct;