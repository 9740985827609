import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {inventory} from '../../../proptypes/inventory';
import InventoriesTable from './InventoriesTable';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import InventoriesDialog from './InventoriesDialog';

const InventoriesMenu = ({inventories, newInventories, plantingYear, onInventoryAdd, onInventoriesChange, onInventoryUpdate, onPlantingYearAdd}) => {

  const [openInventoryDialog, setOpenInventoryDialog] = useState(false);

  const styles = {
    fab: {
      float: 'right',
      mt: 2,
      '&.MuiButtonBase-root': {
        borderRadius: '100%',
        backgroundColor: 'secondary.main',
        mr: 1,
        '&:hover': {
          backgroundColor: 'primary.main',
        }
      },
      '&.Mui-disabled': {
        backgroundColor: '#d7d7d7'
      }
    }
  };

  return <Box>
    <InventoriesTable
      inventories={inventories}
      newInventories={newInventories}
      onInventoriesChange={onInventoriesChange}
      onInventoryUpdate={onInventoryUpdate}
      plantingYear={plantingYear}
    />
    <Fab sx={styles.fab} size='small' onClick={() => setOpenInventoryDialog(!openInventoryDialog)}>
      <AddIcon sx={{color: 'white'}}/>
    </Fab>
    <InventoriesDialog
      inventories={inventories}
      plantingYear={plantingYear}
      isInventoryDialogOpen={openInventoryDialog}
      onDialogClose={() => setOpenInventoryDialog(!openInventoryDialog)}
      onInventoryAdd={onInventoryAdd}
      onPlantingYearAdd={onPlantingYearAdd}
    />
  </Box>;
};

InventoriesMenu.propTypes = {
  inventories: PropTypes.arrayOf(inventory),
  newInventories: PropTypes.arrayOf(inventory),
  plantingYear: PropTypes.number,
  onInventoryAdd: PropTypes.func.isRequired,
  onInventoryUpdate: PropTypes.func.isRequired,
  onInventoriesChange: PropTypes.func.isRequired,
  onPlantingYearAdd: PropTypes.func.isRequired
};

export default InventoriesMenu;