import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/styles';

import {DataGrid, esES, gridClasses} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/Delete';

import {co2_intervention} from '../../../proptypes/co2_intervention';
import {CO2_INTERVENTION_TYPE, CO2_INTERVENTION_TYPE_OPTIONS, EXTRACTION_RATIO_OPTIONS} from '../../../../config';
import {getLabelById} from '../../../../utils/getLabelById';
import Tooltip from '@mui/material/Tooltip';

const styles = (widescreen) => ({
  table: {
    [`.${gridClasses.cell}.required`]: {
      backgroundColor: '#fdeded',
      color: 'red'
    },
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
      outline: 'none'
    },
    fontSize: '14px',
    boxShadow: 'none',
    border: 'none',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 800,
      color: 'primary.main',
      fontSize: '14px'
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '&.MuiPaper-root': {
      border: 2,
      zIndex: 10000
    },
    '&.MuiDataGridPanel-root': {
      border: 2,
      zIndex: 10000
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none', // Eliminar el borde de foco
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none', // Eliminar el borde de foco
    }
  },
  button: {
    backgroundColor: 'secondary.main',
    color: 'primary.contrastText',
    mb: widescreen ? undefined : 1,
    ml: widescreen ? undefined : 1,
    mr: widescreen ? 1 : 1
  },
  expandIcon: {
    color: 'white',
    bgcolor: 'primary.main',
    borderRadius: '100%'
  }
});

const Co2_InterventionTable = ({co2Interventions, newCo2Interventions, plantingYear, onCo2NewInterventionsChange, onCo2InterventionUpdate}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});

  const isPlantingOrNaturalRegeneration = (field) => (field === CO2_INTERVENTION_TYPE.PLANTING || field === CO2_INTERVENTION_TYPE.NATURAL_REGENERATION);
  const isCutting = (field) => (field === CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS || field === CO2_INTERVENTION_TYPE.FINAL_CUTTING);

  const allCo2Interventions = useMemo(() => {
    return [...co2Interventions, ...newCo2Interventions]
      .sort((a, b) => a.year - b.year);
  }, [co2Interventions, newCo2Interventions]);

  const formattedCo2Interventions = useMemo(() => allCo2Interventions
    .map((i, index) => ({...i, index})), [co2Interventions, newCo2Interventions]);

  const temporaryStyle = (id) => ({
    fontStyle: id? 'normal': 'italic',
    color: id? 'black': 'grey'
  });

  //RENDER CELLS
  const renderInterventionCell = params => <Typography sx={temporaryStyle(params.row.id)}>{getLabelById(params.row.co2InterventionType, CO2_INTERVENTION_TYPE_OPTIONS)}</Typography>;
  const renderDeleteCell = params => parseFloat(params.row.id)
    ? <></>
    : <IconButton onClick={() => handleCo2InterventionDelete(params.id)}>
      <DeleteIcon/>
    </IconButton>;
  const renderAgeCell = params => <Typography sx={temporaryStyle(params.row.id)}>{parseFloat(params.row.year) - plantingYear}</Typography>;
  const renderExtractionTreeProportionCell =  params => <Typography sx={temporaryStyle(params.row.id)}>
    {isPlantingOrNaturalRegeneration(params.row.co2InterventionType) ? '-' : getLabelById(params.row.extraction_tree_proportion, EXTRACTION_RATIO_OPTIONS)}</Typography>;
  const renderWeTroncosCell = params => isPlantingOrNaturalRegeneration(params.row.co2InterventionType)
    ? '-'
    : isCutting && params.value === 0 ?
      <Tooltip title='Campo obligatorio'><span>{params.value}</span></Tooltip>
      : <Typography sx={temporaryStyle(params.row.id)}>{params.value}</Typography>;
  const renderResidueCell = params => <Typography sx={temporaryStyle(params.row.id)}>{isPlantingOrNaturalRegeneration(params.row.co2InterventionType) ?  '-' : params.value}</Typography>;
  const renderTreeFeetCell = params => <Typography sx={temporaryStyle(params.row.id)}>{isPlantingOrNaturalRegeneration(params.row.co2InterventionType) ? params.value : '-'}</Typography>;
  const temporalRowStyle = params => <Typography sx={temporaryStyle(params.row.id)}>{params.value}</Typography>;

  const columns = [
    {
      field: 'year',
      headerName: 'Año',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: temporalRowStyle
    },
    {
      field: 'co2InterventionType',
      headerName: 'ACTUACIÓN',
      editable: false,
      headerAlign: 'center',
      width: 160,
      align: 'center',
      sortable: false,
      renderCell: renderInterventionCell
    },
    {
      field: 'age',
      headerName: 'Edad',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: renderAgeCell
    },
    {
      field: 'treeFeet',
      headerName: 'N',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: renderTreeFeetCell
    },
    {
      field: 'extraction_tree_proportion',
      headerName: 'Tipo',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 120,
      flex: 1,
      renderCell: renderExtractionTreeProportionCell
    },
    {
      field: 'residue',
      headerName: '%',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      renderCell: renderResidueCell
    },
    {
      field: 'we_troncos',
      headerName: 'Tn extraídas',
      editable: true,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      width: 120,
      type: 'number',
      renderCell: renderWeTroncosCell
    },
    {
      field: 'actions',
      headerName: '',
      editable: false,
      cellClassName: 'actions',
      headerAlign: 'right',
      align: 'left',
      sortable: false,
      flex: 1,
      renderCell: renderDeleteCell
    }
  ];

  const handleCo2InterventionDelete = (indexToDelete) => {
    const interventionsToRemain = allCo2Interventions
      .filter((_, index) => index !== indexToDelete)
      .filter(i => !i.id);
    onCo2NewInterventionsChange(interventionsToRemain);
  };

  const handleCo2InterventionUpdate = (indexToUpdate, param, value) => {

    const co2InterventionToUpdate = allCo2Interventions[indexToUpdate];

    if (value && co2InterventionToUpdate[param] !== value) {
      allCo2Interventions[indexToUpdate] = {
        ...co2InterventionToUpdate,
        [param]: parseFloat(value),
      };
      onCo2InterventionUpdate(allCo2Interventions.filter(i => !i.id));
    }

  };

  const handleEditStop = (params, e) => {
    const co2InterventionIndex = params.row.index;
    const paramToUpdate = params.field;
    const valueToUpdate = parseFloat(e.target.value);

    handleCo2InterventionUpdate(co2InterventionIndex, paramToUpdate, valueToUpdate);
  };

  return <div style={{width: '100%'}}>
    {formattedCo2Interventions && <DataGrid
      density='compact'
      sx={styles(widescreen).table}
      rows={formattedCo2Interventions}
      columns={columns}
      autoHeight={true}
      disableColumnMenu={true}
      disableColumnFilter={true}
      disableColumnResize={true}
      disableColumnSorting={true}
      onCellEditStop={handleEditStop}
      experimentalFeatures={{newEditingApi: true}}
      localeText={{
        ...esES.components.MuiDataGrid.defaultProps.localeText, // Extiende las traducciones en español
        noRowsLabel: 'Sin Actuaciones', // Sobrescribe el texto para filas vacías
      }}
      hideFooter
      getCellClassName={params => params.field === 'we_troncos'  && params.value === 0 ? 'required' : ''}
      getRowId={params => params.index}
    />}
  </div>;
};

Co2_InterventionTable.propTypes = {
  co2Interventions: PropTypes.arrayOf(co2_intervention),
  newCo2Interventions: PropTypes.arrayOf(co2_intervention),
  plantingYear: PropTypes.number.isRequired,
  onCo2NewInterventionsChange: PropTypes.func.isRequired,
  onCo2InterventionUpdate: PropTypes.func.isRequired,
};

export default Co2_InterventionTable;