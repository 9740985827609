import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import {inventory} from '../../../proptypes/inventory';
import {DataGrid, esES} from '@mui/x-data-grid';
import {useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Typography from '@mui/material/Typography';

const styles = (widescreen) => ({
  table: {
    fontSize: '14px',
    boxShadow: 'none',
    border: 'none',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 800,
      color: 'primary.main',
      fontSize: '14px'
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '&.MuiPaper-root': {
      border: 2,
      zIndex: 10000
    },
    '&.MuiDataGridPanel-root': {
      border: 2,
      zIndex: 10000
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none', // Eliminar el borde de foco
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none', // Eliminar el borde de foco
    }
  },
  button: {
    backgroundColor: 'secondary.main',
    color: 'primary.contrastText',
    mb: widescreen ? undefined : 1,
    ml: widescreen ? undefined : 1,
    mr: widescreen ? 1 : 1
  },
  expandIcon: {
    color: 'white',
    bgcolor: 'primary.main',
    borderRadius: '100%'
  }
});

const InventoriesTable = ({inventories, newInventories, plantingYear, onInventoriesChange, onInventoryUpdate}) => {
  const theme = useTheme();
  const widescreen = useMediaQuery(theme.breakpoints.up('md'), {noSsr: true});

  const [showAll, setShowAll] = useState(false);

  const allInventories = useMemo(() => {
    return [...inventories, ...newInventories]
      .sort((a, b) => a.year - b.year);
  }, [inventories, newInventories]);
  
  
  const formattedInventories = useMemo(() => allInventories
    .map((i, index) => ({...i, inventoryNumber: index})), [inventories, newInventories]);

  const visibleRows = showAll ? formattedInventories : formattedInventories.slice(-3);

  const handleInventoryDelete = (indexToDelete) => {
    const inventoriesToRemain = allInventories
      .filter((_, index) => index !== indexToDelete)
      .filter(i => !i.id);
    onInventoriesChange(inventoriesToRemain);
  };

  const temporaryStyle = (id) => ({
    fontStyle: id? 'normal': 'italic',
    color: id? 'black': 'grey'
  });

  const renderDeleteCell = params => parseFloat(params.row.id)
    ? <></>
    : <IconButton onClick={() => handleInventoryDelete(params.id)}>
      <DeleteIcon/>
    </IconButton>;
  
  const renderAgeCell = params => <Typography sx={temporaryStyle(params.row.id)}>{parseFloat(params.row.year) - plantingYear}</Typography>;

  const temporalRowStyle = (params) => <Typography sx={temporaryStyle(params.row.id)}>{params.value}</Typography>;

  const columns = [
    {
      field: 'inventoryNumber',
      headerName: 'Nº de Inventario',
      editable: false,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      type: 'number',
      renderCell: temporalRowStyle
    },
    {
      field: 'year',
      headerName: 'Año',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      type: 'number',
      renderCell: temporalRowStyle
    },
    {
      field: 'age',
      headerName: 'Edad',
      editable: false,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      type: 'number',
      renderCell: renderAgeCell
    },
    {
      field: 'treeFeet',
      headerName: 'N',
      editable: params => !params.row.id, //Es editable si no viene de la API
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      type: 'number',
      renderCell: temporalRowStyle
    },
    {
      field: 'height',
      headerName: 'Ho',
      editable: params => !params.row.id, //Es editable si no viene de la API
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      type: 'number',
      renderCell: temporalRowStyle
    },
    {
      field: 'basometricArea',
      headerName: 'G',
      editable: params => !params.row.id, //Es editable si no viene de la API
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      flex: 1,
      type: 'number',
      renderCell: temporalRowStyle
    },
    { 
      field: 'actions', 
      headerName: 'Expand',
      // eslint-disable-next-line react/display-name
      renderHeader: () => <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <IconButton onClick={() => setShowAll(!showAll)} size="small">
          {
            inventories.length > 3 ?
              showAll ? <ExpandLessIcon sx={styles(widescreen).expandIcon}/> : <ExpandMoreIcon sx={styles(widescreen).expandIcon}/>
              : undefined
          }
        </IconButton>
      </div>,
      editable: false, 
      cellClassName: 'actions', 
      headerAlign: 'right',
      align: 'left',
      sortable: false,
      flex: 1,
      renderCell: renderDeleteCell
    }
  ];

  const handleInventoryUpdate = (inventoryId, param, value) => {
    const inventoryToUpdate = inventories.find(inventory => inventory.id === inventoryId);

    if (value && inventoryToUpdate[param] !== value) {
      onInventoryUpdate(
        {
          ...inventoryToUpdate,
          [param]: value,
        }
      );
    }
  };
  
  const handleEditStop = (params, e) => {
    const inventoryId = params.row.inventoryNumber;
    const paramToUpdate = params.field;
    const valueToUpdate = parseFloat(e.target.value);

    handleInventoryUpdate(inventoryId, paramToUpdate, valueToUpdate);
  };

  return <div style={{width: '100%'}}>
    {inventories && <DataGrid
      density='compact'
      sx={styles(widescreen).table}
      rows={visibleRows}
      columns={columns}
      autoHeight={true}
      disableColumnMenu={true}
      disableColumnFilter={true}
      disableColumnResize={true}
      disableColumnSorting={true}
      onCellEditStop={handleEditStop}
      experimentalFeatures={{newEditingApi: true}}
      localeText={{
        ...esES.components.MuiDataGrid.defaultProps.localeText, // Extiende las traducciones en español
        noRowsLabel: 'Sin Inventarios', // Sobrescribe el texto para filas vacías
      }}
      hideFooter
      getRowId={params => params.inventoryNumber}
    />}
  </div>;
};

InventoriesTable.propTypes = {
  inventories: PropTypes.arrayOf(inventory),
  newInventories: PropTypes.arrayOf(inventory),
  plantingYear: PropTypes.number,
  onInventoryUpdate: PropTypes.func.isRequired,
  onInventoriesChange: PropTypes.func.isRequired
};

export default InventoriesTable;