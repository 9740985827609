import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {EXTRACTION_RATIO_OPTIONS, CO2_INTERVENTION_TYPE, CO2_INTERVENTION_TYPE_OPTIONS} from '../../../../config';
import PerformancesDialog from './PerformancesDialog';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TargetProduct from './TargetProduct';
import DeleteIcon from '@mui/icons-material/Delete';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import {getLabelById} from '../../../../utils/getLabelById';

//STYLES
const titleSx = {
  fontWeight: 900,
  color: 'secondary.main',
  fontSize: '14px',
  width: '20px',
  px: 1
};

const valueSx = {
  fontSize: '14px',
  width: '20px',
  px: 1
};

const fab = {
  '&.MuiButtonBase-root': {
    borderRadius: '100%',
    backgroundColor: 'secondary.main',
    mr: 1,
    //ml: 'auto',
    '&:hover': {
      backgroundColor: 'primary.main',
    }
  },
  '&.Mui-disabled': {
    backgroundColor: '#d7d7d7'
  }
};
const buttonsSx = (performances) => ({
  display: 'flex',
  height: '40px',
  flexDirection: 'row',
  justifyContent: performances.length ? 'space-between' : 'flex-start',
  gap: 4,
  pl: performances.length ? 3 : 0,
  mt: performances.length ? 2 : 0
});

const PerformanceTable = ({performances, disabledProjectButtons=false, mode, onPerformancesChange, onCalculateCO2}) => {

  const [isPerformancesMenuOpen, setPerformancesMenuOpen] = useState(false);
  const [baselineManagement, setBaselineManagement] = useState('withoutManagement');
  const intermediateCuttingsSortedByAge = useMemo(() => performances && performances.intermediate_cuttings && performances.intermediate_cuttings
    .sort((a, b) => a.age - b.age), [performances]);
  
  const handleAddPerformance = () => setPerformancesMenuOpen(!isPerformancesMenuOpen);

  const handlePruningChange = (e) => onPerformancesChange({...performances, has_pruning: e.target.checked});
  const handleWeedingChange = (e) => onPerformancesChange({...performances, has_weeding: e.target.checked});

  const disableCalculationButton = disabledProjectButtons ||
    (mode === 'PROJECT' && (!performances.planting && !performances.final_cutting && !performances.intermediate_cuttings)) ||
    (mode === 'BASELINE' && baselineManagement === 'addPerformances' && (!performances.planting && !performances.final_cutting && !performances.intermediate_cuttings));

  const calculationButton = <Box sx={buttonsSx(performances)}>
    <Button variant={disableCalculationButton ? 'outlined' : 'contained'}
      color='secondary' sx={{minWidth: '150px'}}
      disabled={disableCalculationButton}
      onClick={() => onCalculateCO2(performances)}>CALCULAR CO2</Button>
  </Box>;
  
  const handleDeletePlanting = () => {
    // eslint-disable-next-line no-unused-vars
    delete performances.planting;
    onPerformancesChange(performances);
  };
  const handleDeleteFinalCutting = () => {
    // eslint-disable-next-line no-unused-vars
    const { final_cutting, ...newPerformance } = performances;
    onPerformancesChange(newPerformance);
  };

  const handleDeleteIntermediateCuttings = (index) => {
    const intermediate_cuttings = performances.intermediate_cuttings.filter((item, i) => i !== index);
    onPerformancesChange( { ...performances, intermediate_cuttings } );
  };

  const table = <>
    <TableContainer sx={{maxWidth: '30vw', pb: 2, display: 'flex', flexDirection: 'column', alignItems: performances.length ? 'flex-end' : 'flex-start', gap: 1}}>
      {performances.planting || performances.intermediate_cuttings || performances.final_cutting
        ? <Table size='small' sx={{mb: 2}}>
          <TableHead>
            <TableRow>
              <Tooltip title='Tipo de actuación'>
                <TableCell align='left' sx={titleSx}>ACTUACIÓN</TableCell>
              </Tooltip>
              <Tooltip title='Edad'>
                <TableCell align='center' sx={titleSx}>Edad</TableCell>
              </Tooltip>
              <Tooltip title='Número de pies por Ha'>
                <TableCell align='center' sx={titleSx}>N</TableCell>
              </Tooltip>
              <Tooltip title='Relación de extracción de la clara'>
                <TableCell align='center' sx={titleSx}>Tipo</TableCell>
              </Tooltip>
              <Tooltip title='Proporción de árboles a extraer'>
                <TableCell align='center' sx={titleSx}>%</TableCell>
              </Tooltip>
              <TableCell align='center' sx={titleSx}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              CO2_INTERVENTION_TYPE.PLANTING in performances && <TableRow>
                <TableCell sx={valueSx}>{getLabelById(CO2_INTERVENTION_TYPE.PLANTING, CO2_INTERVENTION_TYPE_OPTIONS)}</TableCell>
                <TableCell align='center' sx={valueSx}>0</TableCell>
                <TableCell align='center' sx={valueSx}>{performances.planting.number_per_ha || '-'}</TableCell>
                <TableCell align='center' sx={valueSx}>-</TableCell>
                <TableCell align='center' sx={valueSx}>-</TableCell>
                <TableCell align='center' sx={valueSx}><DeleteIcon sx={{color: 'grey'}} onClick={handleDeletePlanting}/></TableCell>
              </TableRow>
            }
            {
              performances && performances.intermediate_cuttings && intermediateCuttingsSortedByAge.map((intermediateCutting, index) =>
                <TableRow key={index}>
                  <TableCell sx={valueSx}>{getLabelById(CO2_INTERVENTION_TYPE.INTERMEDIATE_CUTTINGS, CO2_INTERVENTION_TYPE_OPTIONS)}</TableCell>
                  <TableCell align='center' sx={valueSx}>{intermediateCutting.age}</TableCell>
                  <TableCell align='center' sx={valueSx}>-</TableCell>
                  <TableCell align='center' sx={valueSx}>{getLabelById(intermediateCutting.extraction_ratio, EXTRACTION_RATIO_OPTIONS)}</TableCell>
                  <TableCell align='center' sx={valueSx}>{intermediateCutting.extraction_tree_proportion}</TableCell>
                  <TableCell align='center' sx={valueSx}><DeleteIcon sx={{color: 'grey'}} onClick={() => handleDeleteIntermediateCuttings(index)}/></TableCell>
                </TableRow>
              )
            }
            {
              CO2_INTERVENTION_TYPE.FINAL_CUTTING in performances && <TableRow>
                <TableCell sx={valueSx}>{getLabelById(CO2_INTERVENTION_TYPE.FINAL_CUTTING, CO2_INTERVENTION_TYPE_OPTIONS)}</TableCell>
                <TableCell align='center' sx={valueSx}>{performances.final_cutting.age}</TableCell>
                <TableCell align='center' sx={valueSx}>-</TableCell>
                <TableCell align='center' sx={valueSx}>-</TableCell>
                <TableCell align='center' sx={valueSx}>{performances.final_cutting.extraction_tree_proportion}</TableCell>
                <TableCell align='center' sx={valueSx}><DeleteIcon sx={{color: 'grey'}} onClick={handleDeleteFinalCutting}/></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        : <Typography color='error' sx={{mb: 1, pl: 0.5}}>{mode !== 'BASELINE' && 'No hay actuaciones.'}</Typography>
      }
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: performances.length ? 'space-between' : 'flex-start', width: '100%'}}>
        <Box ml={0.5}>
          <FormControlLabel label='Podas' control={<Checkbox size='small' checked={performances.has_pruning} disabled={disabledProjectButtons} onChange={handlePruningChange}/>}/>
          <FormControlLabel label='Desbroces' control={<Checkbox size='small' checked={performances.has_weeding} disabled={disabledProjectButtons} onChange={handleWeedingChange}/>}/>
        </Box>
        <Fab sx={fab} size='small' onClick={handleAddPerformance} disabled={disabledProjectButtons}>
          <AddIcon sx={{color: 'white'}}/>
        </Fab>
        {calculationButton}
      </Box>
    </TableContainer>
    <PerformancesDialog isPerformancesMenuOpen={isPerformancesMenuOpen} mode={mode} performances={performances}
      onPerformanceChange={onPerformancesChange}
      onDialogClose={() => setPerformancesMenuOpen(!isPerformancesMenuOpen)}/>
  </>;

  const targetProduct = <TargetProduct selectedProductId={performances.commercial_purpose} onProductChange={(value) => onPerformancesChange({...performances, commercial_purpose: value})}/>;

  return <>
    {
      mode === 'BASELINE' ?
        <Box mb={2} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', width: '40vw'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', gap: 2 }}>
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <RadioGroup defaultValue="withoutManagement" row onChange={(e) => setBaselineManagement(e.target.value)}>
                <FormControlLabel value="withoutManagement" control={<Radio/>} label="Sin Gestión"/>
                <FormControlLabel value="addPerformances" control={<Radio/>} label="Añadir Actuaciones"/>
              </RadioGroup>
              {
                baselineManagement !== 'addPerformances' && <>{calculationButton}</>
              }
            </Box>
            {
              baselineManagement === 'addPerformances' && <>{table}</>
            }
          </Box>
          {targetProduct}
        </Box>
        : <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start',  width: '40vw'}}>
          {table}
          {targetProduct}
        </Box>
    }
  </>;
};

PerformanceTable.propTypes = {
  performances: PropTypes.object,
  mode: PropTypes.oneOf(['BASELINE', 'PROJECT']).isRequired,
  disabledProjectButtons: PropTypes.bool,
  onPerformancesChange: PropTypes.func.isRequired,
  onCalculateCO2: PropTypes.func.isRequired
};

export default PerformanceTable;