import apiActionTypes, {
  apiPostCreateMultipleInterventionsError,
  apiPostCreateMultipleInterventionsSuccess,
  apiPostNeverDoError,
  apiGetBatchesError,
  apiGetBatchesSuccess,
  apiActivateCommonerError,
  apiActivateCommonerSuccess,
  apiDeactivateCommonerError,
  apiDeactivateCommonerSuccess,
  apiDeleteExecutionSuccess,
  apiGetBusinessWithLocationError,
  apiGetBusinessWithLocationSuccess,
  apiCommunitiesError,
  apiCommunitiesSuccess,
  apiCompleteAnnualPlanError,
  apiCompleteAnnualPlanSuccess,
  apiCreateAnnualPlanError,
  apiCreateAnnualPlanSuccess,
  apiCreateInterventionError,
  apiDeleteFileError,
  apiCreateCommonerError,
  apiCreateCommonerSuccess,
  apiCreateInterventionSuccess,
  apiDeleteCommonerError,
  apiDeleteCommonerSuccess,
  apiDeleteFileSuccess,
  apiDeleteInterventionError,
  apiDeleteInterventionSuccess,
  apiDownloadFileError,
  apiDownloadFileSuccess,
  apiDownloadInterventionZoneGeometryError,
  apiDownloadInterventionZoneGeometrySuccess,
  apiGetAnnualPlansError,
  apiGetAnnualPlansSuccess,
  apiGetCommonersError,
  apiGetCommonersSuccess,
  apiGetInterventionZoneDetailSuccess,
  apiGetInterventionZoneDetailError,
  apiGetManifest,
  apiGetProfile,
  apiInterventionZonesSuccess,
  apiInterventionZonesError,
  apiLoginSuccess,
  apiLoginError,
  apiLoginUnauthorized,
  apiLogout,
  apiManifestSuccess,
  apiManifestError,
  apiInProcedureAnnualPlanSuccess,
  apiInProcedureAnnualPlanError,
  apiProfileSuccess,
  apiProfileError,
  apiUpdateCommonerSuccess,
  apiUpdateCommonerError,
  apiUpdateInterventionAnnualPlanSuccess,
  apiUpdateInterventionAnnualPlanError,
  apiUploadFileSuccess,
  apiUploadFileError,
  downloadFile,
  apiDeleteAnnualPlanSuccess,
  apiDeleteAnnualPlanError,
  apiCallStarted,
  apiCallEnded,
  apiDeleteCommunityFileSuccess,
  apiDeleteCommunityFileError,
  apiUploadCommunityFileSuccess,
  apiUploadCommunityFileError,
  apiUpdateInterventionSuccess,
  apiUpdateInterventionError,
  apiInviteCommonerSuccess,
  apiInviteCommonerError,
  apiDeleteExecutionError,
  apiGetBusinessSuccess,
  apiGetBusinessError,
  apiMaderaPlusGetSpeciesSuccess,
  apiMaderaPlusGetSpeciesError,
  apiMaderaPlusGetDistrictSuccess,
  apiMaderaPlusGetDistrictError,
  apiPostProspectingPointsSuccess,
  apiPostProspectingPointsError,
  apiPostUpdateElasticModuleError,
  apiPostUpdateElasticModuleSuccess,
  apiGetProspectingPointsError,
  apiGetProspectingPointsSuccess,
  apiDeleteProspectingPointsSuccess,
  apiDeleteProspectingPointsError,
  apiGetAccountEntriesSuccess,
  apiGetAccountEntriesError,
  apiDeleteAccountEntrySuccess,
  apiDeleteAccountEntryError,
  apiCreateAccountEntrySuccess,
  apiCreateAccountEntryError,
  apiUpdateAccountEntrySuccess,
  apiUpdateAccountEntryError,
  apiDownloadAnnualPlanSuccess,
  apiDownloadAnnualPlanError,
  apiPostCreateBatchSuccess,
  apiPostCreateBatchError,
  apiPostMultipleExecutionSuccess,
  apiPostMultipleExecutionError,
  apiRolesListError,
  apiRolesListSuccess,
  apiGetFileDetailSuccess,
  apiGetFileDetailError,
  apiPostDeleteBatchSuccess,
  apiPostDeleteBatchError,
  apiPostUpdateBatchSuccess,
  apiPostUpdateBatchError,
  apiGetCommunityFilesSuccess,
  apiGetCommunityFilesError,
  apiPostCreateExecutionError,
  apiPostCreateExecutionSuccess,
  apiUpdateExecutionSuccess,
  apiUpdateExecutionError,
  apiPostNeverDoSuccess,
  apiGetInterventionSuccess,
  apiGetInterventionError,
  apiGetIntervention,
  apiCreatePlantationSuccess,
  apiCreatePlantationError, 
  apiSendToRSCSuccess,
  apiSendToRSCError,
  apiPostCalculateCO2Success,
  apiPostCalculateCO2Error
} from './actions';
import {getToken} from './selectors';

const ENV_URL = process.env.BASE_URL;
export const BASE_URL = ENV_URL && ENV_URL.charAt(ENV_URL.length - 1) === '/' ? ENV_URL.slice(0, -1) : ENV_URL;

const MADERA_PLUS_URL = process.env.MADERA_PLUS_URL;
const MADERA_PLUS_API_KEY = process.env.MADERA_PLUS_API_KEY;

export const PAGINATION_GET_PARAM = 'page_size';
export const PAGINATION_SIZES = {
  COMMUNITY: 20,
  INTERVENTION_ZONES: 100,
  DEFAULT: 100
};

export const ENDPOINTS = {

  //GENERAL-------------------------------------------------------------------------------------------------------------
  login: `${BASE_URL}/api-token-auth/`,
  profile: `${BASE_URL}/profile/`,
  manifest: `${BASE_URL}/bm/manifest/`,
  communities: `${BASE_URL}/bm/communities/`,
  rolesList: `${BASE_URL}/bm/roles/`,
  interventionZones: id => `${BASE_URL}/bm/communities/${id}/interventionzones/`,

  //INTERVENTIONS-------------------------------------------------------------------------------------------------------
  createInterventions: interventionZoneId => `${BASE_URL}/bm/interventionzones/${interventionZoneId}/interventions/`,
  createPlantation: interventionZoneId => `${BASE_URL}/bm/interventionzones/${interventionZoneId}/plantation/`,
  interventionById: interventionId => `${BASE_URL}/bm/interventions/${interventionId}/`,
  interventionZoneDetail: id => `${BASE_URL}/bm/interventionzones/${id}/`,
  downloadGeometry: communityId => `${BASE_URL}/bm/communities/${communityId}/process_file/`,
  neverDo: interventionId => `${BASE_URL}/bm/interventions/${interventionId}/neverdo/`,

  //EXECUTIONS----------------------------------------------------------------------------------------------------------
  createExecution: interventionId => `${BASE_URL}/bm/interventions/${interventionId}/executeintervention/`,
  updateExecution: executionId => `${BASE_URL}/bm/executions/${executionId}/`,
  deleteExecution: executionId => `${BASE_URL}/bm/executions/${executionId}/`,

  //BATCHES-------------------------------------------------------------------------------------------------------------
  createBatch: communityId => `${BASE_URL}/bm/communities/${communityId}/createbatch/`,
  getCommunityBatches: communityId => `${BASE_URL}/bm/communities/${communityId}/batches/`,
  batch: batchId => `${BASE_URL}/bm/batches/${batchId}`,

  //FILES---------------------------------------------------------------------------------------------------------------
  uploadCommunityFile: fileId => `${BASE_URL}/bm/communities/${fileId}/upload_file/`,
  uploadFile: fileId => `${BASE_URL}/bm/interventions/${fileId}/upload_file/`,
  deleteFile: fileId => `${BASE_URL}/attachments/${fileId}/`,
  downloadFile: (fileId, token) => `${BASE_URL}/attachments/${fileId}/download/?token=${token}`,
  fileDetail: fileId => `${BASE_URL}/attachments/${fileId}/`,
  downloadToken: `${BASE_URL}/download-token/`,
  getCommunityFiles: comunityId => `${BASE_URL}/bm/communities/${comunityId}/files/`,

  //ANNUALPLANS---------------------------------------------------------------------------------------------------------
  annualPlans: annualPlanId => `${BASE_URL}/bm/communities/${annualPlanId}/annualplans/`,
  completeAnnualPlan: annualPlanId => `${BASE_URL}/bm/annualplans/${annualPlanId}/complete/`,
  deleteAnnualPlan: annualPlanId => `${BASE_URL}/bm/annualplans/${annualPlanId}/`,
  updateInterventionsAnnualPlan: annualPlanId => `${BASE_URL}/bm/annualplans/${annualPlanId}/manage_interventions/`,
  downloadAnnualPlan: annualPlanId => `${BASE_URL}/bm/annualplans/${annualPlanId}/process_file/`,
  inProcedureAnnualPlan: annualPlanId => `${BASE_URL}/bm/annualplans/${annualPlanId}/perform/`,

  //COMMONERS-----------------------------------------------------------------------------------------------------------
  commonersList: communityId => `${BASE_URL}/bm/communities/${communityId}/commoners/`,
  commoner: commonerId => `${BASE_URL}/bm/commoners/${commonerId}/`,
  commonerInvite: commonerId => `${BASE_URL}/bm/commoners/${commonerId}/invite/`,
  commonerActivate: commonerId => `${BASE_URL}/bm/commoners/${commonerId}/activate/`,
  commonerDeactivate: commonerId => `${BASE_URL}/bm/commoners/${commonerId}/deactivate/`,

  //BUSINESS------------------------------------------------------------------------------------------------------------
  businessList: `${BASE_URL}/bm/resfor/`,
  businessWithLocation: `${BASE_URL}/bm/resfor/?format=geojson`,

  //MADERAPLUS----------------------------------------------------------------------------------------------------------
  maderaPlusGetSpecies: `${MADERA_PLUS_URL}/get_especies?token=${MADERA_PLUS_API_KEY}`,
  maderaPlusGetDistrict: (x, y) => `${MADERA_PLUS_URL}/get_distrito?token=${MADERA_PLUS_API_KEY}&x=${x}&y=${y}`,
  maderaPlusGetData: (variable, x, y, specieId, district) => `${MADERA_PLUS_URL}/get_data?token=${MADERA_PLUS_API_KEY}&variable=${variable}&x=${x}&y=${y}&sp=${specieId}&distrito=${district}`,
  updateElasticModule: interventionZoneId => `${BASE_URL}/bm/interventionzones/${interventionZoneId}/elastic_module/`,
  updateProspectingPoints: interventionZoneId => `${BASE_URL}/bm/interventionzones/${interventionZoneId}/prospecting_points/`,
  prospectingPoints: pointId => `${BASE_URL}/bm/prospectingpoints/${pointId}/`,

  //ACCOUNT BOOK--------------------------------------------------------------------------------------------------------
  accountEntries: communityId => `${BASE_URL}/bm/communities/${communityId}/accountentries/`,
  accountEntriesDetail: entryId => `${BASE_URL}/bm/accountentries/${entryId}/`,

  //MULTIPLE------------------------------------------------------------------------------------------------------------
  multipleExecution: communityId => `${BASE_URL}/bm/communities/${communityId}/multipleexecution/`,
  multipleExecutionSetBatch: batchId => `${BASE_URL}/bm/batches/${batchId}/setexecutions/`,
  multipleInterventions: communityId => `${BASE_URL}/bm/communities/${communityId}/multipleintervention/`,//crear
  
  //RSC-----------------------------------------------------------------------------------------------------------------
  sendToRSC: interventionId => `${BASE_URL}/bn/interventionzones/${interventionId}/send_to_rsc/`,
  
  //BIOMASCO2-----------------------------------------------------------------------------------------------------------
  //getProjects: (interventionZoneId, communityId) => `${BASE_URL}/bm/biomass-co2/extante-projects?rodal_id=${interventionZoneId}&community_id=${communityId}/`,
  calculateCO2: `${BASE_URL}/biomass-co2/exante-projects/`,
};

const APPLICATION_JSON = 'application/json';

const authenticate = (username, password) =>
  fetch(ENDPOINTS.login, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    }),
    body: JSON.stringify({username, password})
  }).then(response => {
    if (response.status === 200 && response.ok) {
      return response.json()
        .then(body => apiLoginSuccess({
          username: username,
          token: body.token
        }));
    } else if (response.status === 400) {
      // Por alguna razón, la API devuelve un 400 en vez de un 403
      return apiLoginUnauthorized();
    } else {
      const message = `${response.status} ${response.statusText}`;
      return apiLoginError(message);
    }
  });

const API_ACTION = (url, token, method, contentType = undefined, body = undefined) => {
  if (!token) {
    return Promise.reject({message: 'No Token available'});
  } else {
    return fetch(url, {
      method: method,
      headers: new Headers({
        ...(contentType ? {'Content-Type': contentType} : {}),
        'Authorization': `Token ${token}`
      }),
      body
    }).then(response => {
      if ((response.status === 200 || response.status === 201 || response.status === 304) && response.ok) {
        return response.json();
      } else if (response.status === 204 && response.ok) {
        return true;
      } else {
        return Promise.reject({
          status: response.status,
          message: response.statusText
        });
      }
    });
  }
};

const API_MADERA_PLUS = (url) => {
  return fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': APPLICATION_JSON
    })
  }).then(response => {
    if ((response.status === 200) && response.ok) {
      return response.json();
    } else {
      return Promise.reject({
        status: response.status,
        message: response.statusText
      });
    }
  });
};


const PUT = (url, token, data) => API_ACTION(url, token, 'PUT', APPLICATION_JSON, JSON.stringify(data));
const PATCH = (url, token, data) => API_ACTION(url, token, 'PATCH', APPLICATION_JSON, JSON.stringify(data));
const GET = (url, token) => API_ACTION(url, token, 'GET', APPLICATION_JSON);
const POST = (url, token, data) => API_ACTION(url, token, 'POST', APPLICATION_JSON, JSON.stringify(data));
const DELETE = (url, token) => API_ACTION(url, token, 'DELETE');
const UPLOAD_FILE = (url, token, data) => {
  const formData = new FormData();
  data && Object.keys(data).map(key => {
    Array.isArray(data[key]) ?
      data[key].map(value => formData.append(key, value)) :
      formData.append(key, data[key]);
  });
  return API_ACTION(url, token, 'POST', undefined, formData);
};

const GET_PAGINATED = (url, token, page_size=100, previous_results=[]) => {
  const final_url = url.includes(PAGINATION_GET_PARAM) ? url : url + `?${PAGINATION_GET_PARAM}=${page_size}`;
  return API_ACTION(final_url, token, 'GET', APPLICATION_JSON)
    .then(result => {
      const total_results = [...previous_results, ...result.results];
      if (result.next) {
        return GET_PAGINATED(result.next, token, page_size, total_results);
      }
      return total_results;
    });
};

const TOKEN_FILE = (url, token) => API_ACTION(url, token, 'POST');

const logoutOrAction = (onOtherErrorAction) =>
  ({status, message}) =>
    status === 403 ? apiLogout() : onOtherErrorAction({status, message});

const apiMiddleware = () => {
  return store => next => action => {
    const state = store.getState();
    switch (action.type) {

    //GENERAL-----------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_LOGIN:
      return authenticate(action.payload.username, action.payload.password)
        .then(store.dispatch)
        .then(() => next(action));
    case apiActionTypes.API_LOGIN_SUCCESS:
      store.dispatch(apiGetProfile({token: action.payload.token}));
      store.dispatch(apiGetManifest({token: action.payload.token}));
      break;
    case apiActionTypes.API_GET_PROFILE:
      return GET(ENDPOINTS.profile, action.payload.token)
        .then(apiProfileSuccess)
        .catch(logoutOrAction(apiProfileError))
        .then(store.dispatch)
        .then(() => next(action));
    case apiActionTypes.API_GET_MANIFEST:
      return GET(ENDPOINTS.manifest, action.payload.token)
        .then(apiManifestSuccess)
        .catch(logoutOrAction(apiManifestError))
        .then(store.dispatch)
        .then(() => next(action));
    case apiActionTypes.API_GET_COMMUNITIES:
      store.dispatch(apiCallStarted());
      return GET_PAGINATED(ENDPOINTS.communities, getToken(state), PAGINATION_SIZES.COMMUNITY)
        .then(apiCommunitiesSuccess)
        .catch(logoutOrAction(apiCommunitiesError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_ROLES_LIST:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.rolesList, getToken(state))
        .then(apiRolesListSuccess)
        .catch(logoutOrAction(apiRolesListError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_INTERVENTION_ZONES:
      store.dispatch(apiCallStarted());
      return GET_PAGINATED(ENDPOINTS.interventionZones(action.payload), getToken(state), PAGINATION_SIZES.INTERVENTION_ZONES)
        .then(apiInterventionZonesSuccess)
        .catch(logoutOrAction(apiInterventionZonesError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_COMMUNITY_FILES:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.getCommunityFiles(action.payload), getToken(state))
        .then(apiGetCommunityFilesSuccess)
        .catch(logoutOrAction(apiGetCommunityFilesError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //INTERVENTIONS-----------------------------------------------------------------------------------------------------
    case apiActionTypes.API_GET_INTERVENTION:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.interventionById(action.payload), getToken(state))
        .then(apiGetInterventionSuccess)
        .catch(logoutOrAction(apiGetInterventionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_CREATE_INTERVENTION:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.createInterventions(action.payload.interventionZoneId), getToken(state), action.payload.intervention)
        .then((intervention) => apiCreateInterventionSuccess({
          interventionZoneId: action.payload.interventionZoneId,
          intervention: intervention
        }))
        .catch(logoutOrAction(apiCreateInterventionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_CREATE_PLANTATION:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.createPlantation(action.payload.interventionZoneId), getToken(state), ({
        intervention: action.payload.intervention,
        species: action.payload.specieId
      }))
        .then((intervention) => apiCreatePlantationSuccess({
          interventionZoneId: action.payload.interventionZoneId,
          intervention: intervention,
        }))
        .catch(logoutOrAction(apiCreatePlantationError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DELETE_INTERVENTION:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.interventionById(action.payload.interventionId), getToken(state))
        .then(() => apiDeleteInterventionSuccess(action.payload))
        .catch(logoutOrAction(apiDeleteInterventionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_UPDATE_INTERVENTION:
      store.dispatch(apiCallStarted());
      return PATCH(ENDPOINTS.interventionById(action.payload.id), getToken(state), action.payload.intervention)
        .then(apiUpdateInterventionSuccess)
        .catch(logoutOrAction(apiUpdateInterventionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_INTERVENTION_ZONE_DETAIL:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.interventionZoneDetail(action.payload), getToken(state))
        .then(apiGetInterventionZoneDetailSuccess)
        .catch(logoutOrAction(apiGetInterventionZoneDetailError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DOWNLOAD_INTERVENTION_ZONE_GEOMETRY:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.downloadGeometry(action.payload.selectedCommunityId), getToken(state), ({
        ids: action.payload.interventionZoneIds
      }))
        .then(apiDownloadInterventionZoneGeometrySuccess)
        .catch(logoutOrAction(apiDownloadInterventionZoneGeometryError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_NEVER_DO:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.neverDo(action.payload), getToken(state))
        .then(apiPostNeverDoSuccess)
        .catch(logoutOrAction(apiPostNeverDoError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //EXECUTIONS--------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_POST_CREATE_EXECUTION:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.createExecution(action.payload.interventionId), getToken(state), action.payload.execution)
        .then(apiPostCreateExecutionSuccess)
        .catch(logoutOrAction(apiPostCreateExecutionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_UPDATE_EXECUTION:
      store.dispatch(apiCallStarted());
      return PUT(ENDPOINTS.updateExecution(action.payload.id), getToken(state), action.payload)
        .then(apiUpdateExecutionSuccess)
        .catch(logoutOrAction(apiUpdateExecutionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action)); 
    case apiActionTypes.API_DELETE_EXECUTION:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.deleteExecution(action.payload.executionId), getToken(state))
        .then(() => apiDeleteExecutionSuccess(action.payload))
        .then(() => apiGetIntervention(action.payload.interventionId))
        .catch(logoutOrAction(apiDeleteExecutionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //BATCHES-----------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_GET_BATCHES:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.getCommunityBatches(action.payload), getToken(state))
        .then(apiGetBatchesSuccess)
        .catch(logoutOrAction(apiGetBatchesError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_CREATE_BATCH:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.createBatch(action.payload.communityId), getToken(state), action.payload.batch)
        .then(apiPostCreateBatchSuccess)
        .catch(logoutOrAction(apiPostCreateBatchError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_DELETE_BATCH:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.batch(action.payload), getToken(state))
        .then(() => apiPostDeleteBatchSuccess(action.payload))
        .catch(logoutOrAction(apiPostDeleteBatchError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_UPDATE_BATCH:
      store.dispatch(apiCallStarted());
      return PUT(ENDPOINTS.batch(action.payload.id), getToken(state), action.payload)
        .then(apiPostUpdateBatchSuccess)
        .catch(logoutOrAction(apiPostUpdateBatchError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //FILES-------------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_UPLOAD_COMMUNITY_FILE:
      store.dispatch(apiCallStarted());
      return UPLOAD_FILE(ENDPOINTS.uploadCommunityFile(action.payload.selectedCommunityId), getToken(state), action.payload.uploadFormData)
        .then((response) => apiUploadCommunityFileSuccess(response.files))
        .catch(logoutOrAction(apiUploadCommunityFileError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_UPLOAD_FILE:
      store.dispatch(apiCallStarted());
      return UPLOAD_FILE(ENDPOINTS.uploadFile(action.payload.interventionId), getToken(state), action.payload.uploadFormData)
        .then((response) => apiUploadFileSuccess({
          interventionZoneId: action.payload.interventionZoneId,
          interventionId: action.payload.interventionId,
          files: response.files
        }))
        .catch(logoutOrAction(apiUploadFileError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DELETE_FILE:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.deleteFile(action.payload.fileId), getToken(state))
        .then(() => apiDeleteFileSuccess(action.payload))
        .catch(logoutOrAction(apiDeleteFileError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DELETE_COMMUNITY_FILE:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.deleteFile(action.payload.fileId), getToken(state))
        .then(() => apiDeleteCommunityFileSuccess(action.payload.fileId))
        .catch(logoutOrAction(apiDeleteCommunityFileError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_FILE_DETAIL:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.fileDetail(action.payload), getToken(state))
        .then((response) => apiGetFileDetailSuccess(response))
        .catch(logoutOrAction(apiGetFileDetailError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DOWNLOAD_FILE:
      store.dispatch(apiCallStarted());
      return TOKEN_FILE(ENDPOINTS.downloadToken, getToken(state))
        .then((response) => downloadFile({
          fileId: action.payload.fileId,
          content_type: action.payload.content_type
        }, response.token))
        .then(apiDownloadFileSuccess)
        .catch(logoutOrAction(apiDownloadFileError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //ANNUALPLANS-------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_GET_ANNUAL_PLANS:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.annualPlans(action.payload), getToken(state))
        .then(apiGetAnnualPlansSuccess)
        .catch(logoutOrAction(apiGetAnnualPlansError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_CREATE_ANNUAL_PLAN:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.annualPlans(action.payload.id), getToken(state), {year: action.payload.year})
        .then(apiCreateAnnualPlanSuccess)
        .catch(logoutOrAction(apiCreateAnnualPlanError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_COMPLETE_ANNUAL_PLAN:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.completeAnnualPlan(action.payload), getToken(state))
        .then(apiCompleteAnnualPlanSuccess)
        .catch(logoutOrAction(apiCompleteAnnualPlanError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DELETE_ANNUAL_PLAN:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.deleteAnnualPlan(action.payload), getToken(state))
        .then(() => apiDeleteAnnualPlanSuccess(action.payload))
        .catch(logoutOrAction(apiDeleteAnnualPlanError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_UPDATE_INTERVENTION_ANNUAL_PLAN:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.updateInterventionsAnnualPlan(action.payload.id), getToken(state), {interventions: action.payload.interventions})
        .then(apiUpdateInterventionAnnualPlanSuccess)
        .catch(logoutOrAction(apiUpdateInterventionAnnualPlanError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DOWNLOAD_ANNUAL_PLAN:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.downloadAnnualPlan(action.payload), getToken(state))
        .then(apiDownloadAnnualPlanSuccess)
        .catch(logoutOrAction(apiDownloadAnnualPlanError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_IN_PROCEDURE_ANNUAL_PLAN:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.inProcedureAnnualPlan(action.payload), getToken(state))
        .then(apiInProcedureAnnualPlanSuccess)
        .catch(logoutOrAction(apiInProcedureAnnualPlanError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //COMMONERS---------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_GET_COMMONERS:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.commonersList(action.payload), getToken(state))
        .then(apiGetCommonersSuccess)
        .catch(logoutOrAction(apiGetCommonersError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_CREATE_COMMONER:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.commonersList(action.payload), getToken(state))
        .then(apiCreateCommonerSuccess)
        .catch(logoutOrAction(apiCreateCommonerError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DELETE_COMMONER:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.commoner(action.payload), getToken(state))
        .then(() => apiDeleteCommonerSuccess(action.payload))
        .catch(logoutOrAction(apiDeleteCommonerError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_UPDATE_COMMONER:
      store.dispatch(apiCallStarted());
      return PUT(ENDPOINTS.commoner(action.payload.id), getToken(state), action.payload)
        .then(apiUpdateCommonerSuccess)
        .catch(logoutOrAction(apiUpdateCommonerError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_INVITE_COMMONER:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.commonerInvite(action.payload), getToken(state))
        .then((response) => apiInviteCommonerSuccess({
          id: action.payload,
          response
        }))
        .catch(logoutOrAction(apiInviteCommonerError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_ACTIVATE_COMMONER:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.commonerActivate(action.payload), getToken(state))
        .then(apiActivateCommonerSuccess)
        .catch(logoutOrAction(apiActivateCommonerError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DEACTIVATE_COMMONER:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.commonerDeactivate(action.payload), getToken(state))
        .then(apiDeactivateCommonerSuccess)
        .catch(logoutOrAction(apiDeactivateCommonerError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //BUSINESS----------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_GET_BUSINESS:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.businessList, getToken(state))
        .then(apiGetBusinessSuccess)
        .catch(logoutOrAction(apiGetBusinessError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_BUSINESS_WITH_LOCATION:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.businessWithLocation, getToken(state))
        .then(apiGetBusinessWithLocationSuccess)
        .catch(logoutOrAction(apiGetBusinessWithLocationError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //MADERAPLUS--------------------------------------------------------------------------------------------------------
    case apiActionTypes.MADERA_PLUS_GET_SPECIES:
      store.dispatch(apiCallStarted());
      return API_MADERA_PLUS(ENDPOINTS.maderaPlusGetSpecies)
        .then(apiMaderaPlusGetSpeciesSuccess)
        .catch(logoutOrAction(apiMaderaPlusGetSpeciesError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.MADERA_PLUS_GET_DISTRICT:
      store.dispatch(apiCallStarted());
      return API_MADERA_PLUS(ENDPOINTS.maderaPlusGetDistrict(action.payload.x, action.payload.y))
        .then(apiMaderaPlusGetDistrictSuccess)
        .catch(logoutOrAction(apiMaderaPlusGetDistrictError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_UPDATE_ELASTIC_MODULE:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.updateElasticModule(action.payload.interventionZoneId), getToken(state), action.payload)
        .then(apiPostUpdateElasticModuleSuccess)
        .catch(logoutOrAction(apiPostUpdateElasticModuleError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_POST_PROSPECTING_POINTS:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.updateProspectingPoints(action.payload.interventionZoneId), getToken(state), action.payload)
        .then(apiPostProspectingPointsSuccess)
        .catch(logoutOrAction(apiPostProspectingPointsError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_PROSPECTING_POINTS:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.prospectingPoints(action.payload.pointId), getToken(state))
        .then(apiGetProspectingPointsSuccess)
        .catch(logoutOrAction(apiGetProspectingPointsError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DELETE_PROSPECTING_POINTS:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.prospectingPoints(action.payload.pointId), getToken(state))
        .then(apiDeleteProspectingPointsSuccess)
        .catch(logoutOrAction(apiDeleteProspectingPointsError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //ACCOUNT BOOK------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_CREATE_ACCOUNT_ENTRY:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.accountEntries(action.payload.communityId), getToken(state), action.payload.entry)
        .then(apiCreateAccountEntrySuccess)
        .catch(logoutOrAction(apiCreateAccountEntryError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_GET_ACCOUNT_ENTRIES:
      store.dispatch(apiCallStarted());
      return GET(ENDPOINTS.accountEntries(action.payload), getToken(state))
        .then(apiGetAccountEntriesSuccess)
        .catch(logoutOrAction(apiGetAccountEntriesError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_UPDATE_ACCOUNT_ENTRY:
      store.dispatch(apiCallStarted());
      return PUT(ENDPOINTS.accountEntriesDetail(action.payload.id), getToken(state), action.payload)
        .then(apiUpdateAccountEntrySuccess)
        .catch(logoutOrAction(apiUpdateAccountEntryError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    case apiActionTypes.API_DELETE_ACCOUNT_ENTRY:
      store.dispatch(apiCallStarted());
      return DELETE(ENDPOINTS.accountEntriesDetail(action.payload), getToken(state))
        .then(() => apiDeleteAccountEntrySuccess(action.payload))
        .catch(logoutOrAction(apiDeleteAccountEntryError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    //MULTIPLE----------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_POST_MULTIPLE_EXECUTION:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.multipleExecution(action.payload.communityId), getToken(state), action.payload.execution)
        .then((response) => {
          if (action.payload.batch) {
            const body = {
              executions: response
                .flatMap(intervention => intervention.executions
                  .map(execution => execution.id)
                )
            };
            return POST(ENDPOINTS.multipleExecutionSetBatch(action.payload.batch.id), getToken(state), body)
              .then((response) => apiPostMultipleExecutionSuccess(response));
          } else {
            return apiPostMultipleExecutionSuccess(response);
          }
        })
        .catch(logoutOrAction(apiPostMultipleExecutionError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));

    case apiActionTypes.API_CREATE_MULTIPLE_INTERVENTIONS:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.multipleInterventions(action.payload.communityId), getToken(state), action.payload)
        .then(createdInterventions => apiPostCreateMultipleInterventionsSuccess(createdInterventions))
        .catch(logoutOrAction(apiPostCreateMultipleInterventionsError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
      
    case apiActionTypes.API_CREATE_MULTIPLE_PLANTATIONS:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.multipleInterventions(action.payload.communityId), getToken(state), action.payload)
        .then(createdInterventions => apiPostCreateMultipleInterventionsSuccess(createdInterventions))
        .catch(logoutOrAction(apiPostCreateMultipleInterventionsError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
      
    //RSC---------------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_SEND_TO_RSC:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.sendToRSC(action.payload), getToken(state), action.payload)
        .then(apiSendToRSCSuccess)
        .catch(logoutOrAction(apiSendToRSCError))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
      
    //BIOMASCO2---------------------------------------------------------------------------------------------------------
    case apiActionTypes.API_POST_CALCULATE_CO2:
      store.dispatch(apiCallStarted());
      return POST(ENDPOINTS.calculateCO2, getToken(state), action.payload)
        .then(apiPostCalculateCO2Success)
        .catch(logoutOrAction(apiPostCalculateCO2Error))
        .then(store.dispatch)
        .then(() => store.dispatch(apiCallEnded()))
        .then(() => next(action));
    }
    return next(action);
  };
};

export default apiMiddleware();
